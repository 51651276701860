import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: '_id' })
export class IdPipe implements PipeTransform {

	transform(input: string): string {
		input = input ? input.trim() : '';
		return `<span title="${input}">${input.toString().substr(input.length - 3)}</span>`;
	}
}
