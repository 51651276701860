import { Component } from '@angular/core';
import { City, CityError } from '../city.model';
import { BaseForm } from 'src/app/@core/libraries/pages/base-form';
import { Country } from '../../country/country.model';
import { environment } from 'src/environments/environment';
import { HttpParams } from '@angular/common/http';
import { Store } from '../../store/store.model';
import * as moment from 'moment';
import { i18n } from '../../../@core/libraries/i18n';

@Component({
	selector: 'app-form',
	templateUrl: './form.component.html',
	styleUrls: ['./form.component.scss']
})
export class FormComponent extends BaseForm {

	model = 'city';
	item: City = new City();
	errors: CityError = new CityError();
	validate: CityError = new CityError();
	countries: Country[] = [];
	stores: Store[] = [];
	parents: City[] = [];
	days: Array<any> = [];
	times: Array<any> = [];

	initialize() {
		this.loadCountries();
		this.loadParents();
		this.loadStores();
	}

	afterInit() {
		this.days = moment.weekdays().map((i, idx) => {
			return {
				id: idx,
				text: i
			}
		});
		for (let i = 0; i < 24; i++) {
			this.times.push({
				id: i,
				text: `${i18n.from} ${i.toString().padStart(2, '0')} ${i18n.to} ${(i + 1).toString().padStart(2, '0')}`,
			});
		}

		if (!this.item.available_delivery_times || this.item.available_delivery_times.length < 1) {
			let available_times = [];
			for (const i of this.times) {
				available_times[i.id] = {
					id: i.id,
					is_available: false,
					max_orders: 0,
					is_enabled: true
				};
			}

			this.item.available_delivery_times = [];

			for (const i of this.days) {
				this.item.available_delivery_times[i.id] = available_times;
			}
		} else {
			if (this.item.available_delivery_times.filter(d => d).length < 7) {
				let available_times = [];
				for (const i of this.times) {
					available_times[i.id] = {
						id: i.id,
						is_available: false,
						max_orders: 0,
						is_enabled: true
					};
				}
				for (const i of this.days) {
					if (!this.item.available_delivery_times[i.id]) {
						this.item.available_delivery_times[i.id] = available_times;
					}
				}
			}
		}

	}

	loadCountries() {
		this.http.get(`${environment.apiUrl}/country`, {
			params: new HttpParams()
				.set('filter', 'status=true')
				.set('sort', `name.${this.config.lang}=asc`)
				.set('limit', '999')
		})
			.subscribe((response) => {
				this.countries = response['results']['data'];
			});
	}

	loadParents() {
		this.http.get(`${environment.apiUrl}/city`, {
			params: new HttpParams()
				.set('filter', `status=true&parent_id=null&country_id=${this.item.country_id}`)
				.set('sort', `name.${this.config.lang}=asc`)
				.set('limit', '999')
		})
			.subscribe((response) => {
				this.parents = response['results']['data'];
			});
	}

	loadStores() {
		this.http.get(`${environment.apiUrl}/store`, {
			params: new HttpParams()
				.set('filter', 'status=true')
				.set('sort', `name.${this.config.lang}=asc`)
				.set('limit', '999')
		})
			.subscribe((response) => {
				this.stores = response['results']['data'];
			});
	}

	getEmptyErrors() {
		return new CityError();
	}

	enableAllDeliveryTimes() {
		this.item.available_delivery_times = this.item.available_delivery_times.map((d) => d.map(t => ({...t, is_enabled: true})));
	}

	disableAllDeliveryTimes() {
		this.item.available_delivery_times = this.item.available_delivery_times.map((d) => d.map(t => ({...t, is_enabled: false})));
	}

}
