import { ITable } from 'src/app/@core/libraries/interfaces/table';
import { IError } from 'src/app/@core/libraries/interfaces/error';
import { Bannertypes } from 'src/app/@core/data/bannertype.enum';

export class Banner implements ITable {

	_id: String = '';
	name: String = '';
	position: String = '';
	bannertype: Bannertypes;
	code: String = '';
	picture: String = '';
	url: String = '';
	categories: Array<any> = [];
	features: Array<any> = [];
	status: Boolean = false;
}

export class BannerError implements IError {

	name: String = '';
	position: String = '';
	bannertype: String = '';
	code: String = '';
	picture: String = '';
	url: String = '';
	categories: String = '';
	features: String = '';
	status: Boolean = false;
}
