<h1 mat-dialog-title>{{ data.action | i18n }}</h1>
<div mat-dialog-content>
	<mat-card>
		<mat-card-subtitle>{{ 'details' | i18n }}</mat-card-subtitle>
		<mat-card-content>
			<div class="col-12">

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'name' | i18n }}</mat-label>
					<input matInput [(ngModel)]="item.name" [errorStateMatcher]='validate.name'
						placeholder="{{ 'name' | i18n }}" />
					<mat-error>{{ errors.name }}</mat-error>
				</mat-form-field>

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'status' | i18n }}</mat-label>
					<mat-select placeholder="{{ 'status' | i18n }}" [(ngModel)]='item.status'
						[errorStateMatcher]='validate.status' name="status">
						<mat-option [value]="true">{{ 'yes' | i18n }}</mat-option>
						<mat-option [value]="false">{{ 'no' | i18n }}</mat-option>
					</mat-select>
					<mat-error>{{ errors.status }}</mat-error>
				</mat-form-field>

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'position' | i18n }}</mat-label>
					<mat-select placeholder="{{ 'position' | i18n }}" [(ngModel)]="item.position"
						[errorStateMatcher]='validate.position' name="position">
						<mat-option>-- {{ 'remove' | i18n }} --</mat-option>
						<mat-option *ngFor="let c of positions" [value]="c._id">
							{{ c.name }}
						</mat-option>
					</mat-select>
				</mat-form-field>

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'bannertype' | i18n }}</mat-label>
					<mat-select placeholder="{{ 'bannertype' | i18n }}" [(ngModel)]="item.bannertype"
						[errorStateMatcher]='validate.bannertype' name="bannertype">
						<mat-option>-- {{ 'remove' | i18n }} --</mat-option>
						<mat-option *ngFor="let c of bannertypes" [value]="c._id">
							{{ c.name }}
						</mat-option>
					</mat-select>
				</mat-form-field>

				<mat-form-field appearance="outline" class="form-group col-md-6 mat-upload" *ngIf="item.bannertype === _BannerTypes.IMAGE">
					<mat-label>{{ 'picture' | i18n }}</mat-label>
					<input type="file" (change)="upload($event.target.files)" />
					{{ 'upload_percent' | i18n }}: {{ percentDone }}%
					<img *ngIf="item.picture" src="{{ environment.mediaUrl }}{{ item.picture }}" width="48" />
					<input matInput hidden [(ngModel)]="item.picture" [errorStateMatcher]='validate.picture' name="picture">
					<br />
					<mat-error>{{ errors.picture }}</mat-error>
				</mat-form-field>

				<mat-form-field appearance="outline" class="form-group col-md-6" *ngIf="item.bannertype === _BannerTypes.IMAGE">
					<mat-label>{{ 'url' | i18n }}</mat-label>
					<input matInput [(ngModel)]="item.url" [errorStateMatcher]='validate.url'
						placeholder="{{ 'url' | i18n }}" />
					<mat-error>{{ errors.url }}</mat-error>
				</mat-form-field>

				<mat-form-field appearance="outline" class="form-group col-md-12" *ngIf="item.bannertype === _BannerTypes.CODE">
					<mat-label>{{ 'code' | i18n }}</mat-label>
					<textarea matInput [(ngModel)]="item.code" [errorStateMatcher]='validate.code'
						placeholder="{{ 'code' | i18n }}"></textarea>
					<mat-error>{{ errors.code }}</mat-error>
				</mat-form-field>

			</div>
		</mat-card-content>
	</mat-card>
	<mat-card>
		<mat-card-subtitle>{{ 'categories' | i18n }}</mat-card-subtitle>
		<mat-card-content>
			<div class="row">
				<mat-selection-list [(ngModel)]='item.categories'>
					<mat-list-option *ngFor="let p of categories" [value]="p._id">
						{{ p.name[config.lang] }}
					</mat-list-option>
				</mat-selection-list>
			</div>
		</mat-card-content>
	</mat-card>

	<mat-card>
		<mat-card-subtitle>{{ 'features' | i18n }}</mat-card-subtitle>
		<mat-card-content>
			<div class="row">
				<mat-selection-list [(ngModel)]='item.features'>
					<mat-list-option *ngFor="let p of features" [value]="p._id">
						{{ p.name[config.lang] }}
					</mat-list-option>
				</mat-selection-list>
			</div>
		</mat-card-content>
	</mat-card>


</div>
<div mat-dialog-actions>
	<button mat-flat-button color="primary" (click)="save()">{{ 'save' | i18n }}</button>
	<button mat-flat-button color="warn" (click)="close()">{{ 'close' | i18n }}</button>
</div>
