import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { CityService } from './city.service';
import { DetailsComponent } from './details/details.component';
import { FormComponent } from './form/form.component';
import { BaseIndex } from 'src/app/@core/libraries/pages/base-index';
import { AuthService } from 'src/app/@core/services/auth.service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-city',
	templateUrl: './city.component.html',
	styleUrls: ['./city.component.scss']
})
export class CityComponent extends BaseIndex {

	model = 'city';
	displayedColumns: string[] = ['select', '_id', 'name', 'created', 'status', 'tools'];
	theDetailsComponent = DetailsComponent;
	theFormComponent = FormComponent;
	csv_url: string;

	constructor(protected theService: CityService,
		protected router: Router, protected dialog: MatDialog, public auth: AuthService) {
		super(theService, router, dialog);
		const token = `${this.auth.getToken()}`;
		this.csv_url = `${environment.apiUrl}/city/export_csv?token=${token}`;
	}

}
