import { Injectable } from '@angular/core';
import { BaseModelService } from 'src/app/@core/services/base-model.service';

@Injectable({
	providedIn: 'root',
})


export class EmployeeService extends BaseModelService {

	model: String = 'employee';

}
