<div class="container-fluid" dir="{{ 'app.direction' | i18n }}">

	<nav class="navbar navbar-expand-lg navbar-dark bg-unique d-print-none" *ngIf="hasMainStyle">

		<a href="javascript:void(0)" id="icoMenu" (click)="drawer.toggle()">
			<mat-icon class="icon">menu</mat-icon>
		</a>
		<span></span>
		<a [routerLink]="''" class="navbar-brand font-bold">{{ 'dashboard' | i18n }}</a>

		<div class="collapse navbar-collapse">
			<ul class="navbar-nav ml-auto">
				<li class="nav-item">
					<a href="javascript:void(0)" class="nav-link">
						{{ 'welcome' | i18n }}
						<span class="badge badge-primary">
							{{ user?.fullname }}
						</span>
					</a>
				</li>
				<li class="nav-item">
					<a href="javascript:void(0)" (click)="change_language('ar')" class="navbar-link">
						<i class="flag-icon flag-icon-kw"></i>
					</a>
				</li>
				<li class="nav-item">
					<a href="javascript:void(0)" (click)="change_language('en')" class="navbar-link">
						<i class="flag-icon flag-icon-us"></i>
					</a>
				</li>
				<li class="nav-item">
					<a href="password" class="nav-link">
						{{ 'update_password' | i18n }}
						<mat-icon class="icon">lock</mat-icon>
					</a>
				</li>
				<li class="nav-item">
					<a href="javascript:void(0)" (click)="logout()" class="nav-link">
						{{ 'logout' | i18n }}
						<mat-icon class="icon">exit_to_app</mat-icon>
					</a>
				</li>
			</ul>
		</div>

	</nav>
	<!--/.Navbar -->


	<mat-drawer-container>
		<mat-drawer #drawer class="sidebar d-print-none" [mode]="'side'" [opened]="hasMainStyle">
			<mat-list role="list">
				<mat-list-item *ngFor="let i of pages" role="listitem">
					<a [routerLink]="[i.id]" routerLinkActive="active">
						<button mat-mini-fab>
							<mat-icon class="icon">{{ i.icon }}</mat-icon>
						</button>
						{{ i.title }}
					</a>
				</mat-list-item>
			</mat-list>
		</mat-drawer>
		<mat-drawer-content [class]="user['group']" style="min-height: 100vh">
			<router-outlet></router-outlet>
		</mat-drawer-content>
	</mat-drawer-container>
</div>
