import { Injectable } from '@angular/core';
import { BaseModelService } from 'src/app/@core/services/base-model.service';
import { HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root',
})


export class OrderService extends BaseModelService {

	model: String = 'order';

	getByUser(filter = {}, sort = 'asc', skip = 0, limit = 10): any {
		let params = new HttpParams()
			.set('sort', sort)
			.set('skip', (skip + 1).toString())
			.set('limit', limit.toString());
		const keys = Object.keys(filter);
		for (const k of keys) {
			if (!filter[k]) {
				continue;
			}
			params = params.append(`filter[${k}]`, filter[k]);
		}
		return this.http.get(`${environment.apiUrl}/${this.model}/by_user`, {
			params: params
		});
	}

	getByProduct(filter = {}, sort = 'asc', skip = 0, limit = 10): any {
		let params = new HttpParams()
			.set('sort', sort)
			.set('skip', (skip + 1).toString())
			.set('limit', limit.toString());
		const keys = Object.keys(filter);
		for (const k of keys) {
			if (!filter[k]) {
				continue;
			}
			params = params.append(`filter[${k}]`, filter[k]);
		}
		return this.http.get(`${environment.apiUrl}/${this.model}/by_product`, {
			params: params
		});
	}

}
