import { LanguagesObject } from '../../@core/data/languages-object';
import { ITable } from 'src/app/@core/libraries/interfaces/table';
import { IError } from 'src/app/@core/libraries/interfaces/error';

export class Category implements ITable {

	_id: String = '';
	name: LanguagesObject = new LanguagesObject();
	picture_lang: LanguagesObject = new LanguagesObject();
	parent_id: String = '';
	picture: String = '';
	featured: Number = 0;
	status: Boolean = false;
	category_n_billObj: Object = {};
	category_n_billArr: Array<Object> = [];
	category_n_storeObj: Object = {};
	category_n_storeArr: Array<Object> = [];
	supplier_id: String = '';
	starts_at: Number = 0;
	ends_at: Number = 23;
	max_cart_products: Number = 0;
	max_cart_quantity: Number = 0;
}

export class CategoryError implements IError {

	name: LanguagesObject = new LanguagesObject();
	picture_lang: LanguagesObject = new LanguagesObject();
	parent_id: String = '';
	picture: String = '';
	featured: String = '';
	supplier_id: String = '';
	status: String = '';
	starts_at: String = '';
	ends_at: String = '';
	max_cart_products: String = '';
	max_cart_quantity: String = '';
}


export class CategoryFilter {
	supplier_id?: string = null;
}