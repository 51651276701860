import { Component } from '@angular/core';
import { AppPopup, AppPopupError } from '../app_popup.model';
import { BaseForm } from 'src/app/@core/libraries/pages/base-form';
import { HttpParams } from '@angular/common/http';
import { LanguagesObject } from 'src/app/@core/data/languages-object';
import { environment } from 'src/environments/environment';


@Component({
	selector: 'app-form',
	templateUrl: './form.component.html',
	styleUrls: ['./form.component.scss']
})
export class FormComponent extends BaseForm {

	model = 'app_popup';
	item: AppPopup = new AppPopup();
	errors: AppPopupError = new AppPopupError();
	validate: AppPopupError = new AppPopupError();
	features: Array<any>;

	initialize() {
		this.loadFeatures();
	}

	loadFeatures() {
		this.http.get(`${environment.apiUrl}/feature`, {
			params: new HttpParams()
				.set('filter', 'status=true')
				.set('sort', `name.${this.config.lang}=asc`)
				.set('limit', '9999')
		})
			.subscribe((response) => {
				this.features = response['results']['data'];
			});
	}

	getEmptyErrors() {
		return new AppPopupError();
	}

}
