import { Component, OnInit, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { BaseDeleteService } from './base-delete.service';


@Component({
	selector: 'app-base-delete',
	templateUrl: './base-delete.component.html',
	styleUrls: ['./base-delete.component.scss']
})
export class BaseDeleteComponent implements OnInit {

	constructor(
		private service: BaseDeleteService,
		public dialogRef: MatDialogRef<BaseDeleteComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) { }

	ngOnInit() {
	}

	save(): void {
		if (this.data.ids) {
			for (const id of this.data.ids) {
				this.service.delete(id, this.data.model).subscribe((response) => {
				});
			}
		}
		this.dialogRef.close();
	}

	close(): void {
		this.dialogRef.close();
	}

}
