import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class BaseDeleteService {

	constructor(public http: HttpClient) { }

	delete(id, model): any {
		return this.http.delete(`${environment.apiUrl}/${model}/${id}`);
	}

}
