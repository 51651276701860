import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { CouponService } from './coupon.service';
import { DetailsComponent } from './details/details.component';
import { FormComponent } from './form/form.component';
import { BaseIndex } from 'src/app/@core/libraries/pages/base-index';
import { CouponFilter } from './coupon.model';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/@core/services/auth.service';

@Component({
	selector: 'app-coupon',
	templateUrl: './coupon.component.html',
	styleUrls: ['./coupon.component.scss']
})
export class CouponComponent extends BaseIndex {

	model = 'coupon';
	displayedColumns: string[] = ['select', '_id', 'name', 'discount_value', 'max_uses', 'number_of_uses', 'total_discount', 'created', 'status', 'tools'];
	theDetailsComponent = DetailsComponent;
	theFormComponent = FormComponent;
	csv_url: string;
	searchForm: Boolean = false;
	filter: CouponFilter = new CouponFilter();

	constructor(protected theService: CouponService,
		protected router: Router, protected dialog: MatDialog, public auth: AuthService) {
		super(theService, router, dialog);
		const token = `${this.auth.getToken()}`;

		let filter_queries = '';

		for (let f of Object.keys(this.filter)) {
			filter_queries += `&filter[${f}]=${this.filter[f] ? this.filter[f] : ""}`;
		}
		this.csv_url = `${environment.apiUrl}/coupon/export_csv?token=${token}${filter_queries}`;
	}

	setSearchForm() {
		this.searchForm = !this.searchForm;
	}

	search() {
		this.pagination.pageIndex = 0;
		//this.paginator._markInitialized();
		this.get();
	}

}
