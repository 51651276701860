<form class="form-signin">
	<img id="logo" class="mb-4" src="{{ environment.logoUrl }}" />
	<h1>{{ 'login' | i18n }}</h1>

	<div *ngIf="messages.message" class="alert alert-success" role="alert">
		{{ messages.message }}
	</div>
	<div *ngIf="errors.message" class="alert alert-danger" role="alert">
		{{ errors.message }}
	</div>

	<mat-card>
		<mat-form-field>
			<input matInput type="email" [(ngModel)]="item.email" name="email" class="form-control"
				placeholder="{{ 'email' | i18n }}" required autofocus>
		</mat-form-field>
		<br />

		<mat-form-field>
			<input matInput type="password" [(ngModel)]="item.password" name="password" class="form-control"
				placeholder="{{ 'password' | i18n }}" required>
		</mat-form-field>
		<br />

		<a mat-raised-button color="primary" href="javascript:void(0)" (click)="checkLogin()">{{ 'login' | i18n }}</a>
		<p class="mt-5 mb-3 text-muted">&copy; 2022</p>
	</mat-card>
</form>
