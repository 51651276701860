<h1 mat-dialog-title>{{ data.action | i18n }}</h1>
<div mat-dialog-content>
	<mat-card>
		<mat-card-subtitle>{{ 'details' | i18n }}</mat-card-subtitle>
		<mat-card-content>
			<div class="row">

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'fullname' | i18n }}</mat-label>
					<input matInput [(ngModel)]="item.fullname" [errorStateMatcher]='validate.fullname'
						placeholder="{{ 'fullname' | i18n }}" />
					<mat-error>{{ errors.fullname }}</mat-error>
				</mat-form-field>

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'email' | i18n }}</mat-label>
					<input matInput [(ngModel)]="item.email" [errorStateMatcher]='validate.email'
						placeholder="{{ 'email' | i18n }}" />
					<mat-error>{{ errors.email }}</mat-error>
				</mat-form-field>

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'username' | i18n }}</mat-label>
					<input matInput [(ngModel)]="item.username" [errorStateMatcher]='validate.username'
						placeholder="{{ 'username' | i18n }}" />
					<mat-error>{{ errors.username }}</mat-error>
				</mat-form-field>

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'password' | i18n }}</mat-label>
					<input type="password" matInput [(ngModel)]="item.password" [errorStateMatcher]='validate.password'
						placeholder="{{ 'password' | i18n }}" />
					<mat-error>{{ errors.password }}</mat-error>
				</mat-form-field>

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'group' | i18n }}</mat-label>
					<mat-select placeholder="{{ 'group' | i18n }}" [(ngModel)]="item.group"
						[errorStateMatcher]='validate.group' name="group">
						<mat-option>-- {{ 'remove' | i18n }} --</mat-option>
						<mat-option [value]="'admin'">admin</mat-option>
						<mat-option [value]="'manager'">manager</mat-option>
						<mat-option [value]="'viewer'">viewer</mat-option>
					</mat-select>
				</mat-form-field>

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'country' | i18n }}</mat-label>
					<mat-select placeholder="{{ 'country' | i18n }}" [(ngModel)]="item.country_id"
						[errorStateMatcher]='validate.country_id' name="country_id">
						<mat-option>-- {{ 'remove' | i18n }} --</mat-option>
						<mat-option *ngFor="let c of countries" [value]="c._id">
							{{c.name[config.lang]}}
						</mat-option>
					</mat-select>
				</mat-form-field>

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'can_update_wallet' | i18n }}</mat-label>
					<mat-select placeholder="{{ 'can_update_wallet' | i18n }}" [(ngModel)]='item.can_update_wallet'
						[errorStateMatcher]='validate.can_update_wallet' name="can_update_wallet">
						<mat-option [value]="true">{{ 'yes' | i18n }}</mat-option>
						<mat-option [value]="false">{{ 'no' | i18n }}</mat-option>
					</mat-select>
					<mat-error>{{ errors.can_update_wallet }}</mat-error>
				</mat-form-field>

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'status' | i18n }}</mat-label>
					<mat-select placeholder="{{ 'status' | i18n }}" [(ngModel)]='item.status'
						[errorStateMatcher]='validate.status' name="status">
						<mat-option [value]="true">{{ 'yes' | i18n }}</mat-option>
						<mat-option [value]="false">{{ 'no' | i18n }}</mat-option>
					</mat-select>
					<mat-error>{{ errors.status }}</mat-error>
				</mat-form-field>
			</div>
		</mat-card-content>
	</mat-card>
	<mat-card>
		<mat-card-subtitle>{{ 'permissions' | i18n }}</mat-card-subtitle>
		<mat-card-content>
			<div class="row">
				<mat-selection-list [(ngModel)]='item.permissions'>
					<mat-list-option
									*ngFor="let p of pages"
									[selected]="item.permissions && item.permissions[p.id] == true"
									[value]="p.id">
						{{ p.title }}
					</mat-list-option>
				</mat-selection-list>
			</div>
		</mat-card-content>
	</mat-card>

</div>
<div mat-dialog-actions>
	<button mat-flat-button color="primary" (click)="save()">{{ 'save' | i18n }}</button>
	<button mat-flat-button color="warn" (click)="close()">{{ 'close' | i18n }}</button>
</div>
