import { Component } from '@angular/core';
import { Notification, NotificationError } from '../notification.model';
import { BaseForm } from 'src/app/@core/libraries/pages/base-form';
import { City } from '../../city/city.model';
import { environment } from 'src/environments/environment';
import { HttpEventType, HttpParams, HttpResponse } from '@angular/common/http';

@Component({
	selector: 'app-form',
	templateUrl: './form.component.html',
	styleUrls: ['./form.component.scss']
})
export class FormComponent extends BaseForm {

	model = 'notification';
	item: Notification = new Notification();
	errors: NotificationError = new NotificationError();
	validate: NotificationError = new NotificationError();
	cities: City[] = [];

	getEmptyErrors() {
		return new NotificationError();
	}

	initialize() {
		this.loadCities();
	}

	loadCities() {
		this.http.get(`${environment.apiUrl}/city`, {
			params: new HttpParams()
				.set('filter', `status=true`)
				.set('sort', `name.${this.config.lang}=asc`)
				.set('limit', '999')
		})
			.subscribe((response) => {
				this.cities = response['results']['data'];
			});
	}

	uploadCSV(files: File[]) {
		const formData = new FormData();
		Array.from(files).forEach(f => formData.append('file', f));
		//formData.append("is_update", this.is_csv_update.toString());
		//this.is_csv_processing = true;
		this.http.post(`${environment.apiUrl}/notification/mobiles`, formData, { reportProgress: true, observe: 'events' })
			.subscribe((event: any) => {
				//this.is_csv_processing = false;
				if (event.type === HttpEventType.UploadProgress) {
					this.percentDone = Math.round(100 * event.loaded / event.total);
				} else if (event instanceof HttpResponse) {
					const results = event.body;
					console.log(results);
					this.uploadSuccess = true;
					//this.showMessage(i18n.saved_done);
				}
			});
	}

}
