import { ITable } from 'src/app/@core/libraries/interfaces/table';
import { IError } from 'src/app/@core/libraries/interfaces/error';

export class Adminmember implements ITable {

	_id: String = '';
	fullname: String = '';
	username: String = '';
	password: String = '';
	email: String = '';
	country_id: String = '';
	group: String = '';
	permissions: [] = [];
	can_update_wallet: Boolean = false;
	status: Boolean = false;
}

export class AdminmemberError implements IError {

	fullname: String = '';
	username: String = '';
	password: String = '';
	email: String = '';
	country_id: String = '';
	group: String = '';
	permissions: String = '';
	can_update_wallet: String = '';
	status: String = '';
}
