<h1 mat-dialog-title>{{ data.action | i18n }}</h1>
<div mat-dialog-content>
	<mat-card>
		<mat-card-subtitle>{{ 'translated_details' | i18n }}</mat-card-subtitle>
		<mat-card-content>
			<mat-tab-group>
				<mat-tab *ngFor="let l of languages">
					<ng-template mat-tab-label>
						<i class="flag-icon flag-icon-{{l.image}}"></i>
						{{l.name}}
					</ng-template>
					<div class="col-12" dir="{{l.direction}}">

						<mat-form-field appearance="outline" class="form-group col-md-12">
							<mat-label>{{ 'name' | i18n }} ( {{ l.name }} )</mat-label>
							<input matInput [(ngModel)]="item.name[l.code]" [errorStateMatcher]='validate.name[l.code]'
								placeholder="{{ 'name' | i18n }}" />
							<mat-error>{{ errors.name[l.code] }}</mat-error>
						</mat-form-field>

						<mat-form-field appearance="outline" class="form-group col-md-12">
							<mat-label>{{ 'contents' | i18n }} ( {{ l.name }} )</mat-label>
							<input matInput hidden [(ngModel)]="item.contents[l.code]"
								[errorStateMatcher]='validate?.contents[l.code]' name="contents[l.code]" />
							<ckeditor [(ngModel)]="item.contents[l.code]" [editor]="Editor"></ckeditor>
							<mat-error>{{ errors.contents[l.code] }}</mat-error>
						</mat-form-field>

						<mat-form-field appearance="outline" class="form-group col-md-12">
							<mat-label>{{ 'description' | i18n }} ( {{ l.name }} )</mat-label>
							<textarea matInput [(ngModel)]="item.description[l.code]"
								[errorStateMatcher]='validate?.description[l.code]'
								placeholder="{{ 'description' | i18n }}"></textarea>
							<mat-error>{{ errors.description[l.code] }}</mat-error>
						</mat-form-field>

						<mat-form-field appearance="outline" class="form-group col-md-12">
							<mat-label>{{ 'keywords' | i18n }} ( {{ l.name }} )</mat-label>
							<textarea matInput [(ngModel)]="item.keywords[l.code]"
								[errorStateMatcher]='validate?.keywords[l.code]'
								placeholder="{{ 'keywords' | i18n }}"></textarea>
							<mat-error>{{ errors.keywords[l.code] }}</mat-error>
						</mat-form-field>

					</div>
				</mat-tab>
			</mat-tab-group>
		</mat-card-content>
	</mat-card>
	<mat-divider></mat-divider>

	<mat-card>
		<mat-card-subtitle>{{ 'other_details' | i18n }}</mat-card-subtitle>
		<mat-card-content>
			<div class="row">

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'sorting' | i18n }}</mat-label>
					<input matInput [(ngModel)]="item.sorting" [errorStateMatcher]='validate.sorting'
						placeholder="{{ 'sorting' | i18n }}" />
					<mat-error>{{ errors.sorting }}</mat-error>
				</mat-form-field>


				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'status' | i18n }}</mat-label>
					<mat-select placeholder="{{ 'status' | i18n }}" [(ngModel)]='item.status'
						[errorStateMatcher]='validate.status' name="status">
						<mat-option [value]="true">{{ 'yes' | i18n }}</mat-option>
						<mat-option [value]="false">{{ 'no' | i18n }}</mat-option>
					</mat-select>
					<mat-error>{{ errors.status }}</mat-error>
				</mat-form-field>

			</div>

		</mat-card-content>
	</mat-card>

</div>
<div mat-dialog-actions>
	<button mat-flat-button color="primary" (click)="save()">{{ 'save' | i18n }}</button>
	<button mat-flat-button color="warn" (click)="close()">{{ 'close' | i18n }}</button>
</div>
