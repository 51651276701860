// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	apiUrl: 'https://admin.jm3eia.link/v1',
	// apiUrl: 'https://admin.appmartkw.com/v1',
	// apiUrl: 'http://localhost:5000/v1',
	mediaUrl: 'https://media.jm3eia.link/',
	logoUrl: 'https://jm3eia.com/assets/img/logo.png',
	title: {
		ar: 'لوحة تحكم جمعية دوت كوم',
		en: 'Jm3eia Dashboard'
	},
	allow_managing_quantities: true
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
