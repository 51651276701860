import { Injectable } from '@angular/core';

@Injectable()
export class LanguagesService {

	get() {
		return [
			{
				'_id': '000000000000000000000001',
				'name': 'عربي',
				'code': 'ar',
				'direction': 'rtl',
				'image': 'eg',
				'sotring': 1,
				'status': true,
			},
			{
				'_id': '000000000000000000000002',
				'name': 'English',
				'code': 'en',
				'direction': 'ltr',
				'image': 'us',
				'sotring': 2,
				'status': true,
			},
		];
	}
}
