<h1 mat-dialog-title>{{ 'details' | i18n }}</h1>
<div mat-dialog-content *ngIf="item">
	<mat-card>
		<mat-card-subtitle>{{ 'details' | i18n }}</mat-card-subtitle>
		<mat-card-content>
			<table class="table table-bordered">
				<tbody>
					<tr>
						<td>#</td>
						<td>{{ item._id }}</td>
					</tr>
					<tr>
						<td>{{ 'name' | i18n }}</td>
						<td>{{ item.name }}</td>
					</tr>
					<tr>
						<td>{{ 'code' | i18n }}</td>
						<td>{{ item.code }}</td>
					</tr>
					<tr>
						<td>{{ 'discount_value' | i18n }}</td>
						<td>{{ item.discount_value }}</td>
					</tr>
					<tr>
						<td>{{ 'max_uses' | i18n }}</td>
						<td>{{ item.max_uses }}</td>
					</tr>
					<tr>
						<td>{{ 'percent_value' | i18n }}</td>
						<td>{{ item.percent_value }}</td>
					</tr>
					<tr>
						<td>{{ 'valid_until' | i18n }}</td>
						<td>{{ item.valid_until }}</td>
					</tr>
					<tr>
						<td>{{ 'created' | i18n }}</td>
						<td>{{ item.created }}</td>
					</tr>
					<tr>
						<td>{{ 'modified' | i18n }}</td>
						<td>{{ item.modified }}</td>
					</tr>
					<tr>
						<td>{{ 'lastupdate_user_name' | i18n }}</td>
						<td>{{ item.lastupdate_user_name }}</td>
					</tr>
					<tr>
						<td>{{ 'status' | i18n }}</td>
						<td>{{ item.status }}</td>
					</tr>
				</tbody>
			</table>
		</mat-card-content>
	</mat-card>
</div>
<div mat-dialog-actions>
	<button mat-button color="warn" (click)="close()">{{ 'close' | i18n }}</button>
</div>
