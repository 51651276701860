<mat-card *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center">
	<mat-progress-spinner color="primary" mode="indeterminate">
	</mat-progress-spinner>
</mat-card>
<mat-card *ngIf="!isLoading">
	<mat-card-title>{{ 'settings' | i18n }}</mat-card-title>
	<mat-card-content>

		<mat-card>
			<mat-card-subtitle>{{ 'settings_base' | i18n }}</mat-card-subtitle>
			<mat-card-content>

				<div class="row">

					<mat-form-field appearance="outline" class="form-group col-md-12">
						<mat-checkbox [(ngModel)]="item.jm3eia_pro.allow">
							{{ 'allow' | i18n }}</mat-checkbox>
						<mat-error>{{ errors.jm3eia_pro.allow }}</mat-error>
					</mat-form-field>

					<mat-form-field appearance="outline" class="form-group col-md-6">
						<mat-label>{{ 'shipping_cost' | i18n }}</mat-label>
						<input matInput [(ngModel)]="item.jm3eia_pro.shipping_cost"
							[errorStateMatcher]='validate.jm3eia_pro.shipping_cost'
							placeholder="{{ 'shipping_cost' | i18n }}" />
						<mat-error>{{ errors.jm3eia_pro.shipping_cost }}</mat-error>
					</mat-form-field>

					<mat-form-field appearance="outline" class="form-group col-md-6">
						<mat-checkbox [(ngModel)]="item.jm3eia_pro.only_jm3eia">
							{{ 'only_jm3eia' | i18n }}</mat-checkbox>
						<mat-error>{{ errors.jm3eia_pro.only_jm3eia }}</mat-error>
					</mat-form-field>

				</div>

			</mat-card-content>
		</mat-card>

	</mat-card-content>
	<mat-card-footer>
		<div mat-dialog-actions class="col-6 col-offset-3">
			<button mat-flat-button color="primary" class="btn-block"
				(click)="save()">{{ 'save' | i18n }}</button>
		</div>
		<br class="my-5" />
	</mat-card-footer>
</mat-card>
