<h1 mat-dialog-title>{{ data.action | i18n }}</h1>
<div mat-dialog-content>
	<mat-card>
		<mat-card-subtitle>{{ 'details' | i18n }}</mat-card-subtitle>
		<mat-card-content>
			<div class="col-12">

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'name' | i18n }}</mat-label>
					<input matInput [(ngModel)]="item.name" [errorStateMatcher]='validate.name'
						placeholder="{{ 'name' | i18n }}" />
					<mat-error>{{ errors.name }}</mat-error>
				</mat-form-field>

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'mobile' | i18n }}</mat-label>
					<input matInput [(ngModel)]="item.mobile" [errorStateMatcher]='validate.mobile'
						placeholder="{{ 'mobile' | i18n }}" />
					<mat-error>{{ errors.mobile }}</mat-error>
				</mat-form-field>

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'username' | i18n }}</mat-label>
					<input matInput [(ngModel)]="item.username" [errorStateMatcher]='validate.username'
						placeholder="{{ 'username' | i18n }}" />
					<mat-error>{{ errors.username }}</mat-error>
				</mat-form-field>

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'password' | i18n }}</mat-label>
					<input type="password" matInput [(ngModel)]="item.password" [errorStateMatcher]='validate.password'
						placeholder="{{ 'password' | i18n }}" />
					<mat-error>{{ errors.password }}</mat-error>
				</mat-form-field>

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'department' | i18n }}</mat-label>
					<mat-select placeholder="{{ 'department' | i18n }}" [(ngModel)]="item.department"
						[errorStateMatcher]='validate.department' name="department">
						<mat-option>-- {{ 'remove' | i18n }} --</mat-option>
						<mat-option *ngFor="let c of departments" [value]="c._id">
							{{c.name}}
						</mat-option>
					</mat-select>
					<mat-error>{{ errors.department }}</mat-error>
				</mat-form-field>

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'store' | i18n }}</mat-label>
					<mat-select placeholder="{{ 'store' | i18n }}" [(ngModel)]="item.store_id"
						[errorStateMatcher]='validate.store_id' name="store_id">
						<mat-option>-- {{ 'remove' | i18n }} --</mat-option>
						<mat-option *ngFor="let c of stores" [value]="c._id">
							{{c.name[config.lang]}}
						</mat-option>
					</mat-select>
					<mat-error>{{ errors.store_id }}</mat-error>
				</mat-form-field>

				<!-- <mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'working_times.from' | i18n }}</mat-label>
					<input matInput [(ngModel)]="item.working_times.from" [errorStateMatcher]='validate.working_times.from'
						placeholder="{{ 'working_times.from' | i18n }}" (input)="formatTime($event, 'from')" />
					<mat-error>{{ errors.working_times.from }}</mat-error>
				</mat-form-field>

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'working_times.to' | i18n }}</mat-label>
					<input matInput [(ngModel)]="item.working_times.to" [errorStateMatcher]='validate.working_times.to'
						placeholder="{{ 'working_times.to' | i18n }}" (input)="formatTime($event, 'to')" />
					<mat-error>{{ errors.working_times.to }}</mat-error>
				</mat-form-field> -->

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'working_times.from' | i18n }}</mat-label>
					<mat-select placeholder="{{ 'working_times.from' | i18n }}" [(ngModel)]='item.working_times.from'
						[errorStateMatcher]='validate.working_times.from' name="working_times.from">
						<mat-option>-- {{ 'remove' | i18n }} --</mat-option>
						<mat-option *ngFor="let t of timeOptions" [value]="t">{{ t }}
						</mat-option>
					</mat-select>
					<mat-error>{{ errors.working_times.from }}</mat-error>
				</mat-form-field>

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'working_times.to' | i18n }}</mat-label>
					<mat-select placeholder="{{ 'working_times.to' | i18n }}" [(ngModel)]='item.working_times.to'
						[errorStateMatcher]='validate.working_times.to' name="working_times.to">
						<mat-option>-- {{ 'remove' | i18n }} --</mat-option>
						<mat-option *ngFor="let t of timeOptions" [value]="t">{{ t }}
						</mat-option>
					</mat-select>
					<mat-error>{{ errors.working_times.to }}</mat-error>
				</mat-form-field>

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'max_orders' | i18n }}</mat-label>
					<input type="number" matInput [(ngModel)]="item.max_orders" [errorStateMatcher]='validate.max_orders'
						placeholder="{{ 'max_orders' | i18n }}" />
					<mat-error>{{ errors.max_orders }}</mat-error>
				</mat-form-field>

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'status' | i18n }}</mat-label>
					<mat-select placeholder="{{ 'status' | i18n }}" [(ngModel)]='item.status'
						[errorStateMatcher]='validate.status' name="status">
						<mat-option [value]="true">{{ 'yes' | i18n }}</mat-option>
						<mat-option [value]="false">{{ 'no' | i18n }}</mat-option>
					</mat-select>
					<mat-error>{{ errors.status }}</mat-error>
				</mat-form-field>
			</div>
		</mat-card-content>
	</mat-card>

</div>
<div mat-dialog-actions>
	<button mat-flat-button color="primary" (click)="save()">{{ 'save' | i18n }}</button>
	<button mat-flat-button color="warn" (click)="close()">{{ 'close' | i18n }}</button>
</div>
