import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { ITable } from 'src/app/@core/libraries/interfaces/table';
import { BaseIndex } from 'src/app/@core/libraries/pages/base-index';
import { AuthService } from 'src/app/@core/services/auth.service';
import { environment } from 'src/environments/environment';
import { ReportFilter } from '../report.model';
import { ReportService } from '../report.service';

@Component({
	selector: 'app-point-history',
	templateUrl: './point-history.component.html',
	styleUrls: ['./point-history.component.scss']
})
export class PointHistoryComponent extends BaseIndex {

	model = 'report';
	displayedColumns: string[] = ['select', '_id', 'member_name', 'member_mobile', 'old_points', 'new_points', 'old_wallet', 'new_wallet', 'notes', 'created'];
	theDetailsComponent = null;
	theFormComponent = null;
	searchForm: Boolean = false;
	filter: ReportFilter = new ReportFilter();
	csv_url: string;

	constructor(protected theService: ReportService,
		public auth: AuthService,
		protected router: Router, protected dialog: MatDialog) {
		super(theService, router, dialog);
		const token = `${this.auth.getToken()}`;
		this.csv_url = `${environment.apiUrl}/report/point_history?export=true&token=${token}`;
	}

	ngOnInit(): void {
		//this.get();
		this.isLoading = false;
	}

	get() {
		this.isLoading = true;
		this.theService.getPointHistory(this.filter, '', this.pagination.pageIndex, this.pagination.pageSize).subscribe((response) => {
			this.items = response.results.data;
			this.dataSource.data = response.results.data;
			this.pagination.length = response.results.total;
			this.selection = new SelectionModel<ITable>(true, []);
			this.SelectionIds = [];
			this.isLoading = false;
			this.after_get();
		});
	}

	after_get() {
		const filter = this.filter;
		const keys = Object.keys(this.filter);
		let params = '';
		for (const k of keys) {
			if (!filter[k]) {
				continue;
			}
			params += `&filter[${k}]=${filter[k]}`;
		}
		const token = `${this.auth.getToken()}`;
		this.csv_url = `${environment.apiUrl}/report/point_history?token=${token}&export=true&${params}`;
	}

	setSearchForm() {
		this.searchForm = !this.searchForm;
	}

}
