import { Component } from '@angular/core';
import { Product, ProductError, Option } from '../product.model';
import { BaseForm } from 'src/app/@core/libraries/pages/base-form';
import { environment } from 'src/environments/environment';
import { HttpParams, HttpResponse, HttpEventType } from '@angular/common/http';
import { Category } from '../../category/category.model';
import * as ClassicEditor from 'src/assets/vendors/ckeditor/ckeditor';
import { Store } from '../../store/store.model';
import { Supplier } from '../../supplier/supplier.model';
import { OptionType } from '../../option/option.model';
import { Feature } from '../../feature/feature.model';
import { i18n } from '../../../@core/libraries/i18n';
import { LanguagesObject } from 'src/app/@core/data/languages-object';

@Component({
	selector: 'app-form',
	templateUrl: './form.component.html',
	styleUrls: ['./form.component.scss']
})
export class FormComponent extends BaseForm {

	model = 'product';
	item: Product = new Product();
	errors: ProductError = new ProductError();
	validate: ProductError = new ProductError();
	categories: Category[] = [];
	allCategories: Category[] = [];
	stores: Store[] = [];
	floors: any[];
	supplier: Supplier[] = [];
	categoriesObj: any[] = [];
	productncategory: Object = {
		'category_id': '',
		'rank_id': '',
		'name': {},
		'sorting': 0
	};
	validate_productncategory: Object = {};
	storesObj: any[] = [];
	productnstore: Object = {
		'store_id': '',
		'quantity': 0,
		'status': false
	};
	validate_productnstore: Object = {};
	public Editor = ClassicEditor;
	brands: any;

	allFeatures: Array<Feature> = [];
	features: Array<Feature> = [];
	productnfeature: Object = {
		'feature_id': '',
		'rank_id': '',
		'sorting': 0
	};
	validate_productnfeature: Object = {};
	featuresObj: any[] = [];

	ranks: any;
	featureRanksObj: any[] = [];
	feature_ranks: any;
	suppliers: any = [];
	ranksObj: any[] = [];
	ranksByCategory: any[] = [];
	attribute_groups: any[] = [];
	attributes: any[] = [];
	variants: any[] = [];
	variant_product: any = {};
	validate_variant_product: any = {};
	options: Object[] = [];
	option_type: typeof OptionType = OptionType;
	variant_product_options: Array<any> = [];
	variant_n_store: Object = {
		'store_id': '',
		'quantity': 0,
		'status': false
	};
	validate_variant_n_store: Object = {};
	allow_managing_quantities: boolean = environment.allow_managing_quantities;
	minDate: Date = new Date();
	valid_until_time: { hours: Number, minutes: Number } = { hours: 0, minutes: 0 };
	vip_valid_until_time: { hours: Number, minutes: Number } = { hours: 0, minutes: 0 };
	variant_valid_until_time: { hours: Number, minutes: Number } = { hours: 0, minutes: 0 };
	ranksByFeature: any[] = [];
	option_object: Option = {
		name: new LanguagesObject(),
		required: false,
		type: 'single',
		max_choices: 1,
		choices: [{name: new LanguagesObject(), price: 0, picture: ''}]
	};
	isUpdate = false;
	isRestaurant = false;
	isPoints = false;
	isDuplicateAction = false;
	initialize() {
		if (this.data.action === 'add' && this.data.id !== '') {
			this.isDuplicateAction = true;
		}
		this.loadBrands();
		this.loadSuppliers();
		this.loadCategories();
		this.loadFeatures();
		this.loadRanks();
		this.loadFeatureRanks();
		this.loadStores();
		this.loadOptions();
		this.floors = [
			{name: i18n.product_floors[1], _id: 1},
			{name: i18n.product_floors[2], _id: 2},
			{name: i18n.product_floors[3], _id: 3},
			{name: i18n.product_floors[4], _id: 4},
			{name: i18n.product_floors[5], _id: 5},
		];
		
	}

	afterInit() {

		if (this.allCategories.length > 0) {
			if (this.item.supplier_id) {
				this.categoriesObj = [];
				this.categories = this.allCategories.filter(c => c.supplier_id == this.item.supplier_id);
				for (const i of this.categories) {
					this.categoriesObj[i._id.toString()] = i.name[this.config.lang];
				}
			} else {
				this.categoriesObj = [];
				this.categories = this.allCategories.filter(c => !c.supplier_id);
				for (const i of this.categories) {
					this.categoriesObj[i._id.toString()] = i.name[this.config.lang];
				}
			}
		}

		if (this.allFeatures.length > 0) {
			if (this.item.supplier_id) {
				this.featuresObj = [];
				this.features = this.allFeatures.filter(c => c.supplier_id == this.item.supplier_id);
				for (const i of this.features) {
					this.featuresObj[i._id.toString()] = i.name[this.config.lang];
				}
			} else {
				this.featuresObj = [];
				this.features = this.allFeatures.filter(c => !c.supplier_id);
				for (const i of this.features) {
					this.featuresObj[i._id.toString()] = i.name[this.config.lang];
				}
			}
		}

		if (this.item && this.item.supplier_id && this.suppliers) {
			const supplier = this.suppliers.find(s => s._id === this.item.supplier_id);
			this.isRestaurant = supplier && supplier.has_options;
		}

		if (this.item.discount_price_valid_until) {
			this.valid_until_time.hours = new Date(this.item.discount_price_valid_until).getHours();
			this.valid_until_time.minutes = new Date(this.item.discount_price_valid_until).getMinutes();
		}

		if (this.item.vip_discount_price_valid_until) {
			this.item.vip_discount_price_valid_until = new Date(this.item.vip_discount_price_valid_until);
			this.vip_valid_until_time.hours = this.item.vip_discount_price_valid_until.getHours();
			this.vip_valid_until_time.minutes = this.item.vip_discount_price_valid_until.getMinutes();
		}

		if (this.item.variants && this.item.variants.length > 0) {
			this.item.variants = this.item.variants.map(v => {
				if (v.discount_price_valid_until) {
					v.discount_price_valid_until = new Date(v.discount_price_valid_until);
					v.hours = v.discount_price_valid_until.getHours();
					v.minutes = v.discount_price_valid_until.getMinutes();
				}

				return v;
			});

		}

		if (!this.item.base_product) {
			this.item.base_product = {};
		}

		if (this.item._id) {
			this.isUpdate = true;
		}
	}

	loadAttributeGroups() {
		this.http.get(`${environment.apiUrl}/attribute_group`, {
			params: new HttpParams()
				.set('filter', 'status=true')
				.set('sort', `name.${this.config.lang}=asc`)
				.set('limit', '5000')
		})
			.subscribe((response) => {
				this.attribute_groups = response['results']['data'];
			});
	}

	loadOptions() {
		this.http.get(`${environment.apiUrl}/option`, {
			params: new HttpParams()
				.set('filter', `status=true`)
				.set('sort', `name.${this.config.lang}=asc`)
				.set('limit', '5000')
		})
			.subscribe((response) => {
				this.options = response['results']['data'].map((o) => {
					o.options_list = o.options_list.map((i) => {
						delete o._id;
						delete o.created;
						delete o.status;
						delete o.lastUser_update;
						delete o.created;
						delete o.modified;
						i.type = o.type;
						i.option = o;
						return i;
					});
					return o;
				});
				console.log("options: ", this.options);
			});
	}

	loadBrands() {
		this.http.get(`${environment.apiUrl}/brand`, {
			params: new HttpParams()
				.set('filter', 'status=true')
				.set('sort', `name.${this.config.lang}=asc`)
				.set('limit', '5000')
		})
			.subscribe((response) => {
				this.brands = response['results']['data'];
			});
	}

	loadCategories() {
		this.http.get(`${environment.apiUrl}/category`, {
			params: new HttpParams()
				.set('filter', 'status=true')
				.set('sort', `name.${this.config.lang}=asc`)
				.set('limit', '5000')
		})
			.subscribe((response) => {
				this.allCategories = response['results']['data'];
				if (this.item.supplier_id) {
					this.categoriesObj = [];
					this.categories = this.allCategories.filter(c => c.supplier_id == this.item.supplier_id);
					for (const i of this.categories) {
						this.categoriesObj[i._id.toString()] = i.name[this.config.lang];
					}
				} else {
					this.categoriesObj = [];
					this.categories = this.allCategories.filter(c => !c.supplier_id);
					for (const i of this.categories) {
						this.categoriesObj[i._id.toString()] = i.name[this.config.lang];
					}
				}
			});
	}

	loadFeatures() {
		this.http.get(`${environment.apiUrl}/feature`, {
			params: new HttpParams()
				.set('filter', 'status=true')
				.set('sort', `name.${this.config.lang}=asc`)
				.set('limit', '5000')
		})
			.subscribe((response) => {

				this.allFeatures = response['results']['data'];
				if (this.item.supplier_id) {
					this.featuresObj = [];
					this.features = this.allFeatures.filter(c => c.supplier_id == this.item.supplier_id);
					for (const i of this.features) {
						this.featuresObj[i._id.toString()] = i.name[this.config.lang];
					}
				} else {
					this.featuresObj = [];
					this.features = this.allFeatures.filter(c => !c.supplier_id);
					for (const i of this.features) {
						this.featuresObj[i._id.toString()] = i.name[this.config.lang];
					}
				}
			});
	}

	loadRanks() {
		this.http.get(`${environment.apiUrl}/rank`, {
			params: new HttpParams()
				.set('filter', 'status=true')
				.set('sort', `name.${this.config.lang}=asc`)
				.set('limit', '5000')
		})
			.subscribe((response) => {
				this.ranksObj = [];
				this.ranks = response['results']['data'];
				for (const i of this.ranks) {
					this.ranksObj[i._id.toString()] = i.name[this.config.lang];
				}
			});
	}

	loadFeatureRanks() {
		this.http.get(`${environment.apiUrl}/feature_rank`, {
			params: new HttpParams()
				.set('filter', 'status=true')
				.set('sort', `name.${this.config.lang}=asc`)
				.set('limit', '5000')
		})
			.subscribe((response) => {
				this.featureRanksObj = [];
				this.feature_ranks = response['results']['data'];
				for (const i of this.ranks) {
					this.featureRanksObj[i._id.toString()] = i.name[this.config.lang];
				}
			});
	}

	loadFeatureRanksByCategory() {
		console.log("All: ", this.feature_ranks);
		console.log("Filtered: ", this.feature_ranks);
		this.ranksByFeature = this.feature_ranks.filter(r => r.feature_id === this.productnfeature['feature_id']);
	}

	loadRanksByCategory() {
		this.http.get(`${environment.apiUrl}/rank`, {
			params: new HttpParams()
				.set('filter', `status=true&category_id=${this.productncategory['category_id']}`)
				.set('sort', `name.${this.config.lang}=asc`)
				.set('limit', '5000')
		})
			.subscribe((response) => {
				this.ranksByCategory = response['results']['data'];
			});
	}

	loadRanksByCategoryLocaly(id) {
		return this.ranks ? this.ranks.filter((i) => i.category_id && id && i.category_id.toString() === id.toString()) : [];
	}

	loadFeatureRanksByCategoryLocaly(id) {
		return this.feature_ranks ? this.feature_ranks.filter((i) => i.feature_id && id && i.feature_id.toString() === id.toString()) : [];
	}

	loadStores() {
		this.http.get(`${environment.apiUrl}/store`, {
			params: new HttpParams()
				.set('filter', 'status=true')
				.set('sort', `name.${this.config.lang}=asc`)
				.set('limit', '5000')
		})
			.subscribe((response) => {
				this.stores = response['results']['data'];
				for (const i of this.stores) {
					this.storesObj[i._id.toString()] = i.name[this.config.lang];
				}
				if (this.item && this.item.prod_n_storeArr) {
					const available_store_ids = this.stores.map((i) => i._id.toString());
					this.item.prod_n_storeArr = this.item.prod_n_storeArr.filter((i) => available_store_ids.indexOf(i['store_id']) !== -1);
				}
			});
	}


	loadSuppliers() {
		this.http.get(`${environment.apiUrl}/supplier`, {
			params: new HttpParams()
				.set('filter', 'status=true')
				.set('sort', `name.${this.config.lang}=asc`)
				.set('limit', '5000')
		})
			.subscribe((response) => {
				this.suppliers = response['results']['data'];
			});
	}

	add_productncategory() {
		if (this.item.prod_n_categoryArr === undefined) {
			this.item.prod_n_categoryArr = [];
		}
		this.item.prod_n_categoryArr.push(Object.assign({}, this.productncategory));
		this.productncategory = {};
		this.item.prod_n_categoryArr = this.removeDuplicates(this.item.prod_n_categoryArr, 'category_id');
	}

	add_productnfeature() {
		if (this.item.features === undefined) {
			this.item.features = [];
		}
		this.item.features.push(Object.assign({}, this.productnfeature));
		this.productnfeature = {};
		this.item.features = this.removeDuplicates(this.item.features, 'feature_id');
	}

	add_productnstore() {
		if (this.item.prod_n_storeArr === undefined) {
			this.item.prod_n_storeArr = [];
		}
		this.item.prod_n_storeArr.push(Object.assign({}, this.productnstore));
		this.productnstore = {};
		this.item.prod_n_storeArr = this.removeDuplicates(this.item.prod_n_storeArr, 'store_id');
	}

	add_variantnstore() {
		if (this.variant_product.prod_n_storeArr === undefined) {
			this.variant_product.prod_n_storeArr = [];
		}
		this.variant_product.prod_n_storeArr.push(Object.assign({}, this.variant_n_store));
		this.variant_n_store = {};
		this.variant_product.prod_n_storeArr = this.removeDuplicates(this.variant_product.prod_n_storeArr, 'store_id');
	}

	add_variant_product() {
		if (this.item.variants === undefined) {
			this.item.variants = [];
		}
		console.log("variant_product_options: ", this.variant_product_options);
		this.variant_product.options = this.variant_product_options.filter(o => o && o.sku_code);
		console.log("production options: ", this.variant_product.options);
		this.variant_product.sku = this.item.sku;
		this.variant_product.price = this.variant_product.price ? parseFloat(this.variant_product.price.toString()) : 0;
		this.variant_product.old_price = this.variant_product.old_price ? parseFloat(this.variant_product.old_price.toString()) : 0;
		this.variant_product.hours = this.variant_valid_until_time.hours;
		this.variant_product.minutes = this.variant_valid_until_time.minutes;
		if (this.variant_product_options) {
			this.variant_product.sku += '-';
			for (const o of this.variant_product_options) {
				if (o && o.sku_code) {
					this.variant_product.sku += o.sku_code;
				}
			}
			this.item.variants.push(this.variant_product);
			this.variant_product = {};
			this.variant_product_options = [];
		}
		this.item.variants = Object.values(this.item.variants);
		this.item.variants = this.removeDuplicates(this.item.variants, 'sku');
	}

	getBeforeSave() {
		const data = this.item;


		// category
		const prod_in_category = data.prod_n_categoryArr;
		if (data.prod_n_categoryObj === undefined) {
			data.prod_n_categoryObj = {};
		}
		if (prod_in_category) {
			for (const i of prod_in_category) {
				const category = this.categories.find((r) => r._id.toString() === i['category_id'].toString());
				if (category) {
					data.prod_n_categoryObj[i['category_id']] = {
						'name': category.name,
						'sorting': i['sorting'],
					};
				}
			}
		}
		// category

		// store
		const prod_in_store = data.prod_n_storeArr;
		if (data.prod_n_storeObj === undefined) {
			data.prod_n_storeObj = {};
		}
		if (prod_in_store) {
			for (const i of prod_in_store) {
				data.prod_n_storeObj[i['store_id']] = {
					'quantity': i['quantity'],
					'status': i['status'],
				};
			}
		}
		console.log('Data variants: ', data.variants);
		// store
		return data;
	}

	getEmptyErrors() {
		return new ProductError();
	}

	updateCnB(rank_id) {
		this.item.prod_n_categoryArr = this.deleteFromObj(this.item.prod_n_categoryArr, 'rank_id', rank_id);
	}

	updatePnF(feature_id) {
		this.item.features = this.deleteFromObj(this.item.features, 'feature_id', feature_id);
	}

	updatePnS(store_id) {
		this.item.prod_n_storeArr = this.deleteFromObj(this.item.prod_n_storeArr, 'store_id', store_id);
	}

	updateVnS(store_id) {
		this.variant_product.prod_n_storeArr = this.deleteFromObj(this.variant_product.prod_n_storeArr, 'store_id', store_id);
	}

	feed_from_store(store_id) {
		const store = this.item && this.item.prod_n_storeArr && this.item.prod_n_storeArr.find((i) => i['store_id'] == store_id);
		return store && store['quantity'] ? parseInt(store['quantity']) : 0;
	}

	update_softcode() {
		this.item.soft_code = this.item.sku;
		if (this.item.variants && this.item.variants.length > 0) {
			this.item.variants = this.item.variants.map(v => {
				const splitted = v.sku.split('-');
				if (this.item.sku !== splitted[0]) {
					v.sku = `${this.item.sku}-${splitted[1]}`;
				}
				return v;
			})
		}
	}

	upload_multi(files: File[]) {
		// pick from one of the 4 styles of file uploads below
		this.upload_multiAndProgress(files);
	}

	upload_multiAndProgress(files: File[]) {
		this.item.gallery_pictures = this.item.gallery_pictures || [];
		for (const _file of files) {
			const formData = new FormData();
			const file: File = _file;
			Array.from([file]).forEach(f => formData.append('picture', f));

			this.http.post(`${environment.apiUrl}/upload`, formData, { reportProgress: true, observe: 'events' })
				.subscribe((event: any) => {
					if (event.type === HttpEventType.UploadProgress) {
					} else if (event instanceof HttpResponse) {
						this.item.gallery_pictures.push(event.body.results.path);
					}
				});
		}
	}

	upload_choice_picture(files: File[], index: number) {
		this.uploadImagesAndProgress(files, '').then((res: any) => {
			this.option_object.choices[index].picture = res.path;
		});
	}

	/**
	 * Upload variant images
	 * @param {Array<File>} files - Files to upload
	 * @param {any} variant - Variant object **(Only To Update Variant!**) | Used to add variant images to `gallery_pictures` property when uploading variant images
	 */
	upload_multi_variant(files: File[], variant: any = undefined) {
		this.variant_product.gallery_pictures = this.variant_product.gallery_pictures || [];
		for (const _file of files) {
			const formData = new FormData();
			const file: File = _file;
			Array.from([file]).forEach(f => formData.append('picture', f));

			this.http.post(`${environment.apiUrl}/upload`, formData, { reportProgress: true, observe: 'events' })
				.subscribe((event: any) => {
					if (event.type === HttpEventType.UploadProgress) {
					} else if (event instanceof HttpResponse) {
						this.variant_product.gallery_pictures.push(event.body.results.path);
						if (variant != undefined) {
							// Add the each uploaded image to the variant gallery pictures array
							variant.gallery_pictures = variant.gallery_pictures || [];
							variant.gallery_pictures.push(event.body.results.path);
						}
					}
				});
		}
	}

	deletePicture(pic) {
		this.item.gallery_pictures = this.item.gallery_pictures.filter(function (value, index, arr) {
			return pic !== value;
		});
	}

	deleteVariant_product(sku) {
		this.item.variants = this.item.variants.filter(function (value: any, index, arr) {
			return sku !== value.sku;
		});
	}

	/**
	 * Delete variant product picture from the gallery pictures
	 * @param {String} pic - The image url to delete
	 * @param {any} variant - Variant object **(Only To Update Variant!**) | Used to remove variant image from `gallery_pictures` property
	 */
	deletePicture_variant_product(pic, variant: any = undefined) {
		if (variant !== undefined) {
			// Remove an image from the gallery pictured array
			variant.gallery_pictures = variant.gallery_pictures.filter(function (value, index, arr) {
				return pic !== value;
			});
		} else {
			this.variant_product.gallery_pictures = this.variant_product.gallery_pictures.filter(function (value, index, arr) {
				return pic !== value;
			});
		}
	}

	// safely handles circular references
	safeStringify(obj, indent = 2) {
		let cache = [];
		const retVal = JSON.stringify(
			obj,
			(key, value) =>
				typeof value === "object" && value !== null
					? cache.includes(value)
						? undefined // Duplicate reference found, discard key
						: cache.push(value) && value // Store value in our collection
					: value,
			indent
		);
		cache = null;
		return retVal;
	};

	setValidUntilTime(isVariant = false, variant_sku = '', isVip = false) {
		if (isVariant) {
			if (variant_sku) {
				const foundVariantIndex = this.item.variants.findIndex(v => v.sku == variant_sku);
				if (foundVariantIndex < 0) return;
				this.item.variants[foundVariantIndex].hours = new Date().getHours();
				this.item.variants[foundVariantIndex].minutes = new Date().getMinutes();
				this.item.variants[foundVariantIndex].discount_price_valid_until.setHours(this.item.variants[foundVariantIndex].hours as number, this.item.variants[foundVariantIndex].minutes as number);
			} else {
				this.variant_valid_until_time.hours = new Date().getHours();
				this.variant_valid_until_time.minutes = new Date().getMinutes();
				this.variant_product.discount_price_valid_until.setHours(this.variant_valid_until_time.hours as number, this.variant_valid_until_time.minutes as number);
			}
		} else if (isVip) {
			this.vip_valid_until_time.hours = new Date().getHours();
			this.vip_valid_until_time.minutes = new Date().getMinutes();
			this.item.vip_discount_price_valid_until.setHours(this.vip_valid_until_time.hours as number, this.vip_valid_until_time.minutes as number);
		} else {
			this.valid_until_time.hours = new Date().getHours();
			this.valid_until_time.minutes = new Date().getMinutes();
			this.item.discount_price_valid_until.setHours(this.valid_until_time.hours as number, this.valid_until_time.minutes as number);
		}
	}

	timeChangedHandeler(isVariant = false, variant_sku = '', isVip = false) {
		if (isVariant) {
			if (variant_sku) {
				const foundVariantIndex = this.item.variants.findIndex(v => v.sku == variant_sku);
				if (foundVariantIndex < 0) return;
				this.item.variants[foundVariantIndex].discount_price_valid_until.setHours(this.item.variants[foundVariantIndex].hours as number, this.item.variants[foundVariantIndex].minutes as number);
			} else {
				this.variant_product.discount_price_valid_until.setHours(this.variant_valid_until_time.hours as number, this.variant_valid_until_time.minutes as number);
			}
		} else if (isVip) {
			this.item.vip_discount_price_valid_until.setHours(this.vip_valid_until_time.hours as number, this.vip_valid_until_time.minutes as number);
			console.log('it is vip: ', this.vip_valid_until_time, this.item.vip_discount_price_valid_until);
		} else {
			this.item.discount_price_valid_until.setHours(this.valid_until_time.hours as number, this.valid_until_time.minutes as number);
		}
	}


	add_option() {
		if (!this.item.options) {
			if (this.item.options === undefined) {
				this.item.options = [];
			}
		}

		if (this.option_object.type === "single") this.option_object.max_choices = 1;

		this.item.options.push(this.option_object);

		this.option_object = {
			name: new LanguagesObject(),
			required: false,
			type: 'single',
			max_choices: 1,
			choices: [{name: new LanguagesObject(), price: 0, picture: ''}]
		}

	}

	delete_option(index: any) {
		this.item.options.splice(index, 1);
	}

	delete_option_choice(optionIndex: any, choiceIndex: any) {
		this.item.options[optionIndex].choices.splice(choiceIndex, 1);
	}

	add_choice() {
		this.option_object.choices.push({name: new LanguagesObject(), price: 0, picture: ''});
	}

	delete_choice(index: any) {
		if (this.option_object.choices.length === 1) return;
		this.option_object.choices.splice(index, 1);
	}

	supplierChangeHander() {
		if (this.item && this.item.supplier_id) {
			const supplier = this.suppliers.find(s => s._id === this.item.supplier_id);
			this.isRestaurant = supplier && supplier.has_options;
		}

		if (this.allCategories.length > 0) {
			if (this.item.supplier_id) {
				this.categoriesObj = [];
				this.categories = this.allCategories.filter(c => c.supplier_id == this.item.supplier_id);
				for (const i of this.categories) {
					this.categoriesObj[i._id.toString()] = i.name[this.config.lang];
				}
			} else {
				this.categoriesObj = [];
				this.categories = this.allCategories.filter(c => !c.supplier_id);
				for (const i of this.categories) {
					this.categoriesObj[i._id.toString()] = i.name[this.config.lang];
				}
			}
		}

		if (this.allFeatures.length > 0) {
			if (this.item.supplier_id) {
				this.featuresObj = [];
				this.features = this.allFeatures.filter(c => c.supplier_id == this.item.supplier_id);
				for (const i of this.features) {
					this.featuresObj[i._id.toString()] = i.name[this.config.lang];
				}
			} else {
				this.featuresObj = [];
				this.features = this.allFeatures.filter(c => !c.supplier_id);
				for (const i of this.features) {
					this.featuresObj[i._id.toString()] = i.name[this.config.lang];
				}
			}
		}
	}

}
