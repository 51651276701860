export class BannerPosition {

	public _id: String = '';
	public name: String = '';

	constructor(_id: string, name: String) {
		this._id = _id;
		this.name = name;
	}
}
