import { Component, OnInit, ViewChild, Injectable, Directive } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { MatPaginator, MatTableDataSource, MatSort, Sort } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { ITable } from '../interfaces/table';
import { IService } from '../interfaces/service';
import { BaseDeleteComponent } from '../../theme/base-delete/base-delete.component';

@Injectable()
export class BaseIndex implements OnInit {

	model: String;
	sort_data: boolean = false;
	active: string;
	direction: string = 'asc';
	// is_sort:boolean=false;

	theDetailsComponent: any;
	theFormComponent: any;
	displayedColumns: string[] = ['select', '_id', 'tools'];

	items: ITable[] = [];
	isLoading = true;
	config = {
		lang: localStorage.getItem('lang') || 'en',
	};
	selectedID = '';
	dataSource = new MatTableDataSource<ITable>([]);
	selection = new SelectionModel<ITable>(true, []);
	SelectionIds: Array<String> = [];
	pagination: any = {
		length: 0,
		pageSize: 50,
		pageIndex: 0,
		pageSizeOptions: [50, 100, 250, 500]
	};
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;
	formWidth: String = '600px';
	formHeight: String = '95%';
	detailsWidth: String = '600px';
	filter: any = {};
	total_sells_price: Number = 0;

	constructor(protected theService: IService,
		protected router: Router, protected dialog: MatDialog) { }

	ngOnInit() {
		this.dataSource = new MatTableDataSource<ITable>(this.items);
		this.get();
	}

	sortData(s) {
		this.sort_data = true;
		this.active = s.active;
		this.direction = s.direction;
		this.get();

	}

	get() {
		this.isLoading = true;

		if (this.sort_data == true) {
			this.filter.is_sort = this.sort_data;
			this.filter.active = this.active;
			this.filter.direction = this.direction;
		}

		this.theService.getAll(this.filter, this.filter.direction, this.pagination.pageIndex, this.pagination.pageSize).subscribe((response) => {
			this.items = response.results.data;
			this.dataSource.data = response.results.data;
			this.pagination.length = response.results.total;
			this.total_sells_price = response.results.total_sells_price;
			this.selection = new SelectionModel<ITable>(true, []);
			this.SelectionIds = [];
			this.isLoading = false;
			this.after_get();
		});
	}

	after_get() { }

	next(event) {
		this.pagination.pageSize = event.pageSize;
		this.pagination.pageIndex = event.pageIndex;
		this.get();
	}

	openDetails(id): void {
		this.dialog.open(this.theDetailsComponent, {
			minWidth: this.detailsWidth.toString(),
			data: { id: id },
			disableClose: true
		});
		this.afterCloseDetails();
	}

	afterCloseDetails() { }

	openForm(action, id = '') {
		if (action === 'edit' && !id) {
			return false;
		}
		const dialogRef = this.dialog.open(this.theFormComponent, {
			minWidth: this.formWidth.toString(),
			minHeight: this.formHeight.toString(),
			data: {
				action: action,
				id: id,
			},
			disableClose: true
		});

		dialogRef.afterClosed().subscribe(() => {
			this.get();
		});
	}

	openDelete(id = null) {
		const dialogRef = this.dialog.open(BaseDeleteComponent, {
			minWidth: '600px',
			data: {
				ids: id ? [id] : this.SelectionIds,
				model: this.model
			},
			disableClose: true
		});

		dialogRef.afterClosed().subscribe(() => {
			this.get();
		});
	}

	/** Whether the number of selected elements matches the total number of rows. */
	isAllSelected() {
		const numSelected = this.selection.selected.length;
		const numRows = this.dataSource.data.length;
		return numSelected === numRows;
	}

	/** Selects all rows if they are not all selected; otherwise clear selection. */
	masterToggle() {
		this.isAllSelected() ?
			this.selection.clear() :
			this.dataSource.data.forEach(row => this.selection.select(row));
		this.updateSelectionIds();
	}

	selectAll() {
		this.dataSource.data.forEach(row => this.selection.select(row));
		this.updateSelectionIds();
	}

	deselectAll() {
		this.selection.clear();
		this.updateSelectionIds();
	}

	updateSelectionIds() {
		this.SelectionIds = this.selection.selected.map(i => i._id);
	}

}
