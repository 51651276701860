import { Component } from '@angular/core';
import { Member, MemberError, Address } from '../member.model';
import { BaseForm } from 'src/app/@core/libraries/pages/base-form';
import { Country } from '../../country/country.model';
import { environment } from 'src/environments/environment';
import { HttpParams } from '@angular/common/http';
import { City } from '../../city/city.model';

@Component({
	selector: 'app-form',
	templateUrl: './form.component.html',
	styleUrls: ['./form.component.scss']
})
export class FormComponent extends BaseForm {

	model = 'member';
	item: Member = new Member();
	errors: MemberError = new MemberError();
	validate: MemberError = new MemberError();
	countries: Country[] = [];
	cities: City[] = [];
	storesObj: any[] = [];
	new_address: Address = new Address();
	validate_new_address: Object = {};
	user: any = {};

	initialize() {
		this.loadCountries();
		this.loadCities();
		let user = localStorage.getItem('auth.user');
		this.user = user = user ? JSON.parse(user) : {};
	}

	afterInit() {
		this.item.password = '';
	}

	loadCountries() {
		this.http.get(`${environment.apiUrl}/country`, {
			params: new HttpParams()
				.set('filter', 'status=true')
				.set('sort', `name.${this.config.lang}=asc`)
				.set('limit', '999')
		})
			.subscribe((response) => {
				this.countries = response['results']['data'];
			});
	}

	loadCities() {
		this.http.get(`${environment.apiUrl}/city`, {
			params: new HttpParams()
				.set('filter', `status=true&country_id=${this.new_address.country_id}`)
				.set('sort', `name.${this.config.lang}=asc`)
				.set('limit', '999')
		})
			.subscribe((response) => {
				this.cities = response['results']['data'];
			});
	}

	add_new_address() {
		if (this.item.addresses === undefined) {
			this.item.addresses = [];
		}
		this.item.addresses.push(Object.assign({}, this.new_address));
		this.new_address = new Address();
		this.item.addresses = this.removeDuplicates(this.item.addresses, 'name');

	}

	edit_address(p) {
		this.new_address = p;
	}

	delete_address(id) {
		this.item.addresses = this.deleteFromObj(this.item.addresses, 'id', id);
	}

	getEmptyErrors() {
		return new MemberError();
	}

}
