import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ThemeModule } from '../../@core/modules/theme.module';
import { OrderComponent } from './order.component';
import { DetailsComponent } from './details/details.component';
import { BillComponent } from './bill/bill.component';
import { RouterModule } from '@angular/router';
import { ByUserComponent } from './by-user/by-user.component';
import { ByProductComponent } from './by-product/by-product.component';
import { IssueComponent } from './issue/issue.component';
import { IssuesComponent } from './issues/issues.component';
import { ByZeroProductsComponent } from './by-zero-products/by-zero-products.component';

const COMPONENTS = [
	OrderComponent,
	DetailsComponent,
	BillComponent,
];

@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		ThemeModule,
	],
	declarations: [...COMPONENTS, ByUserComponent, ByProductComponent, IssueComponent, IssuesComponent, ByZeroProductsComponent],
	entryComponents: COMPONENTS
})
export class OrderModule { }
