import { Component } from '@angular/core';
import { Country, CountryError } from '../country.model';
import { BaseForm } from 'src/app/@core/libraries/pages/base-form';

@Component({
	selector: 'app-form',
	templateUrl: './form.component.html',
	styleUrls: ['./form.component.scss']
})
export class FormComponent extends BaseForm {

	model = 'country';
	item: Country = new Country();
	errors: CountryError = new CountryError();
	validate: CountryError = new CountryError();

	getEmptyErrors() {
		return new CountryError();
	}

}
