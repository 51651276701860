<h1 mat-dialog-title>{{ 'details' | i18n }}</h1>
<div mat-dialog-content *ngIf="item">
	<mat-card>
		<mat-card-content>
			<table class="table table-bordered">
				<tbody>
					<tr>
						<td>#</td>
						<td>{{ item._id }}</td>
					</tr>
					<tr>
						<td>{{ 'fullname' | i18n }}</td>
						<td>{{ item.fullname }}</td>
					</tr>
					<tr>
						<td>{{ 'username' | i18n }}</td>
						<td>{{ item.username }}</td>
					</tr>
					<tr>
						<td>{{ 'password' | i18n }}</td>
						<td>********</td>
					</tr>
					<tr>
						<td>{{ 'email' | i18n }}</td>
						<td>{{ item.email }}</td>
					</tr>
					<tr>
						<td>{{ 'mobile' | i18n }}</td>
						<td>{{ item.mobile }}</td>
					</tr>
					<tr>
						<td>{{ 'points' | i18n }}</td>
						<td>{{ item.points }}</td>
					</tr>
					<tr>
						<td>{{ 'wallet' | i18n }}</td>
						<td>{{ item.wallet }}</td>
					</tr>
					<tr>
						<td>{{ 'widget' | i18n }}</td>
						<td>{{ item.address.widget }}</td>
					</tr>
					<tr>
						<td>{{ 'street' | i18n }}</td>
						<td>{{ item.address.street }}</td>
					</tr>
					<tr>
						<td>{{ 'gada' | i18n }}</td>
						<td>{{ item.address.gada }}</td>
					</tr>
					<tr>
						<td>{{ 'created' | i18n }}</td>
						<td>{{ item.created }}</td>
					</tr>
					<tr>
						<td>{{ 'modified' | i18n }}</td>
						<td>{{ item.modified }}</td>
					</tr>
					<tr>
						<td>{{ 'lastupdate_user_name' | i18n }}</td>
						<td>{{ item.lastupdate_user_name }}</td>
					</tr>
					<tr>
						<td>{{ 'status' | i18n }}</td>
						<td>{{ item.status }}</td>
					</tr>
				</tbody>
			</table>
		</mat-card-content>
	</mat-card>
</div>
<div mat-dialog-actions>
	<button mat-button color="warn" (click)="close()">{{ 'close' | i18n }}</button>
</div>
