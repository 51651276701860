import { Component } from '@angular/core';
import { RewardFeature, RewardFeatureError } from '../reward_feature.model';
import { BaseForm } from 'src/app/@core/libraries/pages/base-form';
import { environment } from 'src/environments/environment';
import { HttpParams } from '@angular/common/http';
import { Supplier } from '../../supplier/supplier.model';
import { LanguagesObject } from 'src/app/@core/data/languages-object';

@Component({
	selector: 'app-form',
	templateUrl: './form.component.html',
	styleUrls: ['./form.component.scss']
})
export class FormComponent extends BaseForm {

	model = 'reward_feature';
	item: RewardFeature = new RewardFeature();
	errors: RewardFeatureError = new RewardFeatureError();
	validate: RewardFeatureError = new RewardFeatureError();

	getEmptyErrors() {
		return new RewardFeatureError();
	}

}
