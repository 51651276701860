import { ITable } from 'src/app/@core/libraries/interfaces/table';
import { IError } from 'src/app/@core/libraries/interfaces/error';
import { LanguagesObject } from 'src/app/@core/data/languages-object';

export class Notification implements ITable {

	_id: String = '';
	title: LanguagesObject = new LanguagesObject();
	body: LanguagesObject = new LanguagesObject();
	url?: String = null;
	opened_count: Number = 0;
	cities?: String[] = [];
}

export class NotificationError implements IError {

	title: LanguagesObject = new LanguagesObject();
	body: LanguagesObject = new LanguagesObject();
	url: String = '';
	cities: String = '';
}
