import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { RankService } from './rank.service';
import { DetailsComponent } from './details/details.component';
import { FormComponent } from './form/form.component';
import { BaseIndex } from 'src/app/@core/libraries/pages/base-index';

@Component({
	selector: 'app-rank',
	templateUrl: './rank.component.html',
	styleUrls: ['./rank.component.scss']
})
export class RankComponent extends BaseIndex {

	model = 'rank';
	displayedColumns: string[] = ['select', '_id', 'name', 'created', 'status', 'tools'];
	theDetailsComponent = DetailsComponent;
	theFormComponent = FormComponent;

	constructor(protected theService: RankService,
		protected router: Router, protected dialog: MatDialog) {
		super(theService, router, dialog);
	}

}
