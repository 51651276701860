import { LanguagesObject } from '../../@core/data/languages-object';
import { ITable } from 'src/app/@core/libraries/interfaces/table';
import { IError } from 'src/app/@core/libraries/interfaces/error';

export class AppPopup implements ITable {
	_id: String = '';
	title: LanguagesObject = new LanguagesObject();
	details: LanguagesObject = new LanguagesObject();
	image: LanguagesObject = new LanguagesObject();
	url: String = '';
	accept_button_route: String = '';
	cancel_button_route: String = '';
	show_in_route: String = '';
	sorting: Number = 0;
	status: Boolean = false;

}

export class AppPopupError implements IError {

	title: LanguagesObject = new LanguagesObject();
	details: LanguagesObject = new LanguagesObject();
	image: String = '';
	url: String = '';
	accept_button_route: String = '';
	cancel_button_route: String = '';
	show_in_route: String = '';
	sorting: String = '';
	status: String = '';

}
