import { i18n } from '../libraries/i18n';
export const Pages = [
	{
		id: '',
		title: i18n['home'],
		icon: 'home',
		home: true,
	},
	{
		id: 'product',
		title: i18n['products'],
		icon: 'tv',
		home: false,
	},
	{
		id: 'category',
		title: i18n['categories'],
		icon: 'folder',
		home: false,
	},
	{
		id: 'supplier_category',
		title: i18n['supplier_categories'],
		icon: 'folder',
		home: false,
	},
	{
		id: 'supplier_feature',
		title: i18n['supplier_features'],
		icon: 'folder',
		home: false,
	},
	{
		id: 'rank',
		title: i18n['ranks'],
		icon: 'folder',
		home: false,
	},
	{
		id: 'offer',
		title: i18n['offers'],
		icon: 'gift',
		home: false,
	},
	{
		id: 'reward',
		title: i18n['rewards'],
		icon: 'gift',
		home: false,
	},
	{
		id: 'reward_feature',
		title: i18n['reward_features'],
		icon: 'folder',
		home: false,
	},
	{
		id: 'brand',
		title: i18n['brands'],
		icon: 'copyright',
		home: false,
	},
	{
		id: 'option',
		title: i18n['options'],
		icon: 'edit_attributes',
		home: false,
	},
	{
		id: 'feature',
		title: i18n['features'],
		icon: 'stars',
		home: false,
	},
	{
		id: 'feature_rank',
		title: i18n['feature_ranks'],
		icon: 'folder',
		home: false,
	},
	{
		id: 'supplier',
		title: i18n['suppliers'],
		icon: 'directions_walk',
		home: false,
	},
	{
		id: 'inventory',
		title: i18n['inventories'],
		icon: 'directions_walk',
		home: false,
	},
	{
		id: 'review',
		title: i18n['reviews'],
		icon: 'star',
		home: false,
	},
	{
		id: 'country',
		title: i18n['countries'],
		icon: 'blur_circular',
		home: false,
	},
	{
		id: 'city',
		title: i18n['cities'],
		icon: 'location_city',
		home: false,
	},
	{
		id: 'store',
		title: i18n['stores'],
		icon: 'store',
		home: false,
	},
	{
		id: 'coupon',
		title: i18n['coupons'],
		icon: 'gift',
		home: false,
	},
	{
		id: 'report',
		title: i18n['reports'],
		icon: 'assignment',
		home: false,
	},
	{
		id: 'order',
		title: i18n['orders'],
		icon: 'shopping_bag',
		home: false,
	},
	{
		id: 'order/by_product',
		title: i18n['orders_by_product'],
		icon: 'important_devices',
		home: false,
	},
	{
		id: 'order/by_user',
		title: i18n['orders_by_user'],
		icon: 'face',
		home: false,
	},
	{
		id: 'order_without_quantities',
		title: i18n['orders_without_quantities'],
		icon: 'shopping_bag',
		home: false,
	},

	{
		id: 'issue',
		title: i18n['issues'],
		icon: 'phone-volume',
		home: false,
	},
	{
		id: 'employee',
		title: i18n['employees'],
		icon: 'assignment_ind',
		home: false,
	},
	{
		id: 'page',
		title: i18n['pages'],
		icon: 'pages',
		home: false,
	},
	{
		id: 'slide',
		title: i18n['slides'],
		icon: 'images',
		home: false,
	},
	{
		id: 'notification',
		title: i18n['notifications'],
		icon: 'notifications_active',
		home: false,
	},
	{
		id: 'member_notification',
		title: i18n['member_notification'],
		icon: 'notifications',
		home: false,
	},
	{
		id: 'banner',
		title: i18n['banners'],
		icon: 'local_play',
		home: false,
	},
	{
		id: 'member',
		title: i18n['members'],
		icon: 'group',
		home: false,
	},
	{
		id: 'adminmember',
		title: i18n['adminmembers'],
		icon: 'fingerprint',
		home: false,
	},
	{
		id: 'faq',
		title: i18n['faqs'],
		icon: 'question_answer',
		home: false,
	},
	{
		id: 'ticker',
		title: i18n['ticker'],
		icon: 'ticker',
		home: false,
	},
	{
		id: 'invoice',
		title: 'Invoice',
		icon: 'invoices',
		home: false,
	},
	{
		id: 'setting',
		title: i18n['settings'],
		icon: 'settings',
		home: false,
	},
	{
		id: 'setting/base-setting',
		title: "Base settings",
		icon: 'settings',
		home: false,
	},
	/* {
		id: 'additional_settings',
		title: i18n['additional_settings'],
		icon: 'additional_settings',
		home: false,
	},
	{
		id: 'whatsapp',
		title: i18n['whatsapp'],
		icon: 'whatsapp',
		home: false,
	}, */

];
