import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './pages/login/login.component';

import { NotFoundComponent } from './pages/not-found/not-found.component';

import { AuthorizationGuardService } from './@core/services/authorization.guard.service';

import { AdminmemberComponent } from './pages/adminmember/adminmember.component';
import { BannerComponent } from './pages/banner/banner.component';
import { BillComponent } from './pages/order/bill/bill.component';
import { BrandComponent } from './pages/brand/brand.component';
import { ByProductComponent } from './pages/order/by-product/by-product.component';
import { ByUserComponent } from './pages/order/by-user/by-user.component';
import { ByZeroProductsComponent } from './pages/order/by-zero-products/by-zero-products.component';
import { CategoryComponent } from './pages/category/category.component';
import { SupplierCategoryComponent } from './pages/supplier_category/supplier_category.component';
import { SupplierFeatureComponent } from './pages/supplier_feature/supplier_feature.component';
import { CityComponent } from './pages/city/city.component';
import { CountryComponent } from './pages/country/country.component';
import { CouponComponent } from './pages/coupon/coupon.component';
import { EmployeeComponent } from './pages/employee/employee.component';
import { FaqComponent } from './pages/faq/faq.component';
import { TickerComponent } from './pages/ticker/ticker.component';
import { FeatureComponent } from './pages/feature/feature.component';
import { RewardFeatureComponent } from './pages/reward_feature/reward_feature.component';
import { HomeComponent } from './pages/home/home.component';
import { IssueComponent } from './pages/order/issue/issue.component';
import { IssuesComponent } from './pages/order/issues/issues.component';
import { MemberComponent } from './pages/member/member.component';
import { MemberNotificationComponent } from './pages/member-notification/member-notification.component';
import { NotificationComponent } from './pages/notification/notification.component';
import { OptionComponent } from './pages/option/option.component';
import { OrderComponent } from './pages/order/order.component';
import { PageComponent } from './pages/page/page.component';
import { PasswordComponent } from './pages/password/password.component';
import { ProductComponent } from './pages/product/product.component';
import { RankComponent } from './pages/rank/rank.component';
import { FeatureRankComponent } from './pages/feature_rank/feature_rank.component';
import { OfferComponent } from './pages/offer/offer.component';
import { RewardComponent } from './pages/reward/reward.component';
import { SettingComponent } from './pages/setting/setting.component';
import { BaseSettingComponent } from './pages/setting/base-setting/base-setting.component';
import { InvoiceComponent } from './pages/invoice/invoice.component';
import { AdditionalSettingComponent } from './pages/additional_settings/additional_settings.component';
import { BulkWhatsappComponent } from './pages/whatsapp/whatsapp.component';
import { LogComponent } from './pages/log/log.component';
import { SlideComponent } from './pages/slide/slide.component';
import { AppPopupComponent } from './pages/app_popup/app_popup.component';
import { StoreComponent } from './pages/store/store.component';
import { SupplierComponent } from './pages/supplier/supplier.component';
import { SupportComponent } from './pages/support/support.component';
import { ReportComponent } from './pages/report/report.component';
import { InventoryComponent } from './pages/inventory/inventory.component';
import { ReviewComponent } from './pages/review/review.component';
import { WalletHistoryComponent } from './pages/report/wallet-history/wallet-history.component';
import { PointHistoryComponent } from './pages/report/point-history/point-history.component';
import { MemberHistoryComponent } from './pages/report/member-history/member-history.component';
import { FailedPaymentHistoryComponent } from './pages/report/failed-payment-history/failed-payment-history.component';
import { ProHistoryComponent } from './pages/report/pro-history/pro-history.component';
import { GiveawayEntriesComponent } from './pages/offer/giveaway-entries/giveaway-entries.component';
import { RewardMembersComponent } from './pages/reward/members/reward-members.component';
import { RewardAllComponent } from './pages/reward/all/reward-all.component';

const routes: Routes = [
	{ path: 'adminmember', component: AdminmemberComponent, canActivate: [AuthorizationGuardService] },
	{ path: 'banner', component: BannerComponent, canActivate: [AuthorizationGuardService] },
	{ path: 'brand', component: BrandComponent, canActivate: [AuthorizationGuardService] },
	{ path: 'category', component: CategoryComponent, canActivate: [AuthorizationGuardService] },
	{ path: 'supplier_category', component: SupplierCategoryComponent, canActivate: [AuthorizationGuardService] },
	{ path: 'supplier_feature', component: SupplierFeatureComponent, canActivate: [AuthorizationGuardService] },
	{ path: 'city', component: CityComponent, canActivate: [AuthorizationGuardService] },
	{ path: 'country', component: CountryComponent, canActivate: [AuthorizationGuardService] },
	{ path: 'coupon', component: CouponComponent, canActivate: [AuthorizationGuardService] },
	{ path: 'employee', component: EmployeeComponent, canActivate: [AuthorizationGuardService] },
	{ path: 'faq', component: FaqComponent, canActivate: [AuthorizationGuardService] },
	{ path: 'ticker', component: TickerComponent, canActivate: [AuthorizationGuardService] },
	{ path: 'feature', component: FeatureComponent, canActivate: [AuthorizationGuardService] },
	{ path: 'reward_feature', component: RewardFeatureComponent, canActivate: [AuthorizationGuardService] },
	{ path: 'issue', component: IssuesComponent, canActivate: [AuthorizationGuardService] },
	{ path: 'member', component: MemberComponent, canActivate: [AuthorizationGuardService] },
	{ path: 'member_notification', component: MemberNotificationComponent, canActivate: [AuthorizationGuardService] },
	{ path: 'notification', component: NotificationComponent, canActivate: [AuthorizationGuardService] },
	{ path: 'option', component: OptionComponent, canActivate: [AuthorizationGuardService] },
	{ path: 'order_without_quantities', component: ByZeroProductsComponent, canActivate: [AuthorizationGuardService] },
	{ path: 'order', component: OrderComponent, canActivate: [AuthorizationGuardService] },
	{ path: 'order/by_product', component: ByProductComponent, canActivate: [AuthorizationGuardService] },
	{ path: 'order/by_user', component: ByUserComponent, canActivate: [AuthorizationGuardService] },
	{ path: 'order/:id/issues', component: IssueComponent, canActivate: [AuthorizationGuardService] },
	{ path: 'order/:id', component: BillComponent, canActivate: [AuthorizationGuardService] },
	{ path: 'page', component: PageComponent, canActivate: [AuthorizationGuardService] },
	{ path: 'password', component: PasswordComponent, canActivate: [AuthorizationGuardService] },
	{ path: 'product', component: ProductComponent, canActivate: [AuthorizationGuardService] },
	{ path: 'rank', component: RankComponent, canActivate: [AuthorizationGuardService] },
	{ path: 'feature_rank', component: FeatureRankComponent, canActivate: [AuthorizationGuardService] },
	{ path: 'offer', component: OfferComponent, canActivate: [AuthorizationGuardService] },
	{ path: 'offer/:id/giveaway-entries', component: GiveawayEntriesComponent, canActivate: [AuthorizationGuardService] },
	{ path: 'reward/:id/members', component: RewardMembersComponent, canActivate: [AuthorizationGuardService] },
	{ path: 'reward/all', component: RewardAllComponent, canActivate: [AuthorizationGuardService] },
	{ path: 'reward', component: RewardComponent, canActivate: [AuthorizationGuardService] },
	{ path: 'report', component: ReportComponent, canActivate: [AuthorizationGuardService] },
	{ path: 'report/point-history', component: PointHistoryComponent, canActivate: [AuthorizationGuardService] },
	{ path: 'report/wallet-history', component: WalletHistoryComponent, canActivate: [AuthorizationGuardService] },
	{ path: 'report/member-history', component: MemberHistoryComponent, canActivate: [AuthorizationGuardService] },
	{ path: 'report/pro-history', component: ProHistoryComponent, canActivate: [AuthorizationGuardService] },
	{ path: 'report/failed-payment-history', component: FailedPaymentHistoryComponent, canActivate: [AuthorizationGuardService] },
	{ path: 'setting', component: SettingComponent, canActivate: [AuthorizationGuardService] },
	{ path: 'setting/base-setting', component: BaseSettingComponent, canActivate: [AuthorizationGuardService] },
	{ path: 'additional_setting', component: AdditionalSettingComponent, canActivate: [AuthorizationGuardService] },
	{ path: 'whatsapp', component: BulkWhatsappComponent, canActivate: [AuthorizationGuardService] },
	{ path: 'invoice', component: InvoiceComponent, canActivate: [AuthorizationGuardService] },
	{ path: 'log/:collection/:id', component: LogComponent, canActivate: [AuthorizationGuardService] },
	{ path: 'slide', component: SlideComponent, canActivate: [AuthorizationGuardService] },
	{ path: 'app_popup', component: AppPopupComponent, canActivate: [AuthorizationGuardService] },
	{ path: 'store', component: StoreComponent, canActivate: [AuthorizationGuardService] },
	{ path: 'supplier', component: SupplierComponent, canActivate: [AuthorizationGuardService] },
	{ path: 'inventory', component: InventoryComponent, canActivate: [AuthorizationGuardService] },
	{ path: 'review', component: ReviewComponent, canActivate: [AuthorizationGuardService] },
	{ path: 'support', component: SupportComponent, canActivate: [AuthorizationGuardService] },
	{ path: '', component: HomeComponent, canActivate: [AuthorizationGuardService] },
	{ path: 'login', component: LoginComponent },
	{ path: '404', component: NotFoundComponent },
	{ path: '**', redirectTo: '/404' },
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
