<h1 mat-dialog-title>{{ data.action | i18n }}</h1>
<div mat-dialog-content>
	<mat-card>
		<mat-card-subtitle>{{ 'translated_details' | i18n }}</mat-card-subtitle>
		<mat-card-content>
			<mat-tab-group>
				<mat-tab *ngFor="let l of languages">
					<ng-template mat-tab-label>
						<i class="flag-icon flag-icon-{{l.image}}"></i>
						{{l.name}}
					</ng-template>

					<div class="col-12" dir="{{l.direction}}">

						<mat-form-field appearance="outline" class="form-group col-md-12">
							<mat-label>{{ 'name' | i18n }} ( {{ l.name }} )</mat-label>
							<input matInput [(ngModel)]="item.title[l.code]" [errorStateMatcher]='validate.title[l.code]'
								   placeholder="{{ 'name' | i18n }}" />
							<mat-error>{{ errors.title[l.code] }}</mat-error>
						</mat-form-field>

						<mat-form-field appearance="outline" class="form-group col-md-12">
							<mat-label>{{ 'description' | i18n }} ( {{ l.name }} )</mat-label>
							<input matInput [(ngModel)]="item.details && item.details[l.code]"
								   [errorStateMatcher]='validate.details[l.code]'
								   placeholder="{{ 'description' | i18n }}" />
							<mat-error>{{ errors.details[l.code] }}</mat-error>
						</mat-form-field>

						<mat-form-field appearance="outline" class="form-group col-md-12 mat-upload">
							<mat-label>{{'image' | i18n}}</mat-label>
							<input type="file" (change)="upload($event.target.files,'image.' + l.code)" />
							{{'upload_percent' | i18n}}: {{percentDone}}%
							<img *ngIf="item.image[l.code]" src="{{environment.mediaUrl}}{{item.image[l.code]}}" width="48" />
							<input matInput hidden [(ngModel)]="item.image[l.code]" [errorStateMatcher]='validate.image[l.code]'
								   name="image">
							<br />
							<mat-error>{{ errors.image[l.code] }}</mat-error>
						</mat-form-field>
					</div>

				</mat-tab>
			</mat-tab-group>
		</mat-card-content>
	</mat-card>
	<mat-divider></mat-divider>

	<mat-card>
		<mat-card-subtitle>{{ 'other_details' | i18n }}</mat-card-subtitle>
		<mat-card-content>
			<div class="row">
				<mat-form-field appearance="outline" class="form-group col-md-12">
					<mat-label>{{ 'url' | i18n }} </mat-label>
					<input matInput [(ngModel)]="item.url" [errorStateMatcher]='validate.url'
						   placeholder="{{ 'url' | i18n }}" />
					<mat-error>{{ errors.url }}</mat-error>
				</mat-form-field>
				<mat-form-field appearance="outline" class="form-group col-md-12">
					<mat-label>{{ 'accept_button_route' | i18n }} </mat-label>
					<input matInput [(ngModel)]="item.accept_button_route" [errorStateMatcher]='validate.accept_button_route'
						   placeholder="{{ 'accept_button_route' | i18n }}" />
					<mat-error>{{ errors.accept_button_route }}</mat-error>
				</mat-form-field>

				<mat-form-field appearance="outline" class="form-group col-md-12">
					<mat-label>{{ 'cancel_button_route' | i18n }} </mat-label>
					<input matInput [(ngModel)]="item.cancel_button_route" [errorStateMatcher]='validate.cancel_button_route'
						   placeholder="{{ 'cancel_button_route' | i18n }}" />
					<mat-error>{{ errors.cancel_button_route }}</mat-error>
				</mat-form-field>

				<mat-form-field appearance="outline" class="form-group col-md-12">
					<mat-label>{{ 'show_in_route' | i18n }} </mat-label>
					<input matInput [(ngModel)]="item.show_in_route" [errorStateMatcher]='validate.show_in_route'
						   placeholder="{{ 'show_in_route' | i18n }}" />
					<mat-error>{{ errors.show_in_route }}</mat-error>
				</mat-form-field>

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'status' | i18n }}</mat-label>
					<mat-select placeholder="{{ 'status' | i18n }}" [(ngModel)]='item.status'
								[errorStateMatcher]='validate.status' name="status">
						<mat-option [value]="true">{{ 'yes' | i18n }}</mat-option>
						<mat-option [value]="false">{{ 'no' | i18n }}</mat-option>
					</mat-select>
					<mat-error>{{ errors.status }}</mat-error>
				</mat-form-field>

			</div>
		</mat-card-content>
	</mat-card>

</div>
<div mat-dialog-actions>
	<button mat-flat-button color="primary" (click)="save()">{{ 'save' | i18n }}</button>
	<button mat-flat-button color="warn" (click)="close()">{{ 'close' | i18n }}</button>
</div>
