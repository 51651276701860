import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { NotificationService } from './notification.service';
import { DetailsComponent } from './details/details.component';
import { FormComponent } from './form/form.component';
import { BaseIndex } from 'src/app/@core/libraries/pages/base-index';

@Component({
	selector: 'app-notification',
	templateUrl: './notification.component.html',
	styleUrls: ['./notification.component.scss']
})
export class NotificationComponent extends BaseIndex {

	model = 'notification';
	displayedColumns: string[] = ['select', '_id', 'title', 'body', 'created', 'tools'];
	theDetailsComponent = DetailsComponent;
	theFormComponent = FormComponent;

	constructor(protected theService: NotificationService,
		protected router: Router, protected dialog: MatDialog) {
		super(theService, router, dialog);
	}

}
