import { Component } from '@angular/core';
import { Inventory, InventoryError } from '../inventory.model';
import { BaseForm } from 'src/app/@core/libraries/pages/base-form';
import { City } from '../../city/city.model';
import { environment } from 'src/environments/environment';
import { HttpParams } from '@angular/common/http';
import { Country } from '../../country/country.model';

@Component({
	selector: 'app-form',
	templateUrl: './form.component.html',
	styleUrls: ['./form.component.scss']
})
export class FormComponent extends BaseForm {

	model = 'inventory';
	item: Inventory = new Inventory();
	errors: InventoryError = new InventoryError();
	validate: InventoryError = new InventoryError();


	initialize() {

	}

	getEmptyErrors() {
		return new InventoryError();
	}

}
