import { Component } from '@angular/core';
import { Coupon, CouponError, DiscountPercentPerTotal } from '../coupon.model';
import { BaseForm } from 'src/app/@core/libraries/pages/base-form';
import { environment } from 'src/environments/environment';
import { HttpParams } from '@angular/common/http';
import { Member } from '../../member/member.model';
import { Category } from '../../category/category.model';

@Component({
	selector: 'app-form',
	templateUrl: './form.component.html',
	styleUrls: ['./form.component.scss']
})
export class FormComponent extends BaseForm {

	model = 'coupon';
	item: Coupon = new Coupon();
	errors: CouponError = new CouponError();
	validate: CouponError = new CouponError();
	members: Member[] = [];
	percent_values: Object[] = [];
	minDate: Date = new Date();
	query: String = '';
	suppliers = [];
  	product: string = '';
  	separatorKeysCodes = [13, 188];
	discount_per_total = new DiscountPercentPerTotal();
	categoriesObj: any[] = [];
	categories: Category[] = [];
	allCategories: Category[] = [];

	initialize() {
		this.loadSuppliers();
		this.loadCategories();
		for (let i = 1; i < 6; i++) {
			const v = i * 5;
			this.percent_values.push({
				text: `${v} %`,
				value: v,
			});
		}
	}

	afterInit() {
		if (this.item.member_id) {
			this.http.get(`${environment.apiUrl}/member/${this.item.member_id}`)
			.subscribe((response) => {
				const member = response['results'];
				(document.getElementById('query') as HTMLInputElement).value = `${member.username} (${member.email})`;
			});
		}

		this.supplierChangeHander();
	}

	loadMembers() {
		this.http.get(`${environment.apiUrl}/member`, {
			params: new HttpParams()
				.set('filter', `status=true`)
				.set('q', `${this.query}`)
				.set('sort', `username=asc`)
				.set('limit', `3`)
				.set('all', 'true')
		})
			.subscribe((response) => {
				this.members = response['results']['data'];
			});
	}

	loadSuppliers() {
		this.http.get(`${environment.apiUrl}/supplier`, {
			params: new HttpParams()
				.set('filter', `status=true&is_external=true`)
				.set('sort', `name.${this.config.lang}=asc`)
				.set('limit', '999')
		})
			.subscribe((response) => {
				this.suppliers = response['results']['data'];
				/* for (const i of this.suppliers) {
					this.suppliersObj[i._id.toString()] = i.name[this.config.lang];
				} */
			});
	}

	loadCategories() {
		this.http.get(`${environment.apiUrl}/category`, {
			params: new HttpParams()
				.set('filter', 'status=true')
				.set('sort', `name.${this.config.lang}=asc`)
				.set('limit', '5000')
		})
			.subscribe((response) => {
				this.allCategories = response['results']['data'];
				if (this.item.suppliers && this.item.suppliers.length > 0) {
					this.categoriesObj = [];
					this.categories = this.allCategories.filter(c => this.item.suppliers.includes(c.supplier_id));
					console.log('filtered with suppkiers: ', this.categories);
					for (const i of this.categories) {
						this.categoriesObj[i._id.toString()] = i.name[this.config.lang];
					}
				} else {
					this.categoriesObj = [];
					this.categories = this.allCategories.filter(c => !c.supplier_id);
					console.log('NOT filtered with suppkiers: ', this.categories);
					for (const i of this.categories) {
						this.categoriesObj[i._id.toString()] = i.name[this.config.lang];
					}
				}
			});
	}

	supplierChangeHander() {
		if (this.allCategories.length > 0) {
			if (this.item.suppliers && this.item.suppliers.length > 0) {
				this.categoriesObj = [];
				this.categories = this.allCategories.filter(c => this.item.suppliers.includes(c.supplier_id));
				for (const i of this.categories) {
					this.categoriesObj[i._id.toString()] = i.name[this.config.lang];
				}
			} else {
				this.categoriesObj = [];
				this.categories = this.allCategories.filter(c => !c.supplier_id);
				for (const i of this.categories) {
					this.categoriesObj[i._id.toString()] = i.name[this.config.lang];
				}
			}
		}
	}


	set_member(i) {
		this.query = `${i.username} (${i.email})`;
		this.item.member_id = i._id;
	}

	getEmptyErrors() {
		return new CouponError();
	}

	addValue() {
		if (this.product && this.item.products.indexOf(this.product) === -1) {
		  this.item.products.push(this.product);
		  this.product = '';
		}
	  }
	
	  removeValue(value: string) {
		const index = this.item.products.indexOf(value);
		if (index !== -1) {
		  this.item.products.splice(index, 1);
		}
	  }
	
	  removeLastChip() {
		if (!this.product && this.item.products.length > 0) {
		  this.item.products.pop();
		}
	  }

	  add_discount_percent_per_total() {
		if (this.item.discount_percent_per_total === undefined) {
			this.item.discount_percent_per_total = [];
		}
		if (!this.item.discount_percent_per_total.find(c => c.min_total == this.discount_per_total.min_total)) {
			this.item.discount_percent_per_total.push(this.discount_per_total);
		}
		this.discount_per_total = new DiscountPercentPerTotal();
	}

		remove_discount_percent_per_total(min_total) {
			this.item.discount_percent_per_total = this.item.discount_percent_per_total.filter(c => c.min_total !== min_total);
		}

}
