import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material";
import { CategoryService } from "./category.service";
import { DetailsComponent } from "./details/details.component";
import { FormComponent } from "./form/form.component";
import { BaseIndex } from "src/app/@core/libraries/pages/base-index";
import { AuthService } from "src/app/@core/services/auth.service";
import { environment } from "src/environments/environment";
import { CategoryFilter } from "./category.model";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Supplier } from "../supplier/supplier.model";

@Component({
	selector: "app-category",
	templateUrl: "./category.component.html",
	styleUrls: ["./category.component.scss"],
})
export class CategoryComponent extends BaseIndex {
	model = "category";
	displayedColumns: string[] = [
		"select",
		"_id",
		"name",
		"created",
		"status",
		"tools",
	];
	theDetailsComponent = DetailsComponent;
	theFormComponent = FormComponent;
	csv_url: string;
	searchForm: Boolean = false;
	filter: CategoryFilter = new CategoryFilter();
	suppliers: Supplier[] = [];

	constructor(
		protected theService: CategoryService,
		public http: HttpClient,
		protected router: Router,
		protected dialog: MatDialog,
		public auth: AuthService
	) {
		super(theService, router, dialog);
		this.loadSuppliers();
		const token = `${this.auth.getToken()}`;
		this.csv_url = `${environment.apiUrl}/category/export_csv?token=${token}`;
	}

	setSearchForm() {
		this.searchForm = !this.searchForm;
	}

	loadSuppliers() {
		this.http.get(`${environment.apiUrl}/supplier`, {
			params: new HttpParams()
				.set('filter', 'status=true')
				.set('sort', `name.${this.config.lang}=asc`)
				.set('limit', '999')
		})
			.subscribe((response) => {
				this.suppliers = [{ _id: "Jm3eia", name: { en: "Jm3eia", ar: "Jm3eia ar" } }, ...response['results']['data']];
			});
	}
}
