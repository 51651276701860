import { Component, OnInit } from '@angular/core';
import { Pages } from './@core/data/pages';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
	title = 'Jm3eia Dashboard';
	hasMainStyle = false;
	pages = [];
	user?: any = null;

	constructor(private router: Router) {
		let user = localStorage.getItem('auth.user');
		this.user = user = user ? JSON.parse(user) : {};
		this.get_pages();
	}

	ngOnInit(): void {
		this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        const currentPage = this.router.url.substring(1);
    	const isPageAllowed = this.pages.map(p => p.id).includes(currentPage);

		if (Pages.findIndex(p => p.id === currentPage) > -1 && !isPageAllowed)
			this.router.navigate(['not-found']);
      });

	}

	change_language(code) {
		localStorage.setItem('lang', code);
		location.reload();
	}

	get_pages() {
		const user = this.user;
		this.pages = Object.keys(user).length ? Pages.filter((p) => {
			return p.id === '' || !user['permissions'] || user['permissions'].indexOf(p.id) > -1;
		}) : [];
		if (this.pages && user) {
			this.hasMainStyle = true;
		}

		return this.pages;
	}

	logout() {
		localStorage.removeItem('auth.user');
		localStorage.removeItem('app.settings');
		localStorage.removeItem('token');
		this.router.navigate(['login']);
	}

}
