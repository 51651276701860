<mat-list>
	<!-- <div mat-subheader>
		{{ 'members' | i18n }}
	</div> -->
	<mat-list-item>
		<mat-icon mat-list-icon>folder</mat-icon>
		<div mat-line>
			<a [routerLink]="'/report/point-history'">
				{{ 'point_history' | i18n }}
			</a>
		</div>
		<div mat-line> {{ '' }} </div>
	</mat-list-item>
	<mat-divider></mat-divider>
	<mat-list-item>
		<mat-icon mat-list-icon>folder</mat-icon>
		<div mat-line>
			<a [routerLink]="'/report/wallet-history'">
				{{ 'wallet_history' | i18n }}
			</a>
		</div>
		<div mat-line> {{ '' }} </div>
	</mat-list-item>
	<mat-divider></mat-divider>
	<mat-list-item>
		<mat-icon mat-list-icon>group</mat-icon>
		<div mat-line>
			<a [routerLink]="'/report/member-history'">
				{{ 'member_history' | i18n }}
			</a>
		</div>
		<div mat-line> {{ '' }} </div>
	</mat-list-item>
	<mat-divider></mat-divider>
	<mat-list-item>
		<mat-icon mat-list-icon>group</mat-icon>
		<div mat-line>
			<a [routerLink]="'/report/pro-history'">
				{{ 'pro_history' | i18n }}
			</a>
		</div>
		<div mat-line> {{ '' }} </div>
	</mat-list-item>
	<mat-divider></mat-divider>
	<mat-list-item>
		<mat-icon mat-list-icon>group</mat-icon>
		<div mat-line>
			<a [routerLink]="'/report/failed-payment-history'">
				{{ 'failed_payment_history' | i18n }}
			</a>
		</div>
		<div mat-line> {{ '' }} </div>
	</mat-list-item>
	<mat-divider></mat-divider>
</mat-list>
