import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { FeatureService } from './feature.service';
import { DetailsComponent } from './details/details.component';
import { FormComponent } from './form/form.component';
import { BaseIndex } from 'src/app/@core/libraries/pages/base-index';
import { HttpClient, HttpParams } from '@angular/common/http';
import { FeatureFilter } from './feature.model';
import { Supplier } from '../supplier/supplier.model';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-feature',
	templateUrl: './feature.component.html',
	styleUrls: ['./feature.component.scss']
})
export class FeatureComponent extends BaseIndex {

	model = 'feature';
	displayedColumns: string[] = ['select', '_id', 'name', 'created', 'status', 'tools'];
	theDetailsComponent = DetailsComponent;
	theFormComponent = FormComponent;
	searchForm: Boolean = false;
	filter: FeatureFilter = new FeatureFilter();
	suppliers: Supplier[] = [];

	constructor(
		protected theService: FeatureService,
		public http: HttpClient,
		protected router: Router, protected dialog: MatDialog
	) {
		super(theService, router, dialog);
		this.loadSuppliers();
	}

	setSearchForm() {
		this.searchForm = !this.searchForm;
	}

	loadSuppliers() {
		this.http.get(`${environment.apiUrl}/supplier`, {
			params: new HttpParams()
				.set('filter', 'status=true')
				.set('sort', `name.${this.config.lang}=asc`)
				.set('limit', '999')
		})
			.subscribe((response) => {
				this.suppliers = [{ _id: "Jm3eia", name: { en: "Jm3eia", ar: "Jm3eia ar" } }, ...response['results']['data']];
			});
	}

}
