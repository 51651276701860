<mat-card>
	<mat-card-title>Whatsapp</mat-card-title>
	<mat-card-content>
		<div class="row">

			<mat-form-field appearance="outline" class="form-group col-md-12">
				<mat-label>{{ 'template_key' | i18n }}</mat-label>
				<input matInput [(ngModel)]="item.template_key" [errorStateMatcher]='validate.template_key'
					placeholder="{{ 'template_key' | i18n }}" />
				<mat-error>{{ errors.template_key }}</mat-error>
			</mat-form-field>

			<!-- <mat-form-field class="form-group col-md-12">
				<div class="mb-2">{{ 'message' | i18n }}</div>
				<input matInput hidden [(ngModel)]="item.message" [errorStateMatcher]='validate.message' name="message"
					class="border-none " />
				<ckeditor [(ngModel)]="item.message" [editor]="Editor" [config]="editorConfig"></ckeditor>
				<mat-error>{{ errors.message }}</mat-error>
			</mat-form-field> -->
		</div>
	</mat-card-content>
	<mat-card-footer>
		<div mat-dialog-actions class="col-6 col-offset-3">
			<button mat-flat-button color="primary" class="btn-block" (click)="save()">{{ 'send' | i18n }}</button>
		</div>
		<br class="my-5" />
	</mat-card-footer>
</mat-card>