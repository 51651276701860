import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ThemeModule } from '../../@core/modules/theme.module';
import { AdditionalSettingComponent } from './additional_settings.component';

const COMPONENTS = [
	AdditionalSettingComponent,
];

@NgModule({
	imports: [
		CommonModule,
		ThemeModule,
	],
	declarations: [...COMPONENTS],
	entryComponents: COMPONENTS
})
export class AdditionalSettingModule { }
