<mat-card class="mat-elevation-z8">

	<mat-toolbar>
		<mat-toolbar-row>
			<button mat-raised-button color="primary" (click)="openForm('add')">
				<mat-icon>add_box</mat-icon>
				{{ 'add' | i18n }}
			</button>
			<button mat-raised-button color="link" (click)="openForm('edit', selectedID)">
				<mat-icon>edit_box</mat-icon>
				{{ 'edit' | i18n }}
			</button>
			<button mat-raised-button color="warn" (click)="openDelete()">
				<mat-icon>delete_box</mat-icon>
				{{ 'delete' | i18n }}
			</button>
			<button mat-raised-button color="link" (click)="selectAll()">
				<mat-icon>check_box</mat-icon>
				{{ 'select_all' | i18n }}
			</button>
			<button mat-raised-button color="link" (click)="deselectAll()">
				<mat-icon>check_box_outline_blank</mat-icon>
				{{ 'deselect_all' | i18n }}
			</button>
			<a href="{{ csv_url }}" mat-raised-button color="link">
				<mat-icon>cloud_download</mat-icon>
				{{ 'export' | i18n }}
			</a>
			<span class="spacer"></span>
			<h2 class="title"> {{ 'categories' | i18n }} </h2>
		</mat-toolbar-row>
	</mat-toolbar>

	<mat-card *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center">
		<mat-progress-spinner color="primary" mode="indeterminate">
		</mat-progress-spinner>
	</mat-card>

	<table mat-table [dataSource]="items">

		<!-- Checkbox Column -->
		<ng-container matColumnDef="select">
			<th mat-header-cell *matHeaderCellDef>
				<mat-checkbox (change)="$event ? masterToggle() : null;"
					[checked]="selection.hasValue() && isAllSelected()"
					[indeterminate]="selection.hasValue() && !isAllSelected()">
				</mat-checkbox>
			</th>
			<td mat-cell *matCellDef="let row">
				<mat-checkbox (click)="$event.stopPropagation()"
					(change)="$event ? selection.toggle(row) : null;updateSelectionIds()"
					[checked]="selection.isSelected(row)">
				</mat-checkbox>
			</td>
		</ng-container>

		<!-- _id Column -->
		<ng-container matColumnDef="_id">
			<th mat-header-cell *matHeaderCellDef> # </th>
			<td mat-cell *matCellDef="let i"> <b [outerHTML]="i._id | _id"></b> </td>
		</ng-container>

		<!-- Name Column -->
		<ng-container matColumnDef="name">
			<th mat-header-cell *matHeaderCellDef> {{ 'name' | i18n }} </th>
			<td mat-cell *matCellDef="let i"> {{i.name[config.lang]}} </td>
		</ng-container>

		<!-- Created Column -->
		<ng-container matColumnDef="created">
			<th mat-header-cell *matHeaderCellDef> {{ 'created' | i18n }} </th>
			<td mat-cell *matCellDef="let i"> {{i.created}} </td>
		</ng-container>

		<!-- Status Column -->
		<ng-container matColumnDef="status">
			<th mat-header-cell *matHeaderCellDef> {{ 'status' | i18n }} </th>
			<td mat-cell *matCellDef="let i"> {{i.status}} </td>
		</ng-container>

		<!-- Tools Column -->
		<ng-container matColumnDef="tools">
			<th mat-header-cell *matHeaderCellDef></th>
			<td mat-cell *matCellDef="let i">
				<div class="btn-tools">
					<button mat-mini-fab color="primary" (click)="openDetails(i._id)" title="{{ 'details' | i18n }}">
						<mat-icon>info</mat-icon>
					</button>
					<button mat-mini-fab color="accent" (click)="openForm('add', i._id)" title="{{ 'addCopy' | i18n }}">
						<mat-icon>file_copy</mat-icon>
					</button>
					<button mat-mini-fab color="basic" (click)="openForm('edit', i._id)" title="{{ 'edit' | i18n }}">
						<mat-icon>edit</mat-icon>
					</button>
					<button mat-mini-fab color="warn" (click)="openDelete(i._id)" title="{{ 'delete' | i18n }}">
						<mat-icon>delete</mat-icon>
					</button>
					<!-- <a mat-mini-fab [href]="'/log/category/' + i._id">
						<mat-icon>receipt</mat-icon>
					</a> -->
				</div>
			</td>
		</ng-container>

		<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
		<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
	</table>

	<mat-paginator showFirstLastButtons [length]="pagination.length" [pageSize]="pagination.pageSize"
		[pageSizeOptions]="pagination.pageSizeOptions" (page)="next($event)">
	</mat-paginator>
</mat-card>
