<h1 mat-dialog-title>{{ 'details' | i18n }}</h1>
<div mat-dialog-content *ngIf="item">
	<mat-card>
		<mat-card-subtitle>{{ 'translated_details' | i18n }}</mat-card-subtitle>
		<mat-card-content>
			<mat-tab-group>
				<mat-tab *ngFor="let l of languages">
					<ng-template mat-tab-label>
						<i class="flag-icon flag-icon-{{l.image}}"></i>
						{{l.name}}
					</ng-template>
					<table class="table table-bordered">
						<tbody>
							<tr>
								<td>{{ 'name' | i18n }} ( {{ l.name }} )</td>
								<td>{{ item.name[l.code] }}</td>
							</tr>
							<tr>
								<td>{{ 'currency' | i18n }} ( {{ l.name }} )</td>
								<td>{{ item.currency[l.code] }}</td>
							</tr>
						</tbody>
					</table>
				</mat-tab>
			</mat-tab-group>
		</mat-card-content>
	</mat-card>
	<mat-divider></mat-divider>
	<mat-card>
		<mat-card-subtitle>{{ 'other_details' | i18n }}</mat-card-subtitle>
		<mat-card-content>
			<table class="table table-bordered">
				<tbody>
					<tr>
						<td>#</td>
						<td>{{ item._id }}</td>
					</tr>
					<tr>
						<td>{{ 'code' | i18n }}</td>
						<td><i class="flag-icon flag-icon-{{ item.code }}"></i> ( {{ item.code }} )</td>
					</tr>
					<tr>
						<td>{{ 'created' | i18n }}</td>
						<td>{{ item.created }}</td>
					</tr>
					<tr>
						<td>{{ 'modified' | i18n }}</td>
						<td>{{ item.modified }}</td>
					</tr>
					<tr>
						<td>{{ 'lastupdate_user_name' | i18n }}</td>
						<td>{{ item.lastupdate_user_name }}</td>
					</tr>
					<tr>
						<td>{{ 'status' | i18n }}</td>
						<td>{{ item.status }}</td>
					</tr>
				</tbody>
			</table>
		</mat-card-content>
	</mat-card>
</div>
<div mat-dialog-actions>
	<button mat-button color="warn" (click)="close()">{{ 'close' | i18n }}</button>
</div>
