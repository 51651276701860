import { IError } from "src/app/@core/libraries/interfaces/error";
import { LanguagesObject } from "src/app/@core/data/languages-object";

export class BulkMail {
	/* _id: String = "";
	subject: String = "";
	message: String = ""; */
	template_key: String = "";
}

export class BulkMailError implements IError {
	template_key: String = "";
	/* subject: String = "";
	message: String = ""; */
}

