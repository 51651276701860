<mat-card *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center">
	<mat-progress-spinner color="primary" mode="indeterminate">
	</mat-progress-spinner>
</mat-card>
<mat-card *ngIf="!isLoading">
	<mat-card-title>{{ 'settings' | i18n }}</mat-card-title>
	<mat-card-content>

		<mat-card>
			<mat-card-subtitle>{{ 'settings_base' | i18n }}</mat-card-subtitle>
			<mat-card-content>

				<div class="row">

					<mat-form-field appearance="outline" class="form-group col-md-6">
						<mat-label>{{ 'email' | i18n }}</mat-label>
						<input matInput [(ngModel)]="item.email" [errorStateMatcher]='validate.email'
							placeholder="{{ 'email' | i18n }}" />
						<mat-error>{{ errors.email }}</mat-error>
					</mat-form-field>

					<mat-form-field appearance="outline" class="form-group col-md-6">
						<mat-label>{{ 'phones' | i18n }}</mat-label>
						<input matInput [(ngModel)]="item.phones" [errorStateMatcher]='validate.phones'
							placeholder="{{ 'phones' | i18n }}" />
						<mat-error>{{ errors.phones }}</mat-error>
					</mat-form-field>

				</div>

			</mat-card-content>
		</mat-card>
		<mat-divider></mat-divider>

		<mat-card>
			<mat-card-subtitle>{{ 'settings_social' | i18n }}</mat-card-subtitle>
			<mat-card-content>

				<div class="row">

					<mat-form-field appearance="outline" class="form-group col-md-6">
						<mat-label>{{ 'social.facebook.url' | i18n }}</mat-label>
						<input matInput [(ngModel)]="item.social.facebook.url" [errorStateMatcher]='validate.social.facebook.url'
							placeholder="{{ 'social.facebook.url' | i18n }}" />
						<mat-error>{{ errors.social.facebook.url }}</mat-error>
					</mat-form-field>

					<mat-form-field appearance="outline" class="form-group col-md-6">
						<mat-label>{{ 'social.facebook.image' | i18n }}</mat-label>
						<input matInput [(ngModel)]="item.social.facebook.image" [errorStateMatcher]='validate.social.facebook.image'
							placeholder="{{ 'social.facebook.image' | i18n }}" />
						<mat-error>{{ errors.social.facebook.image }}</mat-error>
					</mat-form-field>

					<mat-form-field appearance="outline" class="form-group col-md-6">
						<mat-label>{{ 'social.twitter.url' | i18n }}</mat-label>
						<input matInput [(ngModel)]="item.social.twitter.url" [errorStateMatcher]='validate.social.twitter.url'
							placeholder="{{ 'social.twitter.url' | i18n }}" />
						<mat-error>{{ errors.social.twitter.url }}</mat-error>
					</mat-form-field>

					<mat-form-field appearance="outline" class="form-group col-md-6">
						<mat-label>{{ 'social.twitter.image' | i18n }}</mat-label>
						<input matInput [(ngModel)]="item.social.twitter.image" [errorStateMatcher]='validate.social.twitter.image'
							placeholder="{{ 'social.twitter.image' | i18n }}" />
						<mat-error>{{ errors.social.twitter.image }}</mat-error>
					</mat-form-field>

					<mat-form-field appearance="outline" class="form-group col-md-6">
						<mat-label>{{ 'social.instagram.url' | i18n }}</mat-label>
						<input matInput [(ngModel)]="item.social.instagram.url" [errorStateMatcher]='validate.social.instagram.url'
							placeholder="{{ 'social.instagram.url' | i18n }}" />
						<mat-error>{{ errors.social.instagram.url }}</mat-error>
					</mat-form-field>

					<mat-form-field appearance="outline" class="form-group col-md-6">
						<mat-label>{{ 'social.instagram.image' | i18n }}</mat-label>
						<input matInput [(ngModel)]="item.social.instagram.image" [errorStateMatcher]='validate.social.instagram.image'
							placeholder="{{ 'social.instagram.image' | i18n }}" />
						<mat-error>{{ errors.social.instagram.image }}</mat-error>
					</mat-form-field>

					<mat-form-field appearance="outline" class="form-group col-md-6">
						<mat-label>{{ 'social.whatsapp.url' | i18n }}</mat-label>
						<input matInput [(ngModel)]="item.social.whatsapp.url" [errorStateMatcher]='validate.social.whatsapp.url'
							placeholder="{{ 'social.whatsapp.url' | i18n }}" />
						<mat-error>{{ errors.social.whatsapp.url }}</mat-error>
					</mat-form-field>

					<mat-form-field appearance="outline" class="form-group col-md-6">
						<mat-label>{{ 'social.whatsapp.image' | i18n }}</mat-label>
						<input matInput [(ngModel)]="item.social.whatsapp.image" [errorStateMatcher]='validate.social.whatsapp.image'
							placeholder="{{ 'social.whatsapp.image' | i18n }}" />
						<mat-error>{{ errors.social.whatsapp.image }}</mat-error>
					</mat-form-field>

				</div>

			</mat-card-content>
		</mat-card>
		<mat-divider></mat-divider>

		<mat-card>
			<mat-card-subtitle>{{ 'sender_emails' | i18n }}</mat-card-subtitle>
			<mat-card-content>

				<div class="row">

					<mat-form-field appearance="outline" class="form-group col-md-6">
						<mat-label>{{ 'sender_emails.register.host' | i18n }}</mat-label>
						<input matInput [(ngModel)]="item.sender_emails.register.host" [errorStateMatcher]='validate.sender_emails.register.host'
							placeholder="{{ 'sender_emails.register.host' | i18n }}" />
						<mat-error>{{ errors.sender_emails.register.host }}</mat-error>
					</mat-form-field>

					<mat-form-field appearance="outline" class="form-group col-md-6">
						<mat-label>{{ 'sender_emails.register.secure' | i18n }}</mat-label>
						<mat-select placeholder="{{ 'sender_emails.register.secure' | i18n }}"
							[(ngModel)]='item.sender_emails.register.secure' [errorStateMatcher]='validate.sender_emails.register.secure'
							name="sender_emails.register.secure">
							<mat-option [value]="true">{{ 'yes' | i18n }}</mat-option>
							<mat-option [value]="false">{{ 'no' | i18n }}</mat-option>
						</mat-select>
						<mat-error>{{ errors.sender_emails.register.secure }}</mat-error>
					</mat-form-field>

					<mat-form-field appearance="outline" class="form-group col-md-6">
						<mat-label>{{ 'sender_emails.register.port' | i18n }}</mat-label>
						<input type="number" matInput [(ngModel)]="item.sender_emails.register.port"
							[errorStateMatcher]='validate.sender_emails.register.port'
							placeholder="{{ 'sender_emails.register.port' | i18n }}" />
						<mat-error>{{ errors.sender_emails.register.port }}</mat-error>
					</mat-form-field>

					<mat-form-field appearance="outline" class="form-group col-md-6">
						<mat-label>{{ 'sender_emails.register.username' | i18n }}</mat-label>
						<input type="email" matInput [(ngModel)]="item.sender_emails.register.username"
							[errorStateMatcher]='validate.sender_emails.register.username'
							placeholder="{{ 'sender_emails.register.username' | i18n }}" />
						<mat-error>{{ errors.sender_emails.register.username }}</mat-error>
					</mat-form-field>

					<mat-form-field appearance="outline" class="form-group col-md-6">
						<mat-label>{{ 'sender_emails.register.password' | i18n }}</mat-label>
						<input type="password" matInput [(ngModel)]="item.sender_emails.register.password"
							[errorStateMatcher]='validate.sender_emails.register.password'
							placeholder="{{ 'sender_emails.register.password' | i18n }}" />
						<mat-error>{{ errors.sender_emails.register.password }}</mat-error>
					</mat-form-field>

				</div>

				<div class="row">

					<mat-form-field appearance="outline" class="form-group col-md-6">
						<mat-label>{{ 'sender_emails.reset_password.host' | i18n }}</mat-label>
						<input matInput [(ngModel)]="item.sender_emails.reset_password.host"
							[errorStateMatcher]='validate.sender_emails.reset_password.host'
							placeholder="{{ 'sender_emails.reset_password.host' | i18n }}" />
						<mat-error>{{ errors.sender_emails.reset_password.host }}</mat-error>
					</mat-form-field>

					<mat-form-field appearance="outline" class="form-group col-md-6">
						<mat-label>{{ 'sender_emails.reset_password.secure' | i18n }}</mat-label>
						<mat-select placeholder="{{ 'sender_emails.reset_password.secure' | i18n }}"
							[(ngModel)]='item.sender_emails.reset_password.secure' [errorStateMatcher]='validate.sender_emails.reset_password.secure'
							name="sender_emails.reset_password.secure">
							<mat-option [value]="true">{{ 'yes' | i18n }}</mat-option>
							<mat-option [value]="false">{{ 'no' | i18n }}</mat-option>
						</mat-select>
						<mat-error>{{ errors.sender_emails.reset_password.secure }}</mat-error>
					</mat-form-field>

					<mat-form-field appearance="outline" class="form-group col-md-6">
						<mat-label>{{ 'sender_emails.reset_password.port' | i18n }}</mat-label>
						<input type="number" matInput [(ngModel)]="item.sender_emails.reset_password.port"
							[errorStateMatcher]='validate.sender_emails.reset_password.port'
							placeholder="{{ 'sender_emails.reset_password.port' | i18n }}" />
						<mat-error>{{ errors.sender_emails.reset_password.port }}</mat-error>
					</mat-form-field>

					<mat-form-field appearance="outline" class="form-group col-md-6">
						<mat-label>{{ 'sender_emails.reset_password.username' | i18n }}</mat-label>
						<input type="email" matInput [(ngModel)]="item.sender_emails.reset_password.username"
							[errorStateMatcher]='validate.sender_emails.reset_password.username'
							placeholder="{{ 'sender_emails.reset_password.username' | i18n }}" />
						<mat-error>{{ errors.sender_emails.reset_password.username }}</mat-error>
					</mat-form-field>

					<mat-form-field appearance="outline" class="form-group col-md-6">
						<mat-label>{{ 'sender_emails.reset_password.password' | i18n }}</mat-label>
						<input type="password" matInput [(ngModel)]="item.sender_emails.reset_password.password"
							[errorStateMatcher]='validate.sender_emails.reset_password.password'
							placeholder="{{ 'sender_emails.reset_password.password' | i18n }}" />
						<mat-error>{{ errors.sender_emails.reset_password.password }}</mat-error>
					</mat-form-field>

				</div>

				<div class="row">

					<mat-form-field appearance="outline" class="form-group col-md-6">
						<mat-label>{{ 'sender_emails.orders.host' | i18n }}</mat-label>
						<input matInput [(ngModel)]="item.sender_emails.orders.host" [errorStateMatcher]='validate.sender_emails.orders.host'
							placeholder="{{ 'sender_emails.orders.host' | i18n }}" />
						<mat-error>{{ errors.sender_emails.orders.host }}</mat-error>
					</mat-form-field>

					<mat-form-field appearance="outline" class="form-group col-md-6">
						<mat-label>{{ 'sender_emails.orders.secure' | i18n }}</mat-label>
						<mat-select placeholder="{{ 'sender_emails.orders.secure' | i18n }}"
							[(ngModel)]='item.sender_emails.orders.secure' [errorStateMatcher]='validate.sender_emails.orders.secure'
							name="sender_emails.orders.secure">
							<mat-option [value]="true">{{ 'yes' | i18n }}</mat-option>
							<mat-option [value]="false">{{ 'no' | i18n }}</mat-option>
						</mat-select>
						<mat-error>{{ errors.sender_emails.orders.secure }}</mat-error>
					</mat-form-field>

					<mat-form-field appearance="outline" class="form-group col-md-6">
						<mat-label>{{ 'sender_emails.orders.port' | i18n }}</mat-label>
						<input type="number" matInput [(ngModel)]="item.sender_emails.orders.port"
							[errorStateMatcher]='validate.sender_emails.orders.port'
							placeholder="{{ 'sender_emails.orders.port' | i18n }}" />
						<mat-error>{{ errors.sender_emails.orders.port }}</mat-error>
					</mat-form-field>

					<mat-form-field appearance="outline" class="form-group col-md-6">
						<mat-label>{{ 'sender_emails.orders.username' | i18n }}</mat-label>
						<input type="email" matInput [(ngModel)]="item.sender_emails.orders.username"
							[errorStateMatcher]='validate.sender_emails.orders.username'
							placeholder="{{ 'sender_emails.orders.username' | i18n }}" />
						<mat-error>{{ errors.sender_emails.orders.username }}</mat-error>
					</mat-form-field>

					<mat-form-field appearance="outline" class="form-group col-md-6">
						<mat-label>{{ 'sender_emails.orders.password' | i18n }}</mat-label>
						<input type="password" matInput [(ngModel)]="item.sender_emails.orders.password"
							[errorStateMatcher]='validate.sender_emails.orders.password'
							placeholder="{{ 'sender_emails.orders.password' | i18n }}" />
						<mat-error>{{ errors.sender_emails.orders.password }}</mat-error>
					</mat-form-field>

				</div>

			</mat-card-content>
		</mat-card>
		<mat-divider></mat-divider>

		<mat-card>
			<mat-card-subtitle>{{ 'settings_order' | i18n }}</mat-card-subtitle>
			<mat-card-content>

				<div class="row">

					<mat-form-field appearance="outline" class="form-group col-md-6">
						<mat-label>{{ 'orders.min_delivery_time' | i18n }}</mat-label>
						<mat-select placeholder="{{ 'orders.min_delivery_time' | i18n }}"
							[(ngModel)]='item.orders.min_delivery_time' [errorStateMatcher]='validate.orders.min_delivery_time'
							name="orders.min_delivery_time">
							<mat-option>-- {{ 'remove' | i18n }} --</mat-option>
							<mat-option *ngFor="let dt of min_delivery_times" [value]="dt">{{ time_convert(dt) }}
							</mat-option>
						</mat-select>
						<mat-error>{{ errors.orders.min_delivery_time }}</mat-error>
					</mat-form-field>

					<mat-form-field appearance="outline" class="form-group col-md-6">
						<mat-label>{{ 'orders.vip_min_delivery_time' | i18n }}</mat-label>
						<mat-select placeholder="{{ 'orders.vip_min_delivery_time' | i18n }}"
							[(ngModel)]='item.orders.vip_min_delivery_time' [errorStateMatcher]='validate.orders.vip_min_delivery_time'
							name="orders.vip_min_delivery_time">
							<mat-option>-- {{ 'remove' | i18n }} --</mat-option>
							<mat-option *ngFor="let dt of min_delivery_times" [value]="dt">{{ time_convert(dt) }}
							</mat-option>
						</mat-select>
						<mat-error>{{ errors.orders.vip_min_delivery_time }}</mat-error>
					</mat-form-field>

					<mat-form-field appearance="outline" class="form-group col-md-6">
						<mat-label>{{ 'orders.min_value' | i18n }}</mat-label>
						<input matInput [(ngModel)]="item.orders.min_value" [errorStateMatcher]='validate.orders.min_value'
							placeholder="{{ 'orders.min_value' | i18n }}" />
						<mat-error>{{ errors.orders.min_value }}</mat-error>
					</mat-form-field>

					<mat-form-field appearance="outline" class="form-group col-md-6">
						<mat-label>{{ 'orders.vip_min_value' | i18n }}</mat-label>
						<input matInput [(ngModel)]="item.orders.vip_min_value" [errorStateMatcher]='validate.orders.vip_min_value'
							placeholder="{{ 'orders.vip_min_value' | i18n }}" />
						<mat-error>{{ errors.orders.vip_min_value }}</mat-error>
					</mat-form-field>

					<mat-form-field appearance="outline" class="form-group col-md-6">
						<mat-label>{{ 'orders.enable_immediate_delivery' | i18n }}</mat-label>
						<mat-select placeholder="{{ 'orders.enable_immediate_delivery' | i18n }}"
							[(ngModel)]='item.orders.enable_immediate_delivery' [errorStateMatcher]='validate.orders.enable_immediate_delivery'
							name="orders.enable_immediate_delivery">
							<mat-option [value]="true">{{ 'yes' | i18n }}</mat-option>
							<mat-option [value]="false">{{ 'no' | i18n }}</mat-option>
						</mat-select>
						<mat-error>{{ errors.orders.enable_immediate_delivery }}</mat-error>
					</mat-form-field>

					<mat-form-field appearance="outline" class="form-group col-md-6" *ngFor="let l of languages">
						<mat-label>{{ 'orders.closed_message' | i18n }} ( {{ l.name }} )</mat-label>
						<input matInput [(ngModel)]="item.orders.closed_message[l.code]" [errorStateMatcher]='validate.orders.closed_message[l.code]'
							placeholder="{{ 'orders.closed_message' | i18n }}" />
						<mat-error>{{ errors.orders.closed_message[l.code] }}</mat-error>
					</mat-form-field>

					<!-- {{ environment.mediaUrl }} -->

					<mat-form-field appearance="outline" class="form-group col-md-6">
						<mat-label>{{ 'orders.allow.enable_on_web' | i18n }}</mat-label>
						<mat-select placeholder="{{ 'orders.allow.enable_on_web' | i18n }}"
							[(ngModel)]='item.orders.allow.enable_on_web' [errorStateMatcher]='validate.orders.allow.enable_on_web'
							name="orders.allow.enable_on_web">
							<mat-option [value]="true">{{ 'yes' | i18n }}</mat-option>
							<mat-option [value]="false">{{ 'no' | i18n }}</mat-option>
						</mat-select>
						<mat-error>{{ errors.orders.allow.enable_on_web }}</mat-error>
					</mat-form-field>

					<mat-form-field appearance="outline" class="form-group col-md-6">
						<mat-label>{{ 'orders.allow.enable_on_android' | i18n }}</mat-label>
						<mat-select placeholder="{{ 'orders.allow.enable_on_android' | i18n }}"
							[(ngModel)]='item.orders.allow.enable_on_android' [errorStateMatcher]='validate.orders.allow.enable_on_android'
							name="orders.allow.enable_on_android">
							<mat-option [value]="true">{{ 'yes' | i18n }}</mat-option>
							<mat-option [value]="false">{{ 'no' | i18n }}</mat-option>
						</mat-select>
						<mat-error>{{ errors.orders.allow.enable_on_android }}</mat-error>
					</mat-form-field>

					<mat-form-field appearance="outline" class="form-group col-md-6">
						<mat-label>{{ 'orders.allow.enable_on_ios' | i18n }}</mat-label>
						<mat-select placeholder="{{ 'orders.allow.enable_on_ios' | i18n }}"
							[(ngModel)]='item.orders.allow.enable_on_ios' [errorStateMatcher]='validate.orders.allow.enable_on_ios'
							name="orders.allow.enable_on_ios">
							<mat-option [value]="true">{{ 'yes' | i18n }}</mat-option>
							<mat-option [value]="false">{{ 'no' | i18n }}</mat-option>
						</mat-select>
						<mat-error>{{ errors.orders.allow.enable_on_ios }}</mat-error>
					</mat-form-field>

					<mat-form-field appearance="outline" class="form-group col-md-6">
						<mat-label>{{ 'orders.enable_tap' | i18n }}</mat-label>
						<mat-select placeholder="{{ 'orders.enable_tap' | i18n }}"
							[(ngModel)]='item.orders.enable_tap' [errorStateMatcher]='validate.orders.enable_tap'
							name="orders.enable_tap">
							<mat-option [value]="true">{{ 'yes' | i18n }}</mat-option>
							<mat-option [value]="false">{{ 'no' | i18n }}</mat-option>
						</mat-select>
						<mat-error>{{ errors.orders.enable_tap }}</mat-error>
					</mat-form-field>


					<mat-form-field appearance="outline" class="form-group col-md-6">
						<mat-label>{{ 'orders.allow.cod_for_registered_on_android' | i18n }}</mat-label>
						<mat-select placeholder="{{ 'orders.allow.cod_for_registered_on_android' | i18n }}"
							[(ngModel)]='item.orders.allow.cod_for_registered_on_android'
							[errorStateMatcher]='validate.orders.allow.cod_for_registered_on_android'
							name="orders.allow.cod_for_registered_on_android">
							<mat-option [value]="true">{{ 'yes' | i18n }}</mat-option>
							<mat-option [value]="false">{{ 'no' | i18n }}</mat-option>
						</mat-select>
						<mat-error>{{ errors.orders.allow.cod_for_registered_on_android }}</mat-error>
					</mat-form-field>

					<mat-form-field appearance="outline" class="form-group col-md-6">
						<mat-label>{{ 'orders.allow.cod_for_visitors_on_android' | i18n }}</mat-label>
						<mat-select placeholder="{{ 'orders.allow.cod_for_visitors_on_android' | i18n }}"
							[(ngModel)]='item.orders.allow.cod_for_visitors_on_android'
							[errorStateMatcher]='validate.orders.allow.cod_for_visitors_on_android' name="orders.allow.cod_for_visitors_on_android">
							<mat-option [value]="true">{{ 'yes' | i18n }}</mat-option>
							<mat-option [value]="false">{{ 'no' | i18n }}</mat-option>
						</mat-select>
						<mat-error>{{ errors.orders.allow.cod_for_visitors_on_android }}</mat-error>
					</mat-form-field>

					<mat-form-field appearance="outline" class="form-group col-md-6">
						<mat-label>{{ 'orders.allow.knet_for_registered_on_android' | i18n }}</mat-label>
						<mat-select placeholder="{{ 'orders.allow.knet_for_registered_on_android' | i18n }}"
							[(ngModel)]='item.orders.allow.knet_for_registered_on_android'
							[errorStateMatcher]='validate.orders.allow.knet_for_registered_on_android'
							name="orders.allow.knet_for_registered_on_android">
							<mat-option [value]="true">{{ 'yes' | i18n }}</mat-option>
							<mat-option [value]="false">{{ 'no' | i18n }}</mat-option>
						</mat-select>
						<mat-error>{{ errors.orders.allow.knet_for_registered_on_android }}</mat-error>
					</mat-form-field>


					<mat-form-field appearance="outline" class="form-group col-md-6">
						<mat-label>{{ 'orders.allow.knet_for_visitors_on_android' | i18n }}</mat-label>
						<mat-select placeholder="{{ 'orders.allow.knet_for_visitors_on_android' | i18n }}"
							[(ngModel)]='item.orders.allow.knet_for_visitors_on_android'
							[errorStateMatcher]='validate.orders.allow.knet_for_visitors_on_android' name="orders.allow.knet_for_visitors_on_android">
							<mat-option [value]="true">{{ 'yes' | i18n }}</mat-option>
							<mat-option [value]="false">{{ 'no' | i18n }}</mat-option>
						</mat-select>
						<mat-error>{{ errors.orders.allow.knet_for_visitors_on_android }}</mat-error>
					</mat-form-field>


					<mat-form-field appearance="outline" class="form-group col-md-6">
						<mat-label>{{ 'orders.allow.cod_for_registered_on_ios' | i18n }}</mat-label>
						<mat-select placeholder="{{ 'orders.allow.cod_for_registered_on_ios' | i18n }}"
							[(ngModel)]='item.orders.allow.cod_for_registered_on_ios'
							[errorStateMatcher]='validate.orders.allow.cod_for_registered_on_ios' name="orders.allow.cod_for_registered_on_ios">
							<mat-option [value]="true">{{ 'yes' | i18n }}</mat-option>
							<mat-option [value]="false">{{ 'no' | i18n }}</mat-option>
						</mat-select>
						<mat-error>{{ errors.orders.allow.cod_for_registered_on_ios }}</mat-error>
					</mat-form-field>

					<mat-form-field appearance="outline" class="form-group col-md-6">
						<mat-label>{{ 'orders.allow.cod_for_visitors_on_ios' | i18n }}</mat-label>
						<mat-select placeholder="{{ 'orders.allow.cod_for_visitors_on_ios' | i18n }}"
							[(ngModel)]='item.orders.allow.cod_for_visitors_on_ios'
							[errorStateMatcher]='validate.orders.allow.cod_for_visitors_on_ios' name="orders.allow.cod_for_visitors_on_ios">
							<mat-option [value]="true">{{ 'yes' | i18n }}</mat-option>
							<mat-option [value]="false">{{ 'no' | i18n }}</mat-option>
						</mat-select>
						<mat-error>{{ errors.orders.allow.cod_for_visitors_on_ios }}</mat-error>
					</mat-form-field>

					<mat-form-field appearance="outline" class="form-group col-md-6">
						<mat-label>{{ 'orders.allow.knet_for_registered_on_ios' | i18n }}</mat-label>
						<mat-select placeholder="{{ 'orders.allow.knet_for_registered_on_ios' | i18n }}"
							[(ngModel)]='item.orders.allow.knet_for_registered_on_ios'
							[errorStateMatcher]='validate.orders.allow.knet_for_registered_on_ios' name="orders.allow.knet_for_registered_on_ios">
							<mat-option [value]="true">{{ 'yes' | i18n }}</mat-option>
							<mat-option [value]="false">{{ 'no' | i18n }}</mat-option>
						</mat-select>
						<mat-error>{{ errors.orders.allow.knet_for_registered_on_ios }}</mat-error>
					</mat-form-field>


					<mat-form-field appearance="outline" class="form-group col-md-6">
						<mat-label>{{ 'orders.allow.knet_for_visitors_on_ios' | i18n }}</mat-label>
						<mat-select placeholder="{{ 'orders.allow.knet_for_visitors_on_ios' | i18n }}"
							[(ngModel)]='item.orders.allow.knet_for_visitors_on_ios'
							[errorStateMatcher]='validate.orders.allow.knet_for_visitors_on_ios' name="orders.allow.knet_for_visitors_on_ios">
							<mat-option [value]="true">{{ 'yes' | i18n }}</mat-option>
							<mat-option [value]="false">{{ 'no' | i18n }}</mat-option>
						</mat-select>
						<mat-error>{{ errors.orders.allow.knet_for_visitors_on_ios }}</mat-error>
					</mat-form-field>


					<mat-form-field appearance="outline" class="form-group col-md-6">
						<mat-label>{{ 'orders.allow.cod_for_registered_on_web' | i18n }}</mat-label>
						<mat-select placeholder="{{ 'orders.allow.cod_for_registered_on_web' | i18n }}"
							[(ngModel)]='item.orders.allow.cod_for_registered_on_web'
							[errorStateMatcher]='validate.orders.allow.cod_for_registered_on_web' name="orders.allow.cod_for_registered_on_web">
							<mat-option [value]="true">{{ 'yes' | i18n }}</mat-option>
							<mat-option [value]="false">{{ 'no' | i18n }}</mat-option>
						</mat-select>
						<mat-error>{{ errors.orders.allow.cod_for_registered_on_web }}</mat-error>
					</mat-form-field>

					<mat-form-field appearance="outline" class="form-group col-md-6">
						<mat-label>{{ 'orders.allow.cod_for_visitors_on_web' | i18n }}</mat-label>
						<mat-select placeholder="{{ 'orders.allow.cod_for_visitors_on_web' | i18n }}"
							[(ngModel)]='item.orders.allow.cod_for_visitors_on_web'
							[errorStateMatcher]='validate.orders.allow.cod_for_visitors_on_web' name="orders.allow.cod_for_visitors_on_web">
							<mat-option [value]="true">{{ 'yes' | i18n }}</mat-option>
							<mat-option [value]="false">{{ 'no' | i18n }}</mat-option>
						</mat-select>
						<mat-error>{{ errors.orders.allow.cod_for_visitors_on_web }}</mat-error>
					</mat-form-field>

					<mat-form-field appearance="outline" class="form-group col-md-6">
						<mat-label>{{ 'orders.allow.knet_for_registered_on_web' | i18n }}</mat-label>
						<mat-select placeholder="{{ 'orders.allow.knet_for_registered_on_web' | i18n }}"
							[(ngModel)]='item.orders.allow.knet_for_registered_on_web'
							[errorStateMatcher]='validate.orders.allow.knet_for_registered_on_web' name="orders.allow.knet_for_registered_on_web">
							<mat-option [value]="true">{{ 'yes' | i18n }}</mat-option>
							<mat-option [value]="false">{{ 'no' | i18n }}</mat-option>
						</mat-select>
						<mat-error>{{ errors.orders.allow.knet_for_registered_on_web }}</mat-error>
					</mat-form-field>

					<mat-form-field appearance="outline" class="form-group col-md-6">
						<mat-label>{{ 'orders.allow.knet_for_visitors_on_web' | i18n }}</mat-label>
						<mat-select placeholder="{{ 'orders.allow.knet_for_visitors_on_web' | i18n }}"
							[(ngModel)]='item.orders.allow.knet_for_visitors_on_web'
							[errorStateMatcher]='validate.orders.allow.knet_for_visitors_on_web' name="orders.allow.knet_for_visitors_on_web">
							<mat-option [value]="true">{{ 'yes' | i18n }}</mat-option>
							<mat-option [value]="false">{{ 'no' | i18n }}</mat-option>
						</mat-select>
						<mat-error>{{ errors.orders.allow.knet_for_visitors_on_web }}</mat-error>
					</mat-form-field>

					<mat-form-field appearance="outline" class="form-group col-md-6">
						<mat-label>{{ 'orders.evaluation.enable_on_web' | i18n }}</mat-label>
						<mat-select placeholder="{{ 'orders.evaluation.enable_on_web' | i18n }}"
							[(ngModel)]='item.orders.evaluation.enable_on_web' [errorStateMatcher]='validate.orders.evaluation.enable_on_web'
							name="orders.evaluation.enable_on_web">
							<mat-option [value]="true">{{ 'yes' | i18n }}</mat-option>
							<mat-option [value]="false">{{ 'no' | i18n }}</mat-option>
						</mat-select>
						<mat-error>{{ errors.orders.evaluation.enable_on_web }}</mat-error>
					</mat-form-field>

					<mat-form-field appearance="outline" class="form-group col-md-6">
						<mat-label>{{ 'orders.evaluation.enable_on_android' | i18n }}</mat-label>
						<mat-select placeholder="{{ 'orders.evaluation.enable_on_android' | i18n }}"
							[(ngModel)]='item.orders.evaluation.enable_on_android' [errorStateMatcher]='validate.orders.evaluation.enable_on_android'
							name="orders.evaluation.enable_on_android">
							<mat-option [value]="true">{{ 'yes' | i18n }}</mat-option>
							<mat-option [value]="false">{{ 'no' | i18n }}</mat-option>
						</mat-select>
						<mat-error>{{ errors.orders.evaluation.enable_on_android }}</mat-error>
					</mat-form-field>

					<mat-form-field appearance="outline" class="form-group col-md-6">
						<mat-label>{{ 'orders.evaluation.enable_on_ios' | i18n }}</mat-label>
						<mat-select placeholder="{{ 'orders.evaluation.enable_on_ios' | i18n }}"
							[(ngModel)]='item.orders.evaluation.enable_on_ios' [errorStateMatcher]='validate.orders.evaluation.enable_on_ios'
							name="orders.evaluation.enable_on_ios">
							<mat-option [value]="true">{{ 'yes' | i18n }}</mat-option>
							<mat-option [value]="false">{{ 'no' | i18n }}</mat-option>
						</mat-select>
						<mat-error>{{ errors.orders.evaluation.enable_on_ios }}</mat-error>
					</mat-form-field>

					<mat-form-field appearance="outline" class="form-group col-md-6">
						<mat-label>{{ 'orders.evaluation.enable_on_google_play' | i18n }}</mat-label>
						<mat-select placeholder="{{ 'orders.evaluation.enable_on_google_play' | i18n }}"
							[(ngModel)]='item.orders.evaluation.enable_on_google_play'
							[errorStateMatcher]='validate.orders.evaluation.enable_on_google_play' name="orders.evaluation.enable_on_google_play">
							<mat-option [value]="true">{{ 'yes' | i18n }}</mat-option>
							<mat-option [value]="false">{{ 'no' | i18n }}</mat-option>
						</mat-select>
						<mat-error>{{ errors.orders.evaluation.enable_on_google_play }}</mat-error>
					</mat-form-field>

					<mat-form-field appearance="outline" class="form-group col-md-6">
						<mat-label>{{ 'orders.evaluation.enable_on_app_store' | i18n }}</mat-label>
						<mat-select placeholder="{{ 'orders.evaluation.enable_on_app_store' | i18n }}"
							[(ngModel)]='item.orders.evaluation.enable_on_app_store'
							[errorStateMatcher]='validate.orders.evaluation.enable_on_app_store' name="orders.evaluation.enable_on_app_store">
							<mat-option [value]="true">{{ 'yes' | i18n }}</mat-option>
							<mat-option [value]="false">{{ 'no' | i18n }}</mat-option>
						</mat-select>
						<mat-error>{{ errors.orders.evaluation.enable_on_app_store }}</mat-error>
					</mat-form-field>

					<mat-form-field appearance="outline" class="form-group col-md-6">
						<mat-label>{{ 'orders.delay_delivery_times' | i18n }}</mat-label>
						<mat-select placeholder="{{ 'orders.delay_delivery_times' | i18n }}"
							[(ngModel)]='item.orders.delay_delivery_times' [errorStateMatcher]='validate.orders.delay_delivery_times'
							name="orders.delay_delivery_times">
							<mat-option [value]="true">{{ 'yes' | i18n }}</mat-option>
							<mat-option [value]="false">{{ 'no' | i18n }}</mat-option>
						</mat-select>
						<mat-error>{{ errors.orders.delay_delivery_times }}</mat-error>
					</mat-form-field>

					<mat-form-field appearance="outline" class="form-group col-md-6">
						<mat-label>{{ 'orders.delay_delivery_times_days' | i18n }}</mat-label>
						<input type="number" matInput [(ngModel)]="item.orders.delay_delivery_times_days"
							[errorStateMatcher]='validate.orders.delay_delivery_times_days'
							placeholder="{{ 'orders.delay_delivery_times_days' | i18n }}" />
						<mat-error>{{ errors.orders.delay_delivery_times_days }}</mat-error>
					</mat-form-field>

					<mat-form-field appearance="outline" class="form-group col-md-6">
						<mat-label>{{ 'orders.enable_confirm' | i18n }}</mat-label>
						<mat-select placeholder="{{ 'orders.enable_confirm' | i18n }}"
							[(ngModel)]='item.orders.enable_confirm' [errorStateMatcher]='validate.orders.enable_confirm'
							name="orders.enable_confirm">
							<mat-option [value]="true">{{ 'yes' | i18n }}</mat-option>
							<mat-option [value]="false">{{ 'no' | i18n }}</mat-option>
						</mat-select>
						<mat-error>{{ errors.orders.enable_confirm }}</mat-error>
					</mat-form-field>

					<mat-form-field appearance="outline" class="form-group col-md-6">
						<mat-label>{{ 'orders.enable_cancel' | i18n }}</mat-label>
						<mat-select placeholder="{{ 'orders.enable_cancel' | i18n }}"
							[(ngModel)]='item.orders.enable_cancel' [errorStateMatcher]='validate.orders.enable_cancel'
							name="orders.enable_cancel">
							<mat-option [value]="true">{{ 'yes' | i18n }}</mat-option>
							<mat-option [value]="false">{{ 'no' | i18n }}</mat-option>
						</mat-select>
						<mat-error>{{ errors.orders.enable_cancel }}</mat-error>
					</mat-form-field>

					  <mat-card class="col-md-12">
						<mat-card-subtitle>{{ 'jm3eia_pro' | i18n }}
						</mat-card-subtitle>
						<mat-card-content>
							<div class="row">
								<div class="form-group col-md-2" *ngIf="item.jm3eia_pro">
									<mat-checkbox [(ngModel)]="item.jm3eia_pro.allow">
									  {{ 'allow_jm3eia_pro' | i18n }}
									</mat-checkbox>
									<mat-error>{{ errors.jm3eia_pro.allow }}</mat-error>
								  </div>

									<!-- <mat-form-field appearance="outline" class="form-group col-md-4" *ngIf="item.jm3eia_pro && item.jm3eia_pro.allow">
										<mat-label>{{ 'price' | i18n }} (3 months)</mat-label>
										<input matInput [(ngModel)]="item.jm3eia_pro.subscriptions[0].price"
												[errorStateMatcher]='validate.jm3eia_pro.subscriptions[0].price'
												placeholder="{{ 'price' | i18n }} (3 months)" />
										<mat-error>{{ errors.jm3eia_pro.subscriptions[0].price }}</mat-error>
									</mat-form-field> -->
									<mat-form-field appearance="outline" class="form-group col-md-4" *ngIf="item.jm3eia_pro && item.jm3eia_pro.allow">
										<mat-label>{{ 'price' | i18n }} (1 year)</mat-label>
										<input matInput [(ngModel)]="item.jm3eia_pro.subscriptions[0].price"
												[errorStateMatcher]='validate.jm3eia_pro.subscriptions[0].price'
												placeholder="{{ 'price' | i18n }} (1 year)" />
										<mat-error>{{ errors.jm3eia_pro.subscriptions[0].price }}</mat-error>
									</mat-form-field>
								  <div class="form-group col-md-2" *ngIf="item.jm3eia_pro && item.jm3eia_pro.allow">
									<mat-checkbox [(ngModel)]="item.jm3eia_pro.only_jm3eia">
									  {{ 'only_jm3eia' | i18n }}
									</mat-checkbox>
									<mat-error>{{ errors.jm3eia_pro.only_jm3eia }}</mat-error>
								  </div>

								<mat-tab-group class="col-md-12">
									<mat-tab *ngFor="let l of languages">
										<ng-template mat-tab-label>
											<i class="flag-icon flag-icon-{{ l.image }}"></i>
											{{ l.name }}
										</ng-template>
										<mat-form-field appearance="outline" class="form-group col-md-12 mat-upload">
												<mat-label>{{'banner' | i18n}} ( {{ l.name }} )</mat-label>
												<input type="file" (change)="upload($event.target.files, 'jm3eia_pro.banner.' + l.code)" />
												{{'upload_percent' | i18n}}: {{percentDone}}%
												<img *ngIf="item.jm3eia_pro.banner[l.code]" src="{{environment.mediaUrl}}{{item.jm3eia_pro.banner[l.code]}}" width="48" />
												<input matInput hidden [(ngModel)]="item.jm3eia_pro.banner[l.code]" [errorStateMatcher]='validate.jm3eia_pro.banner[l.code]'>
												<br />
												<mat-error>{{ errors.jm3eia_pro.banner[l.code] }}</mat-error>
										</mat-form-field>

										<div class="col-12" dir="{{ l.direction }}">
											<mat-form-field appearance="outline" class="form-group col-md-12">
												<mat-label>{{ 'title' | i18n }} ( {{ l.name }} )
												</mat-label>
												<input matInput [(ngModel)]="jm3eia_pro_content['title'][l.code]"
													placeholder="{{ 'title' | i18n }}" />
												<!-- <mat-error>{{ errors.name[l.code] }}</mat-error> -->
											</mat-form-field>

											<mat-form-field appearance="outline" class="form-group col-md-12">
												<mat-label>{{ 'description' | i18n }} ( {{ l.name }} )
												</mat-label>
												<textarea matInput [(ngModel)]="jm3eia_pro_content['description'][l.code]"
													placeholder="{{ 'description' | i18n }}"></textarea>
												<!-- <mat-error>{{ errors.description[l.code] }}</mat-error> -->
											</mat-form-field>

										</div>
									</mat-tab>
								</mat-tab-group>
								<mat-form-field appearance="outline" class="form-group col-md-4">
									<mat-label>{{ 'image' | i18n }}</mat-label>
									<input type="file" (change)="upload($event.target.files)" />
									{{ 'upload_percent' | i18n }}: {{ percentDone }}%
									<img *ngIf="jm3eia_pro_content['image']" src="{{ environment.mediaUrl }}{{ jm3eia_pro_content.image }}" width="48" />
									<input matInput hidden [(ngModel)]="jm3eia_pro_content['image']" name="picture">
									<br />
									<!-- <mat-error>{{ errors.picture }}</mat-error> -->
								</mat-form-field>

								<!-- <mat-form-field appearance="outline" class="form-group col-md-6">
									<mat-label>{{ 'title' | i18n }}</mat-label>
									<input matInput [(ngModel)]="jm3eia_pro_content['title']" name="title"
										placeholder="{{ 'title' | i18n }}" />
								</mat-form-field>

								<mat-form-field appearance="outline" class="form-group col-md-6">
									<mat-label>{{ 'description' | i18n }}</mat-label>
									<input matInput [(ngModel)]="jm3eia_pro_content['description']" name="description"
										placeholder="{{ 'description' | i18n }}" />
								</mat-form-field> -->

								<div class="form-group col-md-6">
									<button mat-raised-button color="primary"
										[disabled]="!jm3eia_pro_content['title'] || !jm3eia_pro_content['description']"
										(click)="add_pro_content()">
										<mat-icon>add</mat-icon>
										{{ 'add' | i18n }}
									</button>
								</div>
							</div>
							<div class="form-row" *ngIf="item.jm3eia_pro.content && item.jm3eia_pro.content.length > 0">
								<table class="table table-bordered">
									<tr>
										<td>{{ 'image' | i18n }}</td>
										<td>{{ 'title' | i18n }}</td>
										<td>{{ 'description' | i18n }}</td>
										<td></td>
									</tr>
									<tr *ngFor="let p of item.jm3eia_pro.content; let i = index;">
										<td>
											<img *ngIf="p['image']" src="{{ environment.mediaUrl }}{{ p.image }}" width="48" />
										</td>
										<td>
											<p *ngFor="let l of languages">{{ l.code }}: {{ p.title[l.code] }}</p>
										</td>
										<td>
											<p *ngFor="let l of languages">{{ l.code }}: {{ p.description[l.code] }}</p>
										</td>
										<td>
											<button mat-mini-fab color="warn" (click)="delete_pro_content(i)"
												title="{{ 'delete' | i18n }}">
												<mat-icon>delete</mat-icon>
											</button>
										</td>
									</tr>
								</table>
							</div>
						</mat-card-content>
					</mat-card>


					<!-- <mat-form-field appearance="outline" class="form-group col-md-6">
						<mat-checkbox [(ngModel)]="item.jm3eia_pro.allow">
							{{ 'allow' | i18n }}</mat-checkbox>
						<mat-error>{{ errors.jm3eia_pro.allow }}</mat-error>
					</mat-form-field>

					<mat-form-field appearance="outline" class="form-group col-md-6" *ngIf="item.jm3eia_pro.allow">
						<mat-label>{{ 'shipping_cost' | i18n }}</mat-label>
						<input matInput [(ngModel)]="item.jm3eia_pro.shipping_cost"
							[errorStateMatcher]='validate.jm3eia_pro.shipping_cost'
							placeholder="{{ 'shipping_cost' | i18n }}" />
						<mat-error>{{ errors.jm3eia_pro.shipping_cost }}</mat-error>
					</mat-form-field>

					<mat-form-field appearance="outline" class="form-group col-md-6" *ngIf="item.jm3eia_pro.allow">
						<mat-checkbox [(ngModel)]="item.jm3eia_pro.only_jm3eia">
							{{ 'only_jm3eia' | i18n }}</mat-checkbox>
						<mat-error>{{ errors.jm3eia_pro.only_jm3eia }}</mat-error>
					</mat-form-field> -->

				</div>
				<div class="row">

					<mat-card>
						<mat-card-subtitle>{{ 'delivery_times' | i18n }}</mat-card-subtitle>
						<mat-card-content>

							<mat-accordion *ngFor="let d of days">
								<mat-expansion-panel>
									<mat-expansion-panel-header>
										<mat-panel-title>
											{{ d.text }}
										</mat-panel-title>
										<mat-panel-description>
										</mat-panel-description>
									</mat-expansion-panel-header>

									<div class="container">
										<div class="row">
											<div class="col-md-6" *ngFor="let p of times">
												<div class="row">
													<div class="col-md-3">
														{{ p.text }}
													</div>
													<div class="col-md-3">
														<mat-checkbox [(ngModel)]="item.orders.available_delivery_times[d.id][p.id]['is_available']">
															{{ 'is_available' | i18n }}</mat-checkbox>
													</div>
													<div class="col-md-6">
														<mat-form-field appearance="outline" class="form-group">
															<mat-label>{{ 'max_orders' | i18n }}</mat-label>
															<input type="number" matInput
																[(ngModel)]="item.orders.available_delivery_times[d.id][p.id]['max_orders']"
																placeholder="{{ 'max_orders' | i18n }}" />
														</mat-form-field>
													</div>
												</div>
											</div>
										</div>
									</div>


								</mat-expansion-panel>
							</mat-accordion>

						</mat-card-content>
					</mat-card>


				</div>

			</mat-card-content>
		</mat-card>

		<mat-divider></mat-divider>

		<mat-card class="col-md-12">
			<mat-card-subtitle>{{ 'display' | i18n }}
			</mat-card-subtitle>
			<mat-card-content>
				<div class="row">
					<mat-form-field appearance="outline" class="form-group col-md-6">
						<mat-label>{{ 'display.display_order_again' | i18n }}</mat-label>
						<mat-select placeholder="{{ 'display.display_order_again' | i18n }}"
							[(ngModel)]='item.display.display_order_again'
							[errorStateMatcher]='validate.display.display_order_again' name="display.display_order_again">
							<mat-option [value]="true">{{ 'yes' | i18n }}</mat-option>
							<mat-option [value]="false">{{ 'no' | i18n }}</mat-option>
						</mat-select>
						<mat-error>{{ errors.display.display_order_again }}</mat-error>
					</mat-form-field>
					<mat-form-field appearance="outline" class="form-group col-md-6">
						<mat-label>{{ 'display.display_best_selling' | i18n }}</mat-label>
						<mat-select placeholder="{{ 'display.display_best_selling' | i18n }}"
							[(ngModel)]='item.display.display_best_selling'
							[errorStateMatcher]='validate.display.display_best_selling' name="display.display_best_selling">
							<mat-option [value]="true">{{ 'yes' | i18n }}</mat-option>
							<mat-option [value]="false">{{ 'no' | i18n }}</mat-option>
						</mat-select>
						<mat-error>{{ errors.display.display_best_selling }}</mat-error>
					</mat-form-field>
					<mat-form-field appearance="outline" class="form-group col-md-6">
						<mat-label>{{ 'display.display_supplier_reviews' | i18n }}</mat-label>
						<mat-select placeholder="{{ 'display.display_supplier_reviews' | i18n }}"
							[(ngModel)]='item.display.display_supplier_reviews'
							[errorStateMatcher]='validate.display.display_supplier_reviews' name="display.display_supplier_reviews">
							<mat-option [value]="true">{{ 'yes' | i18n }}</mat-option>
							<mat-option [value]="false">{{ 'no' | i18n }}</mat-option>
						</mat-select>
						<mat-error>{{ errors.display.display_supplier_reviews }}</mat-error>
					</mat-form-field>

					<div class="col-12">
						<mat-card>
							<mat-card-subtitle>{{ 'jm3eia_vip' | i18n }}</mat-card-subtitle>
							<mat-card-content>
								<mat-tab-group>
									<mat-tab *ngFor="let l of languages">
										<ng-template mat-tab-label>
											<i class="flag-icon flag-icon-{{ l.image }}"></i>
											{{ l.name }}
										</ng-template>
										<div class="col-12" dir="{{ l.direction }}">
		
											<mat-form-field appearance="outline" class="form-group col-md-12">
												<mat-label>{{ 'title' | i18n }} ( {{ l.name }} )
												</mat-label>
												<input matInput [(ngModel)]="item.display.content.jm3eia_vip.title[l.code]" [errorStateMatcher]='validate.display.content.jm3eia_vip.title[l.code]'
													placeholder="{{ 'title' | i18n }}" />
												<mat-error>{{ errors.display.content.jm3eia_vip.title[l.code] }}</mat-error>
											</mat-form-field>
		
											<mat-form-field appearance="outline" class="form-group col-md-12">
												<mat-label>{{ 'description' | i18n }} ( {{ l.name }} )
												</mat-label>
												<textarea matInput [(ngModel)]="item.display.content.jm3eia_vip.description[l.code]" [errorStateMatcher]='validate?.display.content.jm3eia_vip.description[l.code]'
													placeholder="{{ 'description' | i18n }}"></textarea>
												<mat-error>{{ errors.display.content.jm3eia_vip.description[l.code] }}</mat-error>
											</mat-form-field>

											<mat-form-field appearance="outline" class="form-group col-md-6 mat-upload">
												<mat-label>{{'picture' | i18n}} ( {{ l.name }} )</mat-label>
												<input type="file" (change)="upload($event.target.files, 'display.content.jm3eia_vip.picture.' + l.code)" />
												{{'upload_percent' | i18n}}: {{percentDone}}%
												<img *ngIf="item.display.content.jm3eia_vip.picture[l.code]" src="{{environment.mediaUrl}}{{item.display.content.jm3eia_vip.picture[l.code]}}" width="48" />
												<input matInput hidden [(ngModel)]="item.display.content.jm3eia_vip.picture[l.code]" [errorStateMatcher]='validate.display.content.jm3eia_vip.picture[l.code]'>
												<br />
												<mat-error>{{ errors.display.content.jm3eia_vip.picture[l.code] }}</mat-error>
											</mat-form-field>
		
										</div>
									</mat-tab>
								</mat-tab-group>
							</mat-card-content>
						</mat-card>
					</div>

					<div class="col-12">
						<mat-card>
							<mat-card-subtitle>{{ 'donation' | i18n }}</mat-card-subtitle>
							<mat-card-content>
								<mat-form-field appearance="outline" class="form-group col-md-6">
									<mat-label>{{ 'enable' | i18n }}</mat-label>
									<mat-select placeholder="{{ 'enable' | i18n }}"
										[(ngModel)]='item.donation.enable' [errorStateMatcher]='validate.donation.enable'
										name="enable">
										<mat-option [value]="true">{{ 'yes' | i18n }}</mat-option>
										<mat-option [value]="false">{{ 'no' | i18n }}</mat-option>
									</mat-select>
									<mat-error>{{ errors.donation.enable }}</mat-error>
								</mat-form-field>
								<div class="">
									<mat-form-field appearance="outline" class="form-group col-md-4" *ngFor="let o of item.donation.options; let i = index" >
										<mat-label>{{ 'price' | i18n }} {{i + 1}}</mat-label>
										<input type="number" matInput [(ngModel)]="item.donation.options[i]" [name]="'donation.options.' + i" />
									</mat-form-field>
								</div>
								<mat-tab-group>
									<mat-tab *ngFor="let l of languages">
										<ng-template mat-tab-label>
											<i class="flag-icon flag-icon-{{ l.image }}"></i>
											{{ l.name }}
										</ng-template>
										<div class="col-12" dir="{{ l.direction }}">
		
											<mat-form-field appearance="outline" class="form-group col-md-12">
												<mat-label>{{ 'description' | i18n }} ( {{ l.name }} )
												</mat-label>
												<textarea matInput [(ngModel)]="item.donation.content.description[l.code]" [errorStateMatcher]='validate?.donation.content.description[l.code]'
													placeholder="{{ 'description' | i18n }}"></textarea>
												<mat-error>{{ errors.donation.content.description[l.code] }}</mat-error>
											</mat-form-field>

											<mat-form-field appearance="outline" class="form-group col-md-6 mat-upload">
												<mat-label>{{'picture' | i18n}} ( {{ l.name }} )</mat-label>
												<input type="file" (change)="upload($event.target.files, 'donation.content.picture.' + l.code)" />
												{{'upload_percent' | i18n}}: {{percentDone}}%
												<img *ngIf="item.donation.content.picture[l.code]" src="{{environment.mediaUrl}}{{item.donation.content.picture[l.code]}}" width="48" />
												<input matInput hidden [(ngModel)]="item.donation.content.picture[l.code]" [errorStateMatcher]='validate.donation.content.picture[l.code]'>
												<br />
												<mat-error>{{ errors.donation.content.picture[l.code] }}</mat-error>
											</mat-form-field>
		
										</div>
									</mat-tab>
								</mat-tab-group>
							</mat-card-content>
						</mat-card>
					</div>
				</div>
			</mat-card-content>
		</mat-card>

		<mat-divider></mat-divider>

		<mat-card>
			<mat-card-subtitle>{{ 'settings_wallet' | i18n }}</mat-card-subtitle>
			<mat-card-content>


				<div class="row">

					<mat-form-field appearance="outline" class="form-group col-md-6">
						<mat-label>{{ 'wallet.points_to_wallet' | i18n }}</mat-label>
						<input type="number" matInput [(ngModel)]="item.wallet.points_to_wallet"
							[errorStateMatcher]='validate?.wallet.points_to_wallet' name="wallet.points_to_wallet" />
						<mat-error>{{ errors.wallet.points_to_wallet }}</mat-error>
					</mat-form-field>

					<mat-form-field appearance="outline" class="form-group col-md-6">
						<mat-label>{{ 'wallet.user_can_convert_points_to_wallet' | i18n }}</mat-label>
						<mat-select placeholder="{{ 'wallet.user_can_convert_points_to_wallet' | i18n }}"
							[(ngModel)]='item.wallet.user_can_convert_points_to_wallet'
							[errorStateMatcher]='validate.wallet.user_can_convert_points_to_wallet' name="wallet.user_can_convert_points_to_wallet">
							<mat-option [value]="true">{{ 'yes' | i18n }}</mat-option>
							<mat-option [value]="false">{{ 'no' | i18n }}</mat-option>
						</mat-select>
						<mat-error>{{ errors.wallet.user_can_convert_points_to_wallet }}</mat-error>
					</mat-form-field>

				</div>

				<mat-divider></mat-divider>

				<div class="row">

					<mat-tab-group class="col-12">
						<mat-tab *ngFor="let l of languages">
							<ng-template mat-tab-label>
								<i class="flag-icon flag-icon-{{ l.image }}"></i>
								{{ l.name }}
							</ng-template>
							<div class="col-12" dir="{{ l.direction }}">

								<mat-form-field appearance="outline" class="form-group col-md-12">
									<mat-label>{{ 'wallet.description' | i18n }} ( {{ l.name }} )</mat-label>
									<input matInput hidden [(ngModel)]="item.wallet.description[l.code]"
										[errorStateMatcher]='validate?.wallet.description[l.code]' name="wallet.description[l.code]" />
									<ckeditor [(ngModel)]="item.wallet.description[l.code]" [editor]="Editor">
									</ckeditor>
									<mat-error>{{ errors.wallet.description[l.code] }}</mat-error>
								</mat-form-field>

							</div>
						</mat-tab>
					</mat-tab-group>

				</div>

			</mat-card-content>
		</mat-card>

	</mat-card-content>
	<mat-card-footer>
		<div mat-dialog-actions class="col-6 col-offset-3">
			<button mat-flat-button color="primary" class="btn-block" (click)="save()">{{ 'save' | i18n }}</button>
		</div>
		<br class="my-5" />
	</mat-card-footer>
</mat-card>
