import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Injectable()
export class BaseModelService {

	items: any;
	model: String;

	constructor(public http: HttpClient, public route: ActivatedRoute) {
	}

	getAll(filter = {}, sort = 'asc', skip = 0, limit = 10): any {
		let params = new HttpParams()
			.set(`sort[${filter['active']}]`, sort)
			.set('skip', (skip + 1).toString())
			.set('limit', limit.toString());
		const keys = Object.keys(filter);
		for (const k of keys) {
			if (!filter[k]) {
				continue;
			}
			params = params.append(`filter[${k}]`, filter[k]);
		}
		return this.http.get(`${environment.apiUrl}/${this.model}`, {
			params: params
		});
	}

}
