import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { BannerService } from './banner.service';
import { DetailsComponent } from './details/details.component';
import { FormComponent } from './form/form.component';
import { BaseIndex } from 'src/app/@core/libraries/pages/base-index';

@Component({
	selector: 'app-banner',
	templateUrl: './banner.component.html',
	styleUrls: ['./banner.component.scss']
})
export class BannerComponent extends BaseIndex {

	model = 'banner';
	displayedColumns: string[] = ['select', '_id', 'name', 'position', 'created', 'status', 'tools'];
	theDetailsComponent = DetailsComponent;
	theFormComponent = FormComponent;
	formWidth: String = '600px';
	detailsWidth: String = '400px';

	constructor(protected theService: BannerService,
		protected router: Router, protected dialog: MatDialog) {
		super(theService, router, dialog);
	}

}
