import { Pipe, PipeTransform } from '@angular/core';
import { i18n } from '../libraries/i18n';

@Pipe({
	name: 'i18n'
})
export class I18nPipe implements PipeTransform {

	transform(value: any): any {
		if (!value) {
			return '';
		}
		if (i18n[value]) {
			return i18n[value];
		}
		value = value.replace('_', ' ');
		return value.charAt(0).toUpperCase() + value.slice(1);
	}

}
