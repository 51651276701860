<mat-card class="mat-elevation-z8">

	<mat-toolbar>
		<mat-toolbar-row>
			<button mat-raised-button color="primary" (click)="openForm('add')">
				<mat-icon>add_box</mat-icon>
				{{ 'add' | i18n }}
			</button>
			<button mat-raised-button color="link" (click)="openForm('edit', selectedID)">
				<mat-icon>edit_box</mat-icon>
				{{ 'edit' | i18n }}
			</button>
			<button mat-raised-button color="warn" (click)="openDelete()">
				<mat-icon>delete_box</mat-icon>
				{{ 'delete' | i18n }}
			</button>
			<button mat-raised-button color="link" (click)="selectAll()">
				<mat-icon>check_box</mat-icon>
				{{ 'select_all' | i18n }}
			</button>
			<button mat-raised-button color="link" (click)="deselectAll()">
				<mat-icon>check_box_outline_blank</mat-icon>
				{{ 'deselect_all' | i18n }}
			</button>
			<button mat-raised-button color="link" (click)="setSearchForm()">
				<mat-icon>search</mat-icon>
				{{ 'search' | i18n }}
			</button>
			<a href="{{ csv_url }}" mat-raised-button color="link">
				<mat-icon>cloud_download</mat-icon>
				{{ 'export' | i18n }}
			</a>
			<a href="{{ csv_url_filtered }}" mat-raised-button color="link">
				<mat-icon>cloud_download</mat-icon>
				{{ 'filtered' | i18n }}
			</a>
			<button mat-raised-button color="link" (click)="showImportForm()">
				<mat-icon>cloud_upload</mat-icon>
				{{ 'import' | i18n }}
			</button>
			<span class="spacer"></span>
			<h2 class="title"> {{ 'products' | i18n }} </h2>
		</mat-toolbar-row>
	</mat-toolbar>

	<mat-card *ngIf="searchForm">
		<div class="form-row">
			<mat-form-field appearance="outline" class="form-group col-md-4">
				<mat-label>{{ 'name' | i18n }}</mat-label>
				<input matInput [(ngModel)]="filter.name" placeholder="{{ 'name' | i18n }}" (keyup)="get()" />
			</mat-form-field>
			<mat-form-field appearance="outline" class="form-group col-md-4">
				<mat-label>{{ 'sku' | i18n }}</mat-label>
				<input matInput [(ngModel)]="filter.sku" placeholder="{{ 'sku' | i18n }}" (keyup)="get()" />
			</mat-form-field>
			<mat-form-field appearance="outline" class="form-group col-md-4">
				<mat-label>{{ 'barcode' | i18n }}</mat-label>
				<input matInput [(ngModel)]="filter.barcode" placeholder="{{ 'barcode' | i18n }}" (keyup)="get()" />
			</mat-form-field>
			<mat-form-field appearance="outline" class="form-group col-md-4">
				<mat-label>{{ 'brand' | i18n }}</mat-label>
				<mat-select placeholder="{{ 'brand' | i18n }}" [(ngModel)]="filter.brand_id" (selectionChange)="get()">
					<mat-option>-- {{ 'remove' | i18n }} --</mat-option>
					<mat-option *ngFor="let c of brands" [value]="c._id">
						{{c.name[config.lang]}}
					</mat-option>
				</mat-select>
			</mat-form-field>
			<mat-form-field appearance="outline" class="form-group col-md-4">
				<mat-label>{{ 'supplier' | i18n }}</mat-label>
				<mat-select placeholder="{{ 'supplier' | i18n }}" [(ngModel)]="filter.supplier_id" (selectionChange)="get()">
					<mat-option>-- {{ 'remove' | i18n }} --</mat-option>
					<mat-option *ngFor="let c of suppliers" [value]="c._id">
						{{c.name[config.lang]}}
					</mat-option>
				</mat-select>
			</mat-form-field>
			<mat-form-field appearance="outline" class="form-group col-md-4">
				<mat-label>{{ 'category' | i18n }}</mat-label>
				<mat-select placeholder="{{ 'category' | i18n }}" [(ngModel)]="filter.category_id"
					(selectionChange)="loadRanks();get();">
					<mat-option>-- {{ 'remove' | i18n }} --</mat-option>
					<mat-option *ngFor="let c of categories" [value]="c._id">
						{{c.name[config.lang]}}
					</mat-option>
				</mat-select>
			</mat-form-field>
			<mat-form-field appearance="outline" class="form-group col-md-4">
				<mat-label>{{ 'rank' | i18n }}</mat-label>
				<mat-select placeholder="{{ 'rank' | i18n }}" [(ngModel)]="filter.rank_id" (selectionChange)="get();">
					<mat-option>-- {{ 'remove' | i18n }} --</mat-option>
					<mat-option *ngFor="let c of ranks" [value]="c._id">
						{{c.name[config.lang]}}
					</mat-option>
				</mat-select>
			</mat-form-field>
			<mat-form-field appearance="outline" class="form-group col-md-4">
				<mat-label>{{ 'feature' | i18n }}</mat-label>
				<mat-select placeholder="{{ 'feature' | i18n }}" [(ngModel)]="filter.feature_id" (selectionChange)="get()">
					<mat-option>-- {{ 'remove' | i18n }} --</mat-option>
					<mat-option *ngFor="let f of features" [value]="f._id">
						{{f.name[config.lang]}}
					</mat-option>
				</mat-select>
			</mat-form-field>
			<mat-form-field appearance="outline" class="form-group col-md-4">
				<mat-label>{{ 'out_of_stock' | i18n }}</mat-label>
				<mat-select placeholder="{{ 'out_of_stock' | i18n }}" [(ngModel)]="filter.out_of_stock_store_id"
					(selectionChange)="get();">
					<mat-option>-- {{ 'remove' | i18n }} --</mat-option>
					<mat-option *ngFor="let c of stores" [value]="c._id">
						{{c.name[config.lang]}}
					</mat-option>
				</mat-select>
			</mat-form-field>
			<mat-form-field appearance="outline" class="form-group col-md-4">
				<mat-label>{{ 'from_date' | i18n }}</mat-label>
				<input matInput [matDatepicker]="from_picker" [(ngModel)]="filter.from_date"
					placeholder="{{ 'from_date' | i18n }}" (keyup)="get()">
				<mat-datepicker-toggle matSuffix [for]="from_picker"></mat-datepicker-toggle>
				<mat-datepicker #from_picker></mat-datepicker>
			</mat-form-field>

			<mat-form-field appearance="outline" class="form-group col-md-4">
				<mat-label>{{ 'to_date' | i18n }}</mat-label>
				<input matInput [matDatepicker]="to_picker" [(ngModel)]="filter.to_date" placeholder="{{ 'to_date' | i18n }}"
					(keyup)="get()">
				<mat-datepicker-toggle matSuffix [for]="to_picker"></mat-datepicker-toggle>
				<mat-datepicker #to_picker></mat-datepicker>
			</mat-form-field>
			<!-- <mat-form-field appearance="outline" class="form-group col-md-4">
				<mat-select placeholder="{{ 'status' | i18n }}" (selectionChange)="get()" [(ngModel)]="filter.status">
					<mat-option>-- {{ 'remove' | i18n }} --</mat-option>
					<mat-option *ngFor="let c of order_status_list" [value]="c.key">
						{{ c.value }}
					</mat-option>
				</mat-select>
			</mat-form-field> -->
		</div>
	</mat-card>

	<mat-card *ngIf="importForm">
		<div class="form-row">
			<div appearance="outline" class="form-group col-md-6 mat-upload">
				<mat-label>{{'file' | i18n}}</mat-label>
				<input type="file" (change)="uploadAndImport($event.target.files)" />
				{{'upload_percent' | i18n}}: {{percentDone}}%
				<br />
			</div>
			<div class="form-group col-md-2">
				<mat-card *ngIf="is_csv_processing" style="display: flex; justify-content: center; align-items: center">
					<mat-progress-spinner color="success" mode="indeterminate">
					</mat-progress-spinner>
				</mat-card>
			</div>
			<div appearance="outline" class="form-group col-md-4 mat-upload">
				<mat-label>{{'is_update' | i18n}}</mat-label>
				<mat-slide-toggle [(ngModel)]="is_csv_update" [checked]="is_csv_update">
				</mat-slide-toggle>
			</div>
		</div>
	</mat-card>

	<mat-card *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center">
		<mat-progress-spinner color="primary" mode="indeterminate">
		</mat-progress-spinner>
	</mat-card>

	<table mat-table [dataSource]="items">

		<!-- Checkbox Column -->
		<ng-container matColumnDef="select">
			<th mat-header-cell *matHeaderCellDef>
				<mat-checkbox (change)="$event ? masterToggle() : null;"
					[checked]="selection.hasValue() && isAllSelected()"
					[indeterminate]="selection.hasValue() && !isAllSelected()">
				</mat-checkbox>
			</th>
			<td mat-cell *matCellDef="let row">
				<mat-checkbox (click)="$event.stopPropagation()"
					(change)="$event ? selection.toggle(row) : null;updateSelectionIds()"
					[checked]="selection.isSelected(row)">
				</mat-checkbox>
			</td>
		</ng-container>

		<!-- _id Column -->
		<ng-container matColumnDef="_id">
			<th mat-header-cell *matHeaderCellDef> # </th>
			<td mat-cell *matCellDef="let i"> <b [outerHTML]="i._id | _id"></b> </td>
		</ng-container>

		<!-- Name Column -->
		<ng-container matColumnDef="name">
			<th mat-header-cell *matHeaderCellDef> {{ 'name' | i18n }} </th>
			<td mat-cell *matCellDef="let i"> {{i.name[config.lang]}} </td>
		</ng-container>

		<!-- Price Column -->
		<ng-container matColumnDef="price">
			<th mat-header-cell *matHeaderCellDef> {{ 'price' | i18n }} </th>
			<td mat-cell *matCellDef="let i"> {{i.price}} </td>
		</ng-container>

		<!-- Created Column -->
		<ng-container matColumnDef="created">
			<th mat-header-cell *matHeaderCellDef> {{ 'created' | i18n }} </th>
			<td mat-cell *matCellDef="let i">{{ i.created }}</td>
		</ng-container>

		<!-- Status Column -->
		<ng-container matColumnDef="orders_count">
			<th mat-header-cell *matHeaderCellDef> {{ 'orders_count' | i18n }} </th>
			<td mat-cell *matCellDef="let i"> {{i.orders_count || "_"}} </td>
		</ng-container>

		<!-- Status Column -->
		<ng-container matColumnDef="status">
			<th mat-header-cell *matHeaderCellDef> {{ 'status' | i18n }} </th>
			<td mat-cell *matCellDef="let i"> {{i.status}} </td>
		</ng-container>

		<!-- Total Sells Column -->
		<ng-container matColumnDef="total_sells_price">
			<th mat-header-cell *matHeaderCellDef> {{ 'total_selles_price' | i18n }} </th>
			<td mat-cell *matCellDef="let i"> {{i.orders_count? i.orders_count * i.price : '-'}} </td>
		</ng-container>

		<!-- Tools Column -->
		<ng-container matColumnDef="tools">
			<th mat-header-cell *matHeaderCellDef></th>
			<td mat-cell *matCellDef="let i">
				<div class="btn-tools">
					<button mat-mini-fab color="primary" (click)="openDetails(i._id)" title="{{ 'details' | i18n }}">
						<mat-icon>info</mat-icon>
					</button>
					<button mat-mini-fab color="accent" (click)="openForm('add', i._id)" title="{{ 'addCopy' | i18n }}">
						<mat-icon>file_copy</mat-icon>
					</button>
					<button mat-mini-fab color="basic" (click)="openForm('edit', i._id)" title="{{ 'edit' | i18n }}">
						<mat-icon>edit</mat-icon>
					</button>
					<button mat-mini-fab color="warn" (click)="openDelete(i._id)" title="{{ 'delete' | i18n }}">
						<mat-icon>delete</mat-icon>
					</button>
					<a mat-mini-fab [href]="'/log/product/' + i._id">
						<mat-icon>receipt</mat-icon>
					</a>
				</div>
			</td>
		</ng-container>

		<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
		<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
	</table>
	<div *ngIf="total_sells_price" class="mt-5">{{ 'total_selles_price' | i18n }}: <strong>{{total_sells_price}}</strong></div>
	<mat-paginator showFirstLastButtons [length]="pagination.length" [pageSize]="pagination.pageSize"
		[pageSizeOptions]="pagination.pageSizeOptions" (page)="next($event)">
	</mat-paginator>
</mat-card>
