import { Component } from '@angular/core';
import { Reward, RewardError } from '../reward.model';
import { BaseForm } from 'src/app/@core/libraries/pages/base-form';
import { HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Category } from '../../category/category.model';
import { RewardFeature } from '../../reward_feature/reward_feature.model';

@Component({
	selector: 'app-form',
	templateUrl: './form.component.html',
	styleUrls: ['./form.component.scss']
})
export class FormComponent extends BaseForm {

	model = 'reward';
	item: Reward = new Reward();
	errors: RewardError = new RewardError();
	validate: RewardError = new RewardError();
	minDate: Date = new Date();
	features: RewardFeature[] = [];

	initialize() {
		this.loadFeatures();
	}

	loadFeatures() {
		this.http.get(`${environment.apiUrl}/reward_feature`, {
			params: new HttpParams()
				.set('filter', 'status=true')
				.set('sort', `name.${this.config.lang}=asc`)
				.set('limit', '5000')
		})
			.subscribe((response) => {
				this.features = response['results']['data'];
			});
	}

	getEmptyErrors() {
		return new RewardError();
	}

}
