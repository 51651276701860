import { ITable } from 'src/app/@core/libraries/interfaces/table';
import { IError } from 'src/app/@core/libraries/interfaces/error';

export class Review implements ITable {

	_id: String = '';
	name: String = '';
	rating: Number = 0;
	comment: String = '';
	member_id: String = '';
	supplier_id: String = '';
	device_token: String = '';
	status: Boolean = false;

}

export class ReviewError implements IError {

	_id: String = '';
	name: String = '';
	rating: String = '';
	comment: String = '';
	member_id: String = '';
	supplier_id: String = '';
	device_token: String = '';
	status: String = '';
}

export class SupplierFilter {
	supplier_id?: string = null;
}
