<h1 mat-dialog-title>{{ 'details' | i18n }}</h1>
<div mat-dialog-content *ngIf="item">
	<mat-card>
		<mat-card-content>
			<table class="table table-bordered">
				<tbody>
					<tr>
						<td>#</td>
						<td>{{ item._id }}</td>
					</tr>
					<tr>
						<td>{{ 'name' | i18n }}</td>
						<td>{{ item.name }}</td>
					</tr>
					<tr>
						<td>{{ 'email' | i18n }}</td>
						<td>{{ item.email }}</td>
					</tr>
					<tr>
						<td>{{ 'created' | i18n }}</td>
						<td>{{ item.created }}</td>
					</tr>
					<tr>
						<td>{{ 'modified' | i18n }}</td>
						<td>{{ item.modified }}</td>
					</tr>
					<tr>
						<td>{{ 'lastupdate_user_name' | i18n }}</td>
						<td>{{ item.lastupdate_user_name }}</td>
					</tr>
					<tr>
						<td>{{ 'status' | i18n }}</td>
						<td>{{ item.status }}</td>
					</tr>
				</tbody>
			</table>
		</mat-card-content>
	</mat-card>
</div>
<div mat-dialog-actions>
	<button mat-button color="warn" (click)="close()">{{ 'close' | i18n }}</button>
</div>
