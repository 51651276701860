import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseModelService } from 'src/app/@core/services/base-model.service';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root',
})


export class LogService extends BaseModelService {

	model: String = 'log';

	public getLogs(collection: String, id: String, page: number = 1, action: String = 'all'): any {
		let params = new HttpParams()
			.set('skip', page.toString())
			.set('limit', '1')
			.set('action', action.toString());

		return this.http.get(`${environment.apiUrl}/${this.model}/${collection}/${id}`, {
			params: params
		});
	}

}
