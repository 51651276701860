import { LanguagesObject } from '../../@core/data/languages-object';
import { ITable } from 'src/app/@core/libraries/interfaces/table';
import { IError } from 'src/app/@core/libraries/interfaces/error';

export class Report implements ITable {
	_id: String;

}

export class ReportError implements IError {

}

export class ReportFilter {
	is_sort?: boolean = false;
	active?: string = null;
	direction?: string = null;
}
