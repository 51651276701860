import { Injectable } from '@angular/core';
import { BaseModelService } from 'src/app/@core/services/base-model.service';
import { HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root',
})


export class ReportService extends BaseModelService {

	model: String = 'report';

	public getPointHistory(filter = {}, sort = 'asc', skip = 0, limit = 10): any {
		let params = new HttpParams()
			.set('sort', sort)
			.set('skip', (skip + 1).toString())
			.set('limit', limit.toString());
		const keys = Object.keys(filter);
		for (const k of keys) {
			if (!filter[k]) {
				continue;
			}
			params = params.append(`filter[${k}]`, filter[k]);
		}
		return this.http.get(`${environment.apiUrl}/${this.model}/point_history`, {
			params: params
		});
	}

	public getWalletHistory(filter = {}, sort = 'asc', skip = 0, limit = 10): any {
		let params = new HttpParams()
			.set('sort', sort)
			.set('skip', (skip + 1).toString())
			.set('limit', limit.toString());
		const keys = Object.keys(filter);
		for (const k of keys) {
			if (!filter[k]) {
				continue;
			}
			params = params.append(`filter[${k}]`, filter[k]);
		}
		return this.http.get(`${environment.apiUrl}/${this.model}/wallet_history`, {
			params: params
		});
	}

	public getMemberHistory(filter = {}, skip = 0, limit = 10): any {
		let params = new HttpParams()
			.set('skip', (skip + 1).toString())
			.set('limit', limit.toString());
		const keys = Object.keys(filter);
		for (const k of keys) {
			if (!filter[k]) {
				continue;
			}
			params = params.append(`filter[${k}]`, filter[k]);
		}
		return this.http.get(`${environment.apiUrl}/${this.model}/member_history`, {
			params: params
		});
	}

	public getProHistory(filter = {}, skip = 0, limit = 10): any {
		let params = new HttpParams()
			.set('skip', (skip + 1).toString())
			.set('limit', limit.toString());
		const keys = Object.keys(filter);
		for (const k of keys) {
			if (!filter[k]) {
				continue;
			}
			params = params.append(`filter[${k}]`, filter[k]);
		}
		return this.http.get(`${environment.apiUrl}/${this.model}/pro_history`, {
			params: params
		});
	}

	public getFailedPaymentsHistory(filter = {}, skip = 0, limit = 10): any {
		let params = new HttpParams()
			.set('skip', (skip + 1).toString())
			.set('limit', limit.toString());
		const keys = Object.keys(filter);
		for (const k of keys) {
			if (!filter[k]) {
				continue;
			}
			params = params.append(`filter[${k}]`, filter[k]);
		}
		return this.http.get(`${environment.apiUrl}/${this.model}/failed_payment_history`, {
			params: params
		});
	}

}
