import { Component } from '@angular/core';
import { Category, CategoryError } from '../category.model';
import { BaseForm } from 'src/app/@core/libraries/pages/base-form';
import { environment } from 'src/environments/environment';
import { HttpParams } from '@angular/common/http';
import { Store } from '../../store/store.model';
import { LanguagesObject } from 'src/app/@core/data/languages-object';
import { Supplier } from '../../supplier/supplier.model';
import * as moment from 'moment';

@Component({
	selector: 'app-form',
	templateUrl: './form.component.html',
	styleUrls: ['./form.component.scss']
})
export class FormComponent extends BaseForm {

	model = 'category';
	item: Category = new Category();
	errors: CategoryError = new CategoryError();
	validate: CategoryError = new CategoryError();
	allParents: Category[] = [];
	parents: Category[] = [];
	stores: Store[] = [];
	storesObj: any[] = [];
	categorynstore: Object = {
		'store_id': '',
		'sorting': 0,
		'status': false
	};
	validate_categorynstore: Object = {};
	categorynbill: Object = {
		'store_id': '',
		'sorting': 0
	};
	validate_categorynbill: Object = {};
	suppliers: Supplier[] = [];
	isDuplicateAction = false;
	limitCartProds = false;
	selectedSupplier?: Supplier;

	initialize() {
		if (this.data.action === 'add' && this.data.id !== '') {
			this.isDuplicateAction = true;
		}
		this.loadCategories();
		this.loadStores();
		this.loadSuppliers();
	}

	afterInit(){
		if (!this.item.picture_lang) {
			this.item.picture_lang = new LanguagesObject();;
		}

		if (!this.item.starts_at) {
			this.item.starts_at = 0;
		}

		if (!this.item.ends_at) {
			this.item.ends_at = 0;
		}

		this.supplierChangeHander();
	}

	loadCategories() {
		this.http.get(`${environment.apiUrl}/category`, {
			params: new HttpParams()
				.set('filter', 'status=true&parent_id=null')
				.set('sort', `name.${this.config.lang}=asc`)
				.set('limit', '5000')
		})
			.subscribe((response) => {
				this.allParents = response['results']['data'];
				if (this.item.supplier_id) {
					this.parents = this.allParents.filter(c => c.supplier_id == this.item.supplier_id);
				} else {
					this.parents = this.allParents.filter(c => !c.supplier_id);
				}
			});
	}

	loadStores() {
		this.http.get(`${environment.apiUrl}/store`, {
			params: new HttpParams()
				.set('filter', 'status=true')
				.set('sort', `name.${this.config.lang}=asc`)
				.set('limit', '999')
		})
			.subscribe((response) => {
				this.stores = response['results']['data'];
				for (const i of this.stores) {
					this.storesObj[i._id.toString()] = i.name[this.config.lang];
				}
			});
	}

	loadSuppliers() {
		this.http.get(`${environment.apiUrl}/supplier`, {
			params: new HttpParams()
				.set('filter', 'status=true')
				.set('sort', `name.${this.config.lang}=asc`)
				.set('limit', '999')
		})
			.subscribe((response) => {
				this.suppliers = response['results']['data'];
			});
	}

	add_categorynbill() {
		if (this.item.category_n_billArr === undefined) {
			this.item.category_n_billArr = [];
		}
		this.item.category_n_billArr.push(Object.assign({}, this.categorynbill));
		this.categorynbill = {};
	}

	add_categorynstore() {
		if (this.item.category_n_storeArr === undefined) {
			this.item.category_n_storeArr = [];
		}
		this.item.category_n_storeArr.push(Object.assign({}, this.categorynstore));
		this.categorynstore = {};
	}

	getBeforeSave() {
		const data = this.item;

		// bill
		const category_in_bill = data.category_n_billArr;
		if (data.category_n_billObj === undefined) {
			data.category_n_billObj = {};
		}
		if (category_in_bill) {
			for (const i of category_in_bill) {
				data.category_n_billObj[i['store_id']] = {
					'sorting': i['sorting']
				};
			}
		}
		// bill

		// store
		const category_in_store = data.category_n_storeArr;
		if (data.category_n_storeObj === undefined) {
			data.category_n_storeObj = {};
		}
		if (category_in_store) {
			for (const i of category_in_store) {
				data.category_n_storeObj[i['store_id']] = {
					'sorting': i['sorting'],
					'status': i['status'],
				};
			}
		}
		// store
		return data;
	}

	getEmptyErrors() {
		return new CategoryError();
	}

	updateCnB(store_id) {
		this.item.category_n_billArr = this.deleteFromObj(this.item.category_n_billArr, 'store_id', store_id);
	}

	updateCnS(store_id) {
		this.item.category_n_storeArr = this.deleteFromObj(this.item.category_n_storeArr, 'store_id', store_id);
	}

	supplierChangeHander() {
		if (this.allParents.length > 0) {
			if (this.item.supplier_id) {
				this.parents = this.allParents.filter(c => c.supplier_id == this.item.supplier_id);
			} else {
				this.parents = this.allParents.filter(c => !c.supplier_id);
			}
		}

		const supplier = this.item.supplier_id ? this.suppliers.find(s => s._id === this.item.supplier_id) : null;
		this.selectedSupplier = supplier;
	}

}
