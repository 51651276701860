<h1 mat-dialog-title>{{ data.action | i18n }}</h1>
<div mat-dialog-content>
	<mat-card>
		<mat-card-subtitle>{{ 'translated_details' | i18n }}</mat-card-subtitle>
		<mat-card-content>
			<mat-tab-group>
				<mat-tab *ngFor="let l of languages">
					<ng-template mat-tab-label>
						<i class="flag-icon flag-icon-{{ l.image }}"></i>
						{{ l.name }}
					</ng-template>
					<div class="col-12" dir="{{ l.direction }}">

						<mat-form-field appearance="outline" class="form-group col-md-12">
							<mat-label>{{ 'name' | i18n }} ( {{ l.name }} )
							</mat-label>
							<input matInput [(ngModel)]="item.name[l.code]" [errorStateMatcher]='validate.name[l.code]'
								placeholder="{{ 'name' | i18n }}" />
							<mat-error>{{ errors.name[l.code] }}</mat-error>
						</mat-form-field>

						<mat-form-field appearance="outline" class="form-group col-md-12">
							<mat-label>{{ 'content' | i18n }} ( {{ l.name }} )
							</mat-label>
							<textarea matInput [(ngModel)]="item.content[l.code]" [errorStateMatcher]='validate?.content[l.code]'
								placeholder="{{ 'content' | i18n }}"></textarea>
							<mat-error>{{ errors.content[l.code] }}</mat-error>
						</mat-form-field>

					</div>
				</mat-tab>
			</mat-tab-group>
		</mat-card-content>
	</mat-card>
	<mat-divider></mat-divider>

	<mat-card>
		<mat-card-subtitle>{{ 'other_details' | i18n }}</mat-card-subtitle>
		<mat-card-content>
			<div class="row">
				<mat-form-field appearance="outline" class="form-group col-md-12">
					<mat-label>{{ 'link' | i18n }}</mat-label>
					<input matInput [(ngModel)]="item.link" [errorStateMatcher]='validate.link'
						placeholder="{{ 'link' | i18n }}" />
					<mat-error>{{ errors.link }}</mat-error>
				</mat-form-field>
			</div>

			<div class="form-row">
				<mat-radio-group [(ngModel)]="item.show_in_ticker">
					<mat-label>{{ 'show_in_ticker' | i18n }}</mat-label>
					<mat-radio-button [value]="true">{{ 'true' | i18n }}</mat-radio-button>
					<mat-radio-button [value]="false">{{ 'false' | i18n }}</mat-radio-button>
				</mat-radio-group>
				<mat-error>{{ errors.show_in_ticker }}</mat-error>
			</div>

			<div class="form-row">
				<mat-radio-group [(ngModel)]="item.show_in_featured_categories">
					<mat-label>{{ 'show_in_featured_categories' | i18n }}</mat-label>
					<mat-radio-button [value]="true">{{ 'true' | i18n }}</mat-radio-button>
					<mat-radio-button [value]="false">{{ 'false' | i18n }}</mat-radio-button>
				</mat-radio-group>
				<mat-error>{{ errors.show_in_featured_categories }}</mat-error>
			</div>

			<mat-form-field appearance="outline" class="form-group col-md-6">
				<mat-label>{{ 'sorting' | i18n }}</mat-label>
				<input type="number" matInput [(ngModel)]="item.sorting" [errorStateMatcher]='validate.sorting'
					placeholder="{{ 'sorting' | i18n }}" />
				<mat-error>{{ errors.sorting }}</mat-error>
			</mat-form-field>

			<div class="form-row">
				<mat-form-field>
					<mat-label>{{ 'status' | i18n }}</mat-label>
					<mat-select placeholder="{{ 'status' | i18n }}" [(ngModel)]='item.status'
						[errorStateMatcher]='validate.status' name="status">
						<mat-option [value]="true">{{ 'yes' | i18n }}</mat-option>
						<mat-option [value]="false">{{ 'no' | i18n }}</mat-option>
					</mat-select>
					<mat-error>{{ errors.status }}</mat-error>
				</mat-form-field>
			</div>
		</mat-card-content>
	</mat-card>

</div>
<div mat-dialog-actions>
	<button mat-flat-button color="primary" (click)="save()">{{ 'save' | i18n }}</button>
	<button mat-flat-button color="warn" (click)="close()">{{ 'close' | i18n }}</button>
</div>
