import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
	MatButtonModule,
	MatCardModule,
	MatDialogModule,
	MatDividerModule,
	MatGridListModule,
	MatFormFieldModule,
	MatIconModule,
	MatInputModule,
	MatListModule,
	MatPaginatorModule,
	MatProgressSpinnerModule,
	MatRadioModule,
	MatSelectModule,
	MatSortModule,
	MatTableModule,
	MatTabsModule,
	MatToolbarModule,
	MatTooltipModule,
	MatMenuModule,
	MatSidenavModule,
	MatCheckboxModule,
	MatAutocompleteModule,
	MatSlideToggleModule,
	MatDatepickerModule,
	MatNativeDateModule,
	MatSnackBarModule,
	MatExpansionModule
} from '@angular/material';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

import { I18nPipe, IdPipe, DatePipe } from '../pipes';
const PIPES = [
	DatePipe,
	I18nPipe,
	IdPipe,
];
const MATS = [
	MatAutocompleteModule,
	MatButtonModule,
	MatCardModule,
	MatCheckboxModule,
	MatDatepickerModule,
	MatDialogModule,
	MatDividerModule,
	MatExpansionModule,
	MatFormFieldModule,
	MatGridListModule,
	MatIconModule,
	MatInputModule,
	MatListModule,
	MatMenuModule,
	MatNativeDateModule,
	MatPaginatorModule,
	MatProgressSpinnerModule,
	MatRadioModule,
	MatSelectModule,
	MatSidenavModule,
	MatSlideToggleModule,
	MatSnackBarModule,
	MatSortModule,
	MatTabsModule,
	MatToolbarModule,
	MatTableModule,
	MatTooltipModule
];

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		CKEditorModule,
		...MATS
	],
	declarations: [...PIPES],
	exports: [...PIPES, ...MATS, FormsModule, ReactiveFormsModule, CKEditorModule],
})

export class ThemeModule {
	static forRoot(): ModuleWithProviders<ThemeModule> {
		return <ModuleWithProviders<ThemeModule>>{
			ngModule: ThemeModule,
		};
	}
}
