<h1 mat-dialog-title>{{ data.action | i18n }}</h1>
<div mat-dialog-content>
	<mat-card>
		<mat-card-subtitle>{{ 'details' | i18n }}</mat-card-subtitle>
		<mat-card-content>
			<div class="row">

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'name' | i18n }}</mat-label>
					<input matInput [(ngModel)]="item.name" [errorStateMatcher]='validate.name'
						placeholder="{{ 'name' | i18n }}" />
					<mat-error>{{ errors.name }}</mat-error>
				</mat-form-field>

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'rating' | i18n }}</mat-label>
					<input matInput type="number" min="1" max="5" [(ngModel)]="item.rating" [errorStateMatcher]='validate.rating'
						placeholder="{{ 'rating' | i18n }}" />
					<mat-error>{{ errors.rating }}</mat-error>
				</mat-form-field>

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'comment' | i18n }}</mat-label>
					<input matInput [(ngModel)]="item.comment" [errorStateMatcher]='validate.comment'
						placeholder="{{ 'comment' | i18n }}" />
					<mat-error>{{ errors.comment }}</mat-error>
				</mat-form-field>

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'supplier' | i18n }}</mat-label>
					<mat-select placeholder="{{ 'supplier' | i18n }}" [(ngModel)]="item.supplier_id"
						[errorStateMatcher]='validate.supplier_id'>
						<mat-option>-- {{ 'remove' | i18n }} --</mat-option>
						<mat-option *ngFor="let c of suppliers" [value]="c._id">
							{{ c.name[config.lang] }}
						</mat-option>
					</mat-select>
				</mat-form-field>

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'status' | i18n }}</mat-label>
					<mat-select placeholder="{{ 'status' | i18n }}" [(ngModel)]='item.status'
						[errorStateMatcher]='validate.status' name="status">
						<mat-option [value]="true">{{ 'yes' | i18n }}</mat-option>
						<mat-option [value]="false">{{ 'no' | i18n }}</mat-option>
					</mat-select>
					<mat-error>{{ errors.status }}</mat-error>
				</mat-form-field>

			</div>
		</mat-card-content>
	</mat-card>

</div>
<div mat-dialog-actions>
	<button mat-flat-button color="primary" (click)="save()">{{ 'save' | i18n }}</button>
	<button mat-flat-button color="warn" (click)="close()">{{ 'close' | i18n }}</button>
</div>
