<mat-card class="mat-elevation-z8">

	<mat-toolbar>
		<mat-toolbar-row>
			<a href="{{ csv_url }}" mat-raised-button color="link">
				<mat-icon>cloud_download</mat-icon>
				{{ 'export' | i18n }}
			</a>
			<span class="spacer"></span>
			<h2 class="title"> {{ 'orders_by_product' | i18n }} </h2>
		</mat-toolbar-row>
	</mat-toolbar>

	<mat-card *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center">
		<mat-progress-spinner color="primary" mode="indeterminate">
		</mat-progress-spinner>
	</mat-card>

	<table mat-table [dataSource]="items" matSort class="mat-elevation-z8" (matSortChange)="sortData($event)">

		<!-- Checkbox Column -->
		<ng-container matColumnDef="select">
			<th mat-header-cell *matHeaderCellDef>
				<mat-checkbox (change)="$event ? masterToggle() : null;"
					[checked]="selection.hasValue() && isAllSelected()"
					[indeterminate]="selection.hasValue() && !isAllSelected()">
				</mat-checkbox>
			</th>
			<td mat-cell *matCellDef="let row">
				<mat-checkbox (click)="$event.stopPropagation()"
					(change)="$event ? selection.toggle(row) : null;updateSelectionIds()"
					[checked]="selection.isSelected(row)">
				</mat-checkbox>
			</td>
        </ng-container>

		<!-- _id Column -->
		<ng-container matColumnDef="_id">
			<th mat-header-cell *matHeaderCellDef> # </th>
			<td mat-cell *matCellDef="let i"> {{ i._id }} </td>
		</ng-container>

		<!-- Product Column -->
		<ng-container matColumnDef="product">
			<th mat-header-cell *matHeaderCellDef> {{ 'product' | i18n }} </th>
			<td mat-cell *matCellDef="let i"> {{ i['product']['name'][config.lang] }} </td>
		</ng-container>

		<!-- Orders count Column -->
		<ng-container matColumnDef="orders_count">
			<th mat-header-cell *matHeaderCellDef mat-sort-header="orders_count"> {{ 'orders_count' | i18n }} </th>
			<td mat-cell *matCellDef="let i"> {{i.orders_count}} </td>
		</ng-container>

		<!-- Count total Column -->
		<ng-container matColumnDef="count_total">
			<th mat-header-cell *matHeaderCellDef mat-sort-header="count_total"> {{ 'count_total' | i18n }} </th>
			<td mat-cell *matCellDef="let i"> {{i.count_total}} </td>
		</ng-container>

		<!-- Order Total Column -->
		<ng-container matColumnDef="orders_total">
			<th mat-header-cell *matHeaderCellDef mat-sort-header="orders_total"> {{ 'orders_total' | i18n }} </th>
			<td mat-cell *matCellDef="let i"> {{ i.orders_total }} </td>
		</ng-container>

		<!-- Tools Column -->
		<ng-container matColumnDef="tools">
			<th mat-header-cell *matHeaderCellDef></th>
			<td mat-cell *matCellDef="let i">
				<div class="btn-tools">

				</div>
			</td>
		</ng-container>

		<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
		<tr mat-row *matRowDef="let row; columns: displayedColumns;" class="status_{{row.status}}"></tr>
	</table>

	<mat-paginator showFirstLastButtons [length]="pagination.length" [pageSize]="pagination.pageSize"
		[pageSizeOptions]="pagination.pageSizeOptions" (page)="next($event)">
	</mat-paginator>

</mat-card>
