import { LanguagesObject } from '../../@core/data/languages-object';
import { ITable } from 'src/app/@core/libraries/interfaces/table';
import { IError } from 'src/app/@core/libraries/interfaces/error';

export class Offer implements ITable {

	_id: String = '';
	name: LanguagesObject = new LanguagesObject();
	description: LanguagesObject = new LanguagesObject();
	min_amount: Number;
	target_amount: Number;
	type: 'free_product' |'free_shipping';
	product_sku: String;
	products: String[] = [];
	ignore_target_amount: Boolean = false;
	expires_at: Date;
	status: Boolean = false;
}

export class OfferError implements IError {

	_id: String = '';
	name: LanguagesObject = new LanguagesObject();
	description: LanguagesObject = new LanguagesObject();
	min_amount: String = '';
	target_amount: String = '';
	type: String = '';
	product_sku: String = '';
	products: String = '';
	ignore_target_amount: String = '';
	expires_at: String = '';
	status: String = '';

}


export class GiveawayEntriesFilter {
	is_sort?: boolean = false;
	active?: string = null;
	direction?: string = null;
}
