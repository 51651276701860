import { Component, OnInit } from '@angular/core';
import { BaseIndex } from 'src/app/@core/libraries/pages/base-index';
import { DetailsComponent } from '../details/details.component';
import { Store } from '../../store/store.model';
import { Supplier } from '../../supplier/supplier.model';
import { OrderFilter } from '../order.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { OrderService } from '../order.service';
import { MatDialog } from '@angular/material';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-issues',
  templateUrl: './issues.component.html',
  styleUrls: ['./issues.component.scss']
})
export class IssuesComponent extends BaseIndex {

  model = 'order';
  displayedColumns: string[] = ['select', '_id', 'name', 'solved', 'created', 'status', 'tools'];
  theDetailsComponent = DetailsComponent;
  theFormComponent = null;
  searchForm: Boolean = false;
  stores: Store[] = [];
  suppliers: Supplier[] = [];
  filter: OrderFilter = new OrderFilter();

  constructor(protected theService: OrderService,
    public http: HttpClient,
    protected router: Router, protected dialog: MatDialog) {
    super(theService, router, dialog);
    this.filter.issues = 'true';
    this.loadStores();
    this.loadSuppliers();
  }

  afterCloseDetails() {
    this.filter.issues = 'true';
    this.get();
  }

  loadStores() {
    this.http.get(`${environment.apiUrl}/store`, {
      params: new HttpParams()
        .set('filter', 'status=true')
        .set('sort', `name.${this.config.lang}=asc`)
        .set('limit', '999')
    })
      .subscribe((response) => {
        this.stores = response['results']['data'];
      });
  }

  loadSuppliers() {
    this.http.get(`${environment.apiUrl}/supplier`, {
      params: new HttpParams()
        .set('filter', 'status=true')
        .set('sort', `name.${this.config.lang}=asc`)
        .set('limit', '999')
    })
      .subscribe((response) => {
        this.suppliers = response['results']['data'];
      });
  }

  setSearchForm() {
    this.searchForm = !this.searchForm;
  }

}
