import { Component, OnInit } from '@angular/core';
import { BaseIndex } from 'src/app/@core/libraries/pages/base-index';
import { OrderService } from '../order.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { ITable } from 'src/app/@core/libraries/interfaces/table';
import { SelectionModel } from '@angular/cdk/collections';
import { OrderFilter } from '../order.model';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/@core/services/auth.service';

@Component({
  selector: 'app-by-product',
  templateUrl: './by-product.component.html',
  styleUrls: ['./by-product.component.scss']
})
export class ByProductComponent extends BaseIndex {

	model = 'order';
	displayedColumns: string[] = ['select', '_id', 'product', 'orders_count', 'count_total', 'orders_total', 'tools'];
	theDetailsComponent = null;
	theFormComponent = null;
	searchForm: Boolean = false;
	filter: OrderFilter = new OrderFilter();
	csv_url: string;

	constructor(protected theService: OrderService,
		public auth: AuthService,
		protected router: Router, protected dialog: MatDialog) {
		super(theService, router, dialog);
		const token = `${this.auth.getToken()}`;
		this.csv_url = `${environment.apiUrl}/order/by_product?export=true&token=${token}`;
  }

  ngOnInit(): void {
    this.get();
  }

	get() {
		this.isLoading = true;

		if (this.sort_data == true) {
			this.filter.is_sort = this.sort_data;
			this.filter.active = this.active;
			this.filter.direction = this.direction;
		}

		this.theService.getByProduct(this.filter, '', this.pagination.pageIndex, this.pagination.pageSize).subscribe((response) => {
			this.items = response.results.data;
			this.dataSource.data = response.results.data;
			this.pagination.length = response.results.total;
			this.selection = new SelectionModel<ITable>(true, []);
			this.SelectionIds = [];
			this.isLoading = false;
		});
	}

	afterCloseDetails() {
		this.get();
	}

	setSearchForm() {
		this.searchForm = !this.searchForm;
	}

}
