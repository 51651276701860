<mat-card *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center">
	<mat-progress-spinner color="primary" mode="indeterminate">
	</mat-progress-spinner>
</mat-card>
<mat-card *ngIf="!isLoading">
	<mat-card-title>{{ 'settings' | i18n }}</mat-card-title>
	<mat-card-content>
		<mat-card>
			<mat-card-subtitle>{{ 'settings_order' | i18n }}</mat-card-subtitle>
			<mat-card-content>

				<div class="row">

					<mat-form-field appearance="outline" class="form-group col-md-6">
						<mat-label>{{ 'orders.delay_delivery_times' | i18n }}</mat-label>
						<mat-select placeholder="{{ 'orders.delay_delivery_times' | i18n }}"
							[(ngModel)]='item.orders.delay_delivery_times' [errorStateMatcher]='validate.orders.delay_delivery_times'
							name="orders.delay_delivery_times">
							<mat-option [value]="true">{{ 'yes' | i18n }}</mat-option>
							<mat-option [value]="false">{{ 'no' | i18n }}</mat-option>
						</mat-select>
						<mat-error>{{ errors.orders.delay_delivery_times }}</mat-error>
					</mat-form-field>

					<mat-form-field appearance="outline" class="form-group col-md-6">
						<mat-label>{{ 'orders.delay_delivery_times_days' | i18n }}</mat-label>
						<input type="number" matInput [(ngModel)]="item.orders.delay_delivery_times_days"
							[errorStateMatcher]='validate.orders.delay_delivery_times_days'
							placeholder="{{ 'orders.delay_delivery_times_days' | i18n }}" />
						<mat-error>{{ errors.orders.delay_delivery_times_days }}</mat-error>
					</mat-form-field>

				</div>
				<div class="row">

					<mat-card>
						<mat-card-subtitle>{{ 'delivery_times' | i18n }}</mat-card-subtitle>
						<mat-card-content>

							<mat-accordion *ngFor="let d of days">
								<mat-expansion-panel>
									<mat-expansion-panel-header>
										<mat-panel-title>
											{{ d.text }}
										</mat-panel-title>
										<mat-panel-description>
										</mat-panel-description>
									</mat-expansion-panel-header>

									<div class="container">
										<div class="row">
											<div class="col-md-6" *ngFor="let p of times">
												<div class="row">
													<div class="col-md-3">
														{{ p.text }}
													</div>
													<div class="col-md-3">
														<mat-checkbox [(ngModel)]="item.orders.available_delivery_times[d.id][p.id]['is_available']">
															{{ 'is_available' | i18n }}</mat-checkbox>
													</div>
													<div class="col-md-6">
														<mat-form-field appearance="outline" class="form-group">
															<mat-label>{{ 'max_orders' | i18n }}</mat-label>
															<input type="number" matInput
																[(ngModel)]="item.orders.available_delivery_times[d.id][p.id]['max_orders']"
																placeholder="{{ 'max_orders' | i18n }}" />
														</mat-form-field>
													</div>
												</div>
											</div>
										</div>
									</div>


								</mat-expansion-panel>
							</mat-accordion>

						</mat-card-content>
					</mat-card>


				</div>

			</mat-card-content>
		</mat-card>

		<mat-divider></mat-divider>

	</mat-card-content>
	<mat-card-footer>
		<div mat-dialog-actions class="col-6 col-offset-3">
			<button mat-flat-button color="primary" class="btn-block" (click)="save()">{{ 'save' | i18n }}</button>
		</div>
		<br class="my-5" />
	</mat-card-footer>
</mat-card>
