import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material";
import { SupplierFeatureService } from "./supplier_feature.service";
import { DetailsComponent } from "./details/details.component";
import { FormComponent } from "./form/form.component";
import { BaseIndex } from "src/app/@core/libraries/pages/base-index";
import { AuthService } from "src/app/@core/services/auth.service";
import { environment } from "src/environments/environment";

@Component({
	selector: "app-supplier_feature",
	templateUrl: "./supplier_feature.component.html",
	styleUrls: ["./supplier_feature.component.scss"],
})
export class SupplierFeatureComponent extends BaseIndex {
	model = "supplier_feature";
	displayedColumns: string[] = [
		"select",
		"_id",
		"name",
		"created",
		"status",
		"tools",
	];
	theDetailsComponent = DetailsComponent;
	theFormComponent = FormComponent;
	csv_url: string;

	constructor(
		protected theService: SupplierFeatureService,
		protected router: Router,
		protected dialog: MatDialog,
		public auth: AuthService
	) {
		super(theService, router, dialog);
		const token = `${this.auth.getToken()}`;
		this.csv_url = `${environment.apiUrl}/product/export_csv?token=${token}`;
	}
}
