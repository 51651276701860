import { Component } from '@angular/core';
import { Faq, FaqError } from '../faq.model';
import { BaseForm } from 'src/app/@core/libraries/pages/base-form';
import { environment } from 'src/environments/environment';
import { HttpParams } from '@angular/common/http';
import { Store } from '../../store/store.model';

@Component({
	selector: 'app-form',
	templateUrl: './form.component.html',
	styleUrls: ['./form.component.scss']
})
export class FormComponent extends BaseForm {

	model = 'faq';
	item: Faq = new Faq();
	errors: FaqError = new FaqError();
	validate: FaqError = new FaqError();
	parents: Faq[] = [];

	initialize() {
		this.loadFaqs();
	}

	loadFaqs() {
		this.http.get(`${environment.apiUrl}/faq`, {
			params: new HttpParams()
				.set('filter', 'status=true&parent_id=null')
				.set('sort', `name.${this.config.lang}=asc`)
				.set('limit', '999')
		})
			.subscribe((response) => {
				this.parents = response['results']['data'];
			});
	}

	getEmptyErrors() {
		return new FaqError();
	}

}
