import { Component, OnInit } from '@angular/core';
import { BaseDetails } from 'src/app/@core/libraries/pages/base-details';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';

@Component({
	selector: 'app-bill',
	templateUrl: './bill.component.html',
	styleUrls: ['./bill.component.scss']
})
export class BillComponent implements OnInit {

	item: any;
	isLoading: boolean;
	supplier_ids: Array<String> = [];

	public readonly config = {
		lang: localStorage.getItem('lang') || 'en',
	};
	public settings: any;
	public readonly environment: Object = environment;

	constructor(public http: HttpClient, private route: ActivatedRoute) {
	}

	ngOnInit(): void {
		this.settings = JSON.parse(localStorage.getItem('app.settings'));
		this.isLoading = true;
		this.http.get(`${environment.apiUrl}/order/${this.route.snapshot.params['id']}`).subscribe((response: any) => {
			this.item = response.results;
			this.afterInit();
			this.isLoading = false;
		});
	}

	afterInit() {
		this.supplier_ids = this.item && this.item['products'] ? Object.keys(this.item['products']) : [];
	}

}
