import { Component } from '@angular/core';
import { Adminmember, AdminmemberError } from '../adminmember.model';
import { BaseForm } from 'src/app/@core/libraries/pages/base-form';
import { Country } from '../../country/country.model';
import { environment } from 'src/environments/environment';
import { HttpParams } from '@angular/common/http';
import { City } from '../../city/city.model';
import { Pages } from '../../../@core/data/pages';

@Component({
	selector: 'app-form',
	templateUrl: './form.component.html',
	styleUrls: ['./form.component.scss']
})
export class FormComponent extends BaseForm {

	model = 'adminmember';
	item: Adminmember = new Adminmember();
	errors: AdminmemberError = new AdminmemberError();
	validate: AdminmemberError = new AdminmemberError();
	countries: Country[] = [];
	cities: City[] = [];
	pages = [];

	initialize() {
		this.loadCountries();
		this.loadCities();
		this.loadPages();
	}

	afterInit() {
		this.item.password = '';
	}

	loadCountries() {
		this.http.get(`${environment.apiUrl}/country`, {
			params: new HttpParams()
				.set('filter', 'status=true')
				.set('sort', `name.${this.config.lang}=asc`)
				.set('limit', '999')
		})
			.subscribe((response) => {
				this.countries = response['results']['data'];
			});
	}

	loadCities() {
		this.http.get(`${environment.apiUrl}/city`, {
			params: new HttpParams()
				.set('filter', `status=true`)
				.set('sort', `name.${this.config.lang}=asc`)
				.set('limit', '999')
		})
			.subscribe((response) => {
				this.cities = response['results']['data'];
			});
	}

	loadPages() {
		this.pages = Pages.filter((p) => p.id);
	}

	getEmptyErrors() {
		return new AdminmemberError();
	}

}
