import { IError } from 'src/app/@core/libraries/interfaces/error';
import { ITable } from 'src/app/@core/libraries/interfaces/table';

export class Issue implements ITable {
	_id: String;
	order_id: String;
	employee_name?: string = null;
	employee_no?: string = null;
	customer_no?: string = null;
	issue_type?: string = null;
	details?: string = null;
	solved: boolean = false;
}

export class IssueError implements IError {
	order_id: String = '';
	employee_name: String = '';
	employee_no: String = '';
	customer_no: String = '';
	issue_type: String = '';
	details: String = '';
	solved: String = '';
}