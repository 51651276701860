<div id="invoice" *ngIf="item">
	<div class="toolbar d-print-none">
		<div class="text-right">
			<button id="printInvoice" class="btn btn-primary" onclick="window.print()">
				<i class="fa fa-print"></i>
				{{ 'print' | i18n }}
			</button>
		</div>
		<hr>
	</div>
	<div class="invoice overflow-auto">
		<div style="min-width: 600px">
			<header>
				<div class="row">
					<div class="col">
						<img id="logo" src="{{ environment.logoUrl }}" data-holder-rendered="true" />
					</div>
					<div class="col company-details">
						<h2 class="name">
							{{ settings['site_name'][config.lang] }}
						</h2>
						<div>{{ settings['phones'].join('-') }}</div>
						<div>{{ settings['email'] }}</div>
					</div>
				</div>
			</header>
			<main>
				<div class="row contacts">
					<div class="col invoice-to">
						<div class="text-gray-light">{{ 'invoice_to' | i18n }}:</div>
						<h6 class="to">{{ item['user_data']['fullname'] }}</h6>
						<div class="address">
							{{ 'widget' | i18n }} :
							{{ item['user_data']['address']['widget'] }},
							{{ 'street' | i18n }} :
							{{ item['user_data']['address']['street'] }},
							{{ 'gada' | i18n }} :
							{{ item['user_data']['address']['gada'] }},
							{{ 'house' | i18n }} :
							{{ item['user_data']['address']['house'] }},
							{{ 'city' | i18n }} :
							{{ item['user_data']['address']['city_name'] }}
						</div>
						<div class="email">
							<a
								href="mailto:{{ item['user_data']['email'] }}">{{ item['user_data']['email'] }}</a>
						</div>
					</div>
					<div class="col invoice-details">
						<h6 class="invoice-id">
							{{ 'invoice_no' | i18n }}
							<small>{{ item.order_id }}</small>
						</h6>
						<div class="date">{{ 'date_of_invoice' | i18n }}: {{ item['created'] }}</div>
						<div class="text-info" *ngIf="item['payment_details'] && item['payment_details']['ref']">
							{{ 'knet_ref' | i18n }}:
							{{ item['payment_details']['ref'] }}
						</div>
					</div>
				</div>
				<table>
					<thead>
						<tr>
							<th>#</th>
							<th class="text-left">{{ 'name' | i18n }}</th>
							<th class="text-right">{{ 'price' | i18n }}</th>
							<th class="text-right">{{ 'quantity' | i18n }}</th>
							<th class="text-right">{{ 'total' | i18n }}</th>
						</tr>
					</thead>
					<tbody *ngFor="let s of supplier_ids">
						<tr *ngFor="let i of item.products[s]; let idx = index">
							<td class="no">{{ idx + 1 }}</td>
							<td class="text-left">
								<h3>
									<a href="javascript:void(0)">
										{{ i.name[config.lang] }}
									</a>
								</h3>
								<a href="javascript:void(0)">
									{{ i.barcode }}
								</a>
								<br />
								<a href="javascript:void(0)">
									{{ i.supplier && i.supplier[config.lang] || settings['site_name'][config.lang] }}
								</a>,

								{{ 'delivery_at' | i18n }}

								{{ i.delivery_time || item['delivery_time'] }}

							</td>
							<td class="unit">{{ i.price }}</td>
							<td class="qty">{{ i.quantity }}</td>
							<td class="total">{{ i.price * i.quantity }}</td>
						</tr>
					</tbody>
					<tfoot>
						<tr>
							<td colspan="2"></td>
							<td colspan="2">{{ 'subtotal' | i18n }}</td>
							<td>{{ item.subtotal }}</td>
						</tr>
						<tr>
							<td colspan="2"></td>
							<td colspan="2">{{ 'shipping_cost' | i18n }}</td>
							<td>{{ item.shipping_cost }}</td>
						</tr>
						<tr>
							<td colspan="2"></td>
							<td colspan="2">{{ 'discount_value' | i18n }}</td>
							<td>{{ item.coupon.value }}</td>
						</tr>
						<tr>
							<td colspan="2"></td>
							<td colspan="2">{{ 'discount_by_wallet_value' | i18n }}</td>
							<td>{{ item.discount_by_wallet_value }}</td>
						</tr>
						<tr>
							<td colspan="2"></td>
							<td colspan="2">{{ 'total' | i18n }}</td>
							<td>{{ item.total }}</td>
						</tr>
					</tfoot>
				</table>
			</main>
			<footer>
			</footer>
		</div>
		<!--DO NOT DELETE THIS div. IT is responsible for showing footer always at the bottom-->
		<div></div>
	</div>
</div>
