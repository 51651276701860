<mat-card class="mat-elevation-z8">

	<mat-toolbar>
		<mat-toolbar-row>
			<!-- <button *ngIf="!isClaimed" mat-raised-button color="link" (click)="getRandomeEntry()">
				{{ 'get_random_entry' | i18n }}
			</button> -->
			<button mat-raised-button color="link" (click)="setSearchForm()">
				<mat-icon>search</mat-icon>
				{{ 'search' | i18n }}
			</button>
			<!-- <a href="{{ csv_url }}" mat-raised-button color="link">
				<mat-icon>cloud_download</mat-icon>
				{{ 'export' | i18n }}
			</a> -->
			<span class="spacer"></span>
			<h2 class="title"> {{ 'members' | i18n }} </h2>
		</mat-toolbar-row>
	</mat-toolbar>

	<mat-card *ngIf="searchForm">
		<div class="form-row">

			<mat-form-field appearance="outline" class="form-group col-md-4">
				<mat-select placeholder="{{ 'rewarded' | i18n }}" (selectionChange)="get()" [(ngModel)]="filter.rewarded">
					<mat-option>-- {{ 'remove' | i18n }} --</mat-option>
					<mat-option [value]="true">{{ 'yes' | i18n }}</mat-option>
					<mat-option [value]="false">{{ 'no' | i18n }}</mat-option>
				</mat-select>
			</mat-form-field>

			<mat-form-field appearance="outline" class="form-group col-md-4">
				<mat-label>{{ 'from_date' | i18n }}</mat-label>
				<input matInput [matDatepicker]="from_picker" [(ngModel)]="filter.from_date"
					placeholder="{{ 'from_date' | i18n }}" (keyup)="get()">
				<mat-datepicker-toggle matSuffix [for]="from_picker"></mat-datepicker-toggle>
				<mat-datepicker #from_picker></mat-datepicker>
			</mat-form-field>

			<mat-form-field appearance="outline" class="form-group col-md-4">
				<mat-label>{{ 'to_date' | i18n }}</mat-label>
				<input matInput [matDatepicker]="to_picker" [(ngModel)]="filter.to_date" placeholder="{{ 'to_date' | i18n }}"
					(keyup)="get()">
				<mat-datepicker-toggle matSuffix [for]="to_picker"></mat-datepicker-toggle>
				<mat-datepicker #to_picker></mat-datepicker>
			</mat-form-field>
		</div>
	</mat-card>

	<!-- <mat-card *ngIf="searchForm">
		<div class="form-row">
			<mat-form-field appearance="outline" class="form-group col-md-4">
				<mat-label>{{ 'mobile' | i18n }}</mat-label>
				<input matInput [(ngModel)]="filter.mobile" placeholder="{{ 'mobile' | i18n }}" (keyup)="get()" />
			</mat-form-field>

		</div>
	</mat-card> -->

	<mat-card *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center">
		<mat-progress-spinner color="primary" mode="indeterminate">
		</mat-progress-spinner>
	</mat-card>

	<table mat-table [dataSource]="items" matSort (matSortChange)="sortData($event)">

		<!-- Checkbox Column -->
		<!-- <ng-container matColumnDef="select">
			<th mat-header-cell *matHeaderCellDef>
				<mat-checkbox (change)="$event ? masterToggle() : null;" [checked]="selection.hasValue() && isAllSelected()"
					[indeterminate]="selection.hasValue() && !isAllSelected()">
				</mat-checkbox>
			</th>
			<td mat-cell *matCellDef="let row">
				<mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null;updateSelectionIds()"
					[checked]="selection.isSelected(row)">
				</mat-checkbox>
			</td>
		</ng-container> -->

		<!-- _id Column -->
		<!-- <ng-container matColumnDef="_id">
			<th mat-header-cell *matHeaderCellDef> # </th>
			<td mat-cell *matCellDef="let i"> <b [outerHTML]="i._id | _id"></b> </td>
		</ng-container> -->

		<!-- FullName Column -->
		<ng-container matColumnDef="fullname">
			<th mat-header-cell *matHeaderCellDef> {{ 'fullname' | i18n }} </th>
			<td mat-cell *matCellDef="let i"> {{ i.fullname }} </td>
		</ng-container>

		<!-- Mobile Column -->
		<ng-container matColumnDef="mobile">
			<th mat-header-cell *matHeaderCellDef> {{ 'mobile' | i18n }} </th>
			<td mat-cell *matCellDef="let i"> {{ i.mobile }} </td>
		</ng-container>

		<!-- Entry date Column -->
		<!-- <ng-container matColumnDef="entry_date">
			<th mat-header-cell *matHeaderCellDef> {{ 'entry_date' | i18n }} </th>
			<td mat-cell *matCellDef="let i"> {{ i.entry_date }} </td>
		</ng-container> -->

		<!-- Tools Column -->
		<ng-container matColumnDef="tools">
			<th mat-header-cell *matHeaderCellDef></th>
			<td mat-cell *matCellDef="let i">
				<div class="btn-tools">
					<a *ngIf="!i.rewarded" color="primary" (click)="markAsReawarded(i._id)" title="{{ 'reward' | i18n }}" style="color: white; background-color: green; padding: 5px; border-radius: 5px;">
						Reward
					</a>
					<a *ngIf="i.rewarded" color="primary" (click)="markAsReawarded(i._id)" title="{{ 'reward' | i18n }}" style="color: green;">
						Rewarded!
					</a>
				</div>
			</td>
		</ng-container>

		<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
		<tr mat-row *matRowDef="let row; columns: displayedColumns;" class="{{ row.isClaimed ? 'claimed' : '' }}"></tr>
	</table>

	<mat-paginator showFirstLastButtons [length]="pagination.length" [pageSize]="pagination.pageSize" [pageSizeOptions]="pagination.pageSizeOptions"
		(page)="next($event)">
	</mat-paginator>
</mat-card>
