import { LanguagesObject } from '../../@core/data/languages-object';
import { ITable } from 'src/app/@core/libraries/interfaces/table';
import { IError } from 'src/app/@core/libraries/interfaces/error';


export class RewardProduct {
	name: LanguagesObject = new LanguagesObject();
	picture: LanguagesObject = new LanguagesObject();
}

export class RewardProductError {
	name: String = '';
	picture: String = '';
}

export class Reward implements ITable {

	_id: String = '';
	title: LanguagesObject = new LanguagesObject();
	description: LanguagesObject = new LanguagesObject();
	old_points: Number;
	points: Number;
	product: RewardProduct = new RewardProduct();
	expires_at: Date;
	sorting: Number;
	feature_id: string;
	status: Boolean = false;
}

export class RewardError implements IError {

	_id: String = '';
	title: LanguagesObject = new LanguagesObject();
	description: LanguagesObject = new LanguagesObject();
	old_points: String = '';
	points: String = '';
	product: RewardProductError = new RewardProductError();
	expires_at: String = '';
	sorting: String = '';
	feature_id: string = '';
	status: String = '';

}


export class RewardMembersFilter {
	is_sort?: boolean = false;
	active?: string = null;
	direction?: string = null;
	rewarded?: boolean = null;
	from_date?: Date = null;
	to_date?: Date = null;
}
