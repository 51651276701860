import { Component } from '@angular/core';
import { Store, StoreError } from '../store.model';
import { BaseForm } from 'src/app/@core/libraries/pages/base-form';

@Component({
	selector: 'app-form',
	templateUrl: './form.component.html',
	styleUrls: ['./form.component.scss']
})
export class FormComponent extends BaseForm {

	model = 'store';
	item: Store = new Store();
	errors: StoreError = new StoreError();
	validate: StoreError = new StoreError();

	getEmptyErrors() {
		return new StoreError();
	}

}
