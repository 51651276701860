<mat-card class="mb-3">
	<mat-card-title>{{ 'log' | i18n }}</mat-card-title>
	<mat-card-content>

		<div class="pagination">
			<button *ngIf="page > 1" mat-raised-button id="previous" (click)="previous()">
				<mat-icon>keyboard_arrow_left</mat-icon>
			</button>
			<!-- <mat-form-field appearance="outline">
				<mat-select placeholder="{{ 'action' | i18n }}" [(ngModel)]="action" name="action" (selectionChange)="get()">
					<mat-option [value]="'CREATE'">Create</mat-option>
					<mat-option [value]="'UPDATE'">Update</mat-option>
					<mat-option [value]="'DELETE'">Delete</mat-option>
					<mat-option [value]="'all'">All</mat-option>
				</mat-select>
			</mat-form-field> -->
			<button *ngIf="page < total" mat-raised-button id="next" (click)="next()">
				<mat-icon>keyboard_arrow_right</mat-icon>
			</button>
		</div>
	</mat-card-content>
</mat-card>

<mat-card *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center">
	<mat-progress-spinner color="primary" mode="indeterminate">
	</mat-progress-spinner>
</mat-card>

<mat-card *ngIf="noData" class="mb-3">
	<p class="text-center">No Data</p>
</mat-card>

<div *ngIf="!noData">

	<mat-card *ngIf="!isLoading" class="mb-3">
		<mat-card-title>{{ 'meta' | i18n }}</mat-card-title>
		<mat-card-content>
			<div class="row">
				<div class="col-md-4 text-center">
					<h6 class="font-weight-bold">Action</h6>
					<p>{{ item.action }}</p>
				</div>
				<div class="col-md-4 text-center">
					<h6 class="font-weight-bold">Changed By</h6>
					<p>{{ item.member.fullname }}</p>
					<p>{{ item.member.email }}</p>
				</div>
				<div class="col-md-4 text-center">
					<h6 class="font-weight-bold">Changed At</h6>
					<p>{{ item.created }}</p>
				</div>
			</div>
		</mat-card-content>
	</mat-card>

	<mat-card *ngIf="!isLoading">
		<mat-card-title>{{ 'changes' | i18n }}</mat-card-title>
		<div class="text-right">
			<mat-form-field appearance="outline">
				<mat-select placeholder="{{ 'show' | i18n }}" [(ngModel)]="show" name="show">
					<mat-option [value]="'changes'">The changes only</mat-option>
					<mat-option [value]="'all'">All</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
		<mat-card-content>
			<section id="changes" *ngIf="show == 'changes'">
				<div class="row">
					<div class="col-md-12">

						<table>
							<thead>
								<tr>
									<th>Field</th>
									<th>Old value</th>
									<th>New value</th>
								</tr>
							</thead>
							<tbody>
								<tr *ngFor="let field of fields">
									<td>{{ field | i18n }}</td>
									<td>
										<pre
											*ngIf="isObjectOrArray(changedFields[field]?.from)">{{ changedFields[field]?.from | json }}</pre>
										<span
											*ngIf="!isObjectOrArray(changedFields[field]?.from)">{{ changedFields[field]?.from }}</span>
									</td>
									<td>
										<pre
											*ngIf="isObjectOrArray(changedFields[field]?.to)">{{ changedFields[field]?.to | json }}</pre>
										<span
											*ngIf="!isObjectOrArray(changedFields[field]?.to)">{{ changedFields[field]?.to }}</span>
									</td>
								</tr>
							</tbody>
						</table>

						<!-- <pre>{{ item.old_data }}</pre> -->
						<!-- <textarea [innerHTML]="item.old_data" readonly></textarea> -->
					</div>
					<div class="col-md-6">
						<!-- <pre>{{ item.new_data }}</pre> -->
						<!-- <textarea [innerHTML]="item.new_data" readonly></textarea> -->
					</div>
				</div>
			</section>

			<section id="all" *ngIf="show == 'all'">
				<table>
					<tr>
						<th class="font-weight-bold">Old</th>
						<th class="font-weight-bold">New</th>
					</tr>
					<tr>
						<td>
							<pre>{{ item.old_data | json }}</pre>
						</td>
						<td>
							<pre>{{ item.new_data | json }}</pre>
						</td>
					</tr>
				</table>
			</section>
		</mat-card-content>
		<mat-card-footer>

		</mat-card-footer>
	</mat-card>
</div>
