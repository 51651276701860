import { IError } from "src/app/@core/libraries/interfaces/error";
import { LanguagesObject } from "src/app/@core/data/languages-object";

export class FreeMonthShipping {
	allow: Boolean = false;
	shipping_cost: Number = 0;
	only_jm3iea: Boolean = false;
}

export class FreeMonthShippingError {
	allow: String = "";
	shipping_cost: String = "";
	only_jm3iea: String = "";
}

export class Orders {
	jm3eia_pro: FreeMonthShipping = new FreeMonthShipping();
}

export class OrdersError {
	jm3eia_pro: FreeMonthShippingError = new FreeMonthShippingError();
}

export class Setting {
	orders: Orders = new Orders();
}

export class SettingError implements IError {
	orders?: OrdersError = new OrdersError();
}
